<section class="bread-block">
  <div class="container">
    <nav aria-label="breadcrumb">
      <ol class="breadcrumb">
        <li class="breadcrumb-item"><a [routerLink]="['']">Головна</a></li>
        <li class="breadcrumb-item">Кондиціювання</li>
      </ol>
    </nav>
  </div>
</section>
<section class="block-uslugi my-2 my-lg-4">
  <div class="container">
    <div class="title title-zag-block">Кондиціювання</div>
    <div class="row filter-select-content">
      <div class="col-12 col-lg-4">
        <div class="li-filter-select-content">
          <img src="../../../assets/images/p1.jpg" alt="Кондиціювання складу"
               class="img-fluid">
          <div class="name-filter-select-content">Кондиціювання складу</div>
          <div class="label-filter-select-content text-uppercase">Кондиціювання</div>
          <a [routerLink]="['/konditsionirovaniye-sklada']" class="my-btn">ДЕТАЛЬНІШЕ</a>
        </div>
      </div>
      <div class="col-12 col-lg-4">
        <div class="li-filter-select-content">
          <img src="../../../assets/images/p2.jpg" alt="Кондиціювання заводу"
               class="img-fluid">
          <div class="name-filter-select-content">Кондиціювання заводу</div>
          <div class="label-filter-select-content text-uppercase">Кондиціювання</div>
          <a [routerLink]="['/konditsionirovaniye-zavoda']" class="my-btn">ДЕТАЛЬНІШЕ</a>
        </div>
      </div>
      <div class="col-12 col-lg-4">
        <div class="li-filter-select-content">
          <img src="../../../assets/images/p2.jpg"
               alt="Кондиціювання приміщень фармацевтичної промисловості" class="img-fluid">
          <div class="name-filter-select-content">Кондиціювання приміщень фармацевтичної промисловості</div>
          <div class="label-filter-select-content text-uppercase">Кондиціювання</div>
          <a [routerLink]="['/konditsionirovaniye-pomeshcheniy-farmatsevticheskoy-promyshlennosti']" class="my-btn">ДЕТАЛЬНІШЕ</a>
        </div>
      </div>
    </div>
  </div>
</section>
<section class="content-page my-2 my-lg-4">
  <div class="container">
    <h4><strong>Системи кондиціювання</strong></h4>
    <p>Звичне нам поняття кондиціювання включає цілий комплекс взаємодіючих елементів, спрямованих
      на підтримку комфортної температури у приміщенні. Регулювання параметрів повітря відбувається у двох
      напрямках: охолодження та нагрівання. Крім зазначених, існує необхідність підтримання оптимальної
      температури для життєдіяльності, виробничих процесів та збереження матеріалів, зволоження та очищення
      повітря.<strong>Кондиціювання для комерційних та промислових приміщень під ключ </strong>включає в себе
      такі процеси:</p>
    <ol>
      <li>
        <p>Оцінка стану приміщення чи будівлі.</p>
      </li>
      <li>
        <p>Порівняння доступних рішень та вибір найбільш оптимального.</p>
      </li>
      <li>
        <p>Створення проекту системи.</p>
      </li>
      <li>
        <p>Кошторис, розрахунок вартості проекту.</p>
      </li>
      <li>
        <p>Закупівля обладнання та матеріалів.</p>
      </li>
      <li>
        <p>Встановлення та налаштування систем кондиціювання.</p>
      </li>
    </ol>
    <p> Залежно від мети системи, кондиціонер є зручним або технологічним. Перший тип
      Очищає, охолоджує або нагріває повітря в робочих кімнатах для підтримки мікрокліматичних стандартів. Лист
      повинні відповідати санітарним та гігієнічним вимогам законодавства про охорону праці та забезпечити
      Здорові умови для працівників. <strong> дизайн, установка, обладнання та постачання обладнання для систем
        Кондиціонер </strong> передбачає дотримання зазначених норм. </p>
    <p> Технологічні кондиціонери створюють штучні умови для певних промислових процесів або зберігання
      Продукція на складі. Це може бути спеціалізована температура, рівень вологи або конкретний вміст у конкретному
      речовини в складі повітря. Замовлення <strong> кондиціонер під ключ до комплексу інженерної компанії
        Системні інженерії в Kyiv </strong> Ви можете надати всі фактори на початковому етапі та встановити
      Найефективніша та вигідна система. </p>
    <h4><strong> Професійний кондиціонер </strong></h4>
    <p> Система інженерних систем встановлює домашні, промислові та напівпромислові кондиціонери в житлових та
      виробничі потужності. Split Systems (так званий набір обладнання) є найпопулярнішими на даний момент
      Типи обладнання. Вони - стіна, касета, канал, універсальний та стовпчик. Кожен вид вирішує
      Деякі завдання підходять для заданої шкали проекту. Наша команда допоможе впоратися з багатьма
      різновиди та виберіть відповідний кондиціонер. Ми створюємо сприятливу атмосферу в офісах, торгових центрах,
      Приватні будинки, на фабриках та складах з 2003 року. За 16 років проектування та встановлення кондиціонерів ми в
      Досконалість освоїла мистецтво встановлення обладнання будь -якої складності. В арсеналі компанії лише останні
      Інструменти та сертифіковані матеріали та обладнання. </p></div>
</section>
<app-our-advantages></app-our-advantages>
<app-our-clients-small></app-our-clients-small>
<app-feedback></app-feedback>
<app-map></app-map>

