<section class="bread-block">
  <div class="container">
    <nav aria-label="breadcrumb">
      <ol class="breadcrumb">
        <li class="breadcrumb-item"><a [routerLink]="['']">Головна</a></li>
        <li class="breadcrumb-item"><a [routerLink]="['/portfolio']">Портфоліо</a></li>
        <li class="breadcrumb-item">Торгово-розважальний центр «Любава»</li>
      </ol>
    </nav>
  </div>
</section>

<section class="content-page my-2 my-lg-4">
  <div class="container">
    <div class="title title-zag-block">ТОРГОВО-РОЗВАЖАЛЬНИЙ ЦЕНТР «ЛЮБАВА»</div>
  </div>
</section>

<section class="slider">
  <div class="container">
    <div class="portfolio-owl owl-carousel owl-theme">
      <div class="slider-item">
        <img src="../../../../assets/images/lyubava/lyubava_3.jpg" alt="Торгово-развлекательный центр «Любава»"
             class="img-fluid">
      </div>
      <div class="slider-item">
        <img src="../../../../assets/images/lyubava/lyubava_2.jpg" alt="Торгово-развлекательный центр «Любава»"
             class="img-fluid">
      </div>
      <div class="slider-item">
        <img src="../../../../assets/images/lyubava/lyubava_1.jpg" alt="Торгово-развлекательный центр «Любава»"
             class="img-fluid">
      </div>
    </div>
  </div>
</section>
<section class="content-page my-2 my-lg-4">
  <div class="container">
    <p>Торгово-розважальний центр «Любава» 25 тис. м2 (м. Черкаси, бул. Шевченка, 208).</p>
  </div>
</section>
<section class="content-page my-2 my-lg-4">
  <div class="container">
    <div class="title title-zag-block">Проведені роботи</div>
    <ol>
      <li>Монтаж мультизональних систем кондиціювання повітря на базі обладнання LG MULTI V - 1,5 МВт</li>
      <li>Монтаж системи припливно-витяжної вентиляції SALDA</li>
      <li>Монтаж котельні на базі обладнання Viessmann 2,4 МВт</li>
    </ol>
  </div>
</section>

<app-our-advantages></app-our-advantages>
<app-our-clients-small></app-our-clients-small>
<app-feedback></app-feedback>
<app-map></app-map>
