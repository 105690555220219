<section class="bread-block">
  <div class="container">
    <nav aria-label="breadcrumb">
      <ol class="breadcrumb">
        <li class="breadcrumb-item"><a [routerLink]="['']">Головна</a></li>
        <li class="breadcrumb-item"><a [routerLink]="['/uslugi']">Наші послуги</a></li>
        <li class="breadcrumb-item">Аналіз та оптимізація проектів за розділами ОВіК</li>
      </ol>
    </nav>
  </div>
</section>
<section class="content-page my-2 my-lg-4">
  <div class="container">
    <div class="title title-zag-block">АНАЛІЗ І ОПТИМІЗАЦІЯ ПРОЕКТІВ ЗА РОЗДІЛАМИ ОВІК</div>
    <p><strong>Компанія "Комплекс Інжиніринг Системз"</strong> здійснює аналіз та оптимізацію розділів Опалення,
      Вентиляція та Кондиціонування. Спільно з Замовником наші спеціалісти складають технічне завдання на вказані
      системи, або аналізують та оптимізують технічне завдання замовника, підбирають обладнання та виконують
      проектування проектної стадії та робочої документації. Компанія <strong>"Комплекс Інжиніринг Системз"</strong>
      виконує розділи Опалення, Вентиляція та Кондиціонування, як для цивільних об'єктів (торгові комплекси, житлові
      будинки, дитячі садки, спортивно-оздоровчі комплекси), так і для промислових підприємств, де суттєве значення
      мають якісний склад повітря витяжних та приточних систем, температурні режими окремих технологічних процесів, а
      також дотримання норм з охорони праці та виробничої санітарії за допомогою систем вентиляції, опалення та
      кондиціонування.</p><h4><strong>Відмінності Опалення, Вентиляції та Кондиціонування в промисловому та цивільному
    будівництві</strong></h4>
    <p class="ql-align-justify">Зачіпаючи проектування систем&nbsp;ОВіК, важливо відзначити деякі відмінності в
      проектування систем для цивільного та промислового будівництва. У цивільному будівництві робиться
      упор на системи опалення та кондиціювання. Серйозна увага приділяється різним способам опалення:
      водяне опалення на різних приладах, система "тепла підлога". Кондиціювання також є важливим питанням у
      необхідності створення найсприятливіших умов комфорту.</p>
    <p class="ql-align-justify">Що ж стосується виробничих будівель, то в даному випадку на перше місце виходить система
      вентиляції. Важливо, за допомогою системи вентиляції, видалити з виробничих приміщень шкідливі домішки,
      що містяться в повітрі робочої зони і при цьому не допустити поширення викидів забруднюючих речовин у
      атмосферному повітрі вище за значення гранично-допустимих концентрацій. Таким чином рішення щодо вентиляції повинні
      містити і необхідні рішення з очищення викидів: різні фільтри, скрубери, абсорбційні та інші
      установки.</p>
    <p class="ql-align-justify">Підтримка заданої температури всередині приміщень також здійснюється за рахунок
      вентиляційних систем (повітряних калориферів). Також у процесі підтримання температури всередині приміщення важливе
      місце відводиться повітряним тепловим завісам над отворами в огороджувальних конструкціях.</p>
    <p class="ql-align-justify">Особливо важливе використання вентиляційних систем у процесах видалення надлишку теплових
      викидів, що утворюються в "гарячих" процесах технології. Так, видалення чи перерозподіл надлишків тепла,
      що виділяється, наприклад, у процесі гарячого пресування пластмас, здійснюється за допомогою промислових чилерів
      та фрикулерів.</p>
    <p class="ql-align-justify">Величезне значення системи вентиляції (кондиціонери) мають при створенні чистих
      виробництв ("чистих приміщень").</p><h4><strong>Вентиляція</strong></h4>
    <p class="ql-align-justify">Розділ проектної документації&nbsp;"Вентиляція"&nbsp;розробляється&nbsp;відповідно
      з нормативними документації та технічним завданням на розробку системи. Розрахунок вентиляції включає: розрахунок
      повітрообмінів, підбір вентиляційного обладнання, підбір елементів мережі повітроводів, а також
      повітророзподільників. У розрахунковій частині проводиться аеродинамічний та акустичний розрахунок системи повітроводів та
      повітророзподільників. Графічна частина містить: лист загальних даних, плани, аксонометричні схеми,
      специфікації обладнання та матеріалів.</p>
    <p class="ql-align-justify">Проектування вентиляції виконується у два етапи:&nbsp;</p>
    <ul>
      <li class="ql-align-justify">стадія проект (П)&nbsp;</li>
      <li class="ql-align-justify">робочий проект (Р).</li>
    </ul>
    <p class="ql-align-justify">У документації стадії "П" розробляються основні технічні рішення та розраховуються
      основні показники проекту Крім іншого, в об'єм стадії "П" входять роботи з підбору основного обладнання.
      (вентиляційні установки), визначення необхідного місця для розміщення обладнання, а також попередня
      трасування мережі повітроводів.&nbsp;</p>
    <p class="ql-align-justify">При виконанні робочого проекту приймаються остаточні рішення щодо вибору
      обладнання, його розміщення та трасування мереж повітроводів. Здійснюється погодження проектної документації
      зі спеціалістами суміжних розділів та видача необхідних завдань для здійснення тепло- та холодопостачання, підведення
      електричної потужності, забезпечення необхідних конструктивних заходів.</p>
    <p class="ql-align-justify">Також у момент розробки робочої документації розробляється та розраховується система
      повітророзподілу, складається специфікація обладнання та матеріалів, необхідних для реалізації проекту
      вентиляції.</p><h4><strong>Опалення</strong></h4>
    <p class="ql-align-justify">Розділ проектної документації&nbsp;"Опалення"&nbsp;розробляється на основі
      технічного завдання, а також відповідно до нормативної документації.</p>
    <p class="ql-align-justify">Розділ включає:</p>
    <ul>
      <li class="ql-align-justify">розрахунок тепловтрат,</li>
      <li class="ql-align-justify">підбір опалювальних приладів,</li>
      <li class="ql-align-justify">підбір елементів трубопроводів, регулюючої арматури, запірної арматури</li>
      <li class="ql-align-justify">гідравлічний розрахунок та балансування мережі.</li>
    </ul>
    <p class="ql-align-justify">Графічна частина містить:</p>
    <ul>
      <li class="ql-align-justify">аркуш загальних даних,</li>
      <li class="ql-align-justify">плани,</li>
      <li class="ql-align-justify">аксонометричні схеми,</li>
      <li class="ql-align-justify">специфікації обладнання та матеріалів.&nbsp;&nbsp;</li>
    </ul>
    <p class="ql-align-justify">У робочій документації на системи опалення особливе місце займає гідравлічний розрахунок
      та балансування мережі трубопроводів. Так, відсутність у проектній документації налаштувань балансових клапанів не
      дозволить налагодити розрахунковий розподіл теплоносія, що призведе до нестійкої роботи всієї системи
      опалення. Варто зазначити, що в документації можуть бути зазначені або налаштування балансових клапанів, або перепад
      тиску на клапані.</p>
    <p class="ql-align-justify">Проект системи опалення&nbsp;охоплює у своєму обсязі трубопроводи,
      запірно-регулюючу арматуру, а також прилади опалення та, у разі їх застосування, контури теплої підлоги.
      Генератор тепла (котел з обв'язкою), вузол елеваторний, вузол обліку теплової енергії не входять в об'єм розділу
      "Опалення" та розробляються в рамках розділу ІТП (індивідуальний тепловий пункт).</p>
    <h4><strong>Кондиціонування</strong></h4>
    <p class="ql-align-justify">Розділ&nbsp;"Кондиціонування" включає: розрахунок теплоприток, підбір обладнання
      систем кондиціювання, підбір елементів трубопроводів, а також запірної та регулюючої арматури.</p>
    <p class="ql-align-justify">Проектування кондиціювання включає гідравлічний розрахунок та балансування мережі.
      Графічна частина містить: лист загальних даних, плани, аксонометричні схеми, специфікації обладнання та
      матеріалів.</p>
    <p class="ql-align-justify">Найважливішим моментом при розробці проекту кондиціювання є грамотний та
      точний розрахунок розподілу повітря. Саме правильно розрахована система повітророзподілу дозволить домогтися
      приміщенні заданих параметрів, що обслуговується, і уникнути виникнення зон з підвищеною швидкістю руху холодного
      повітря. Також критичним чинником розробки системи кондиціонування є шум, створюваний
      обладнанням. Тільки застосування сучасного обладнання для систем кондиціювання, технічні параметри
      якого підтверджуються незалежними експертами, дозволяє гарантовано створювати кліматичні настанови
      високого рівня комфортності.</p>
    <p class="ql-align-justify">Надійність кліматичних систем у великій мірі залежить від того, наскільки добре
      організовано процес повернення олії в компресор. Тому дуже важливо, щоб у проектній документації була
      відображено інформацію про встановлення маслоповернених петель, а також обмеження для трасування фреонопроводів,
      рекомендовані виробником.&nbsp;</p>
    <p class="ql-align-justify">Неприємним моментом при експлуатації системи кондиціювання може бути погана
      проектне опрацювання системи відведення дренажу, оскільки цей пункт - спірний і може бути віднесений як до розділу
      "Водопостачання та каналізація", так і до розділу "Кондиціонування". Для виключення можливості такого роду
      нерозуміння професійні проектні організації заздалегідь обговорюють виконання проекту відведення дренажу.</p>
    <p class="ql-align-justify">Команда фахівців&nbsp;компанії «Комплекс Інжиніринг Системз» виконає аналіз та
      оптимізацію вашої проектної
      документації а також виконають проектування проектної стадії та робочої документації у найкоротші терміни.</p>
    <p class="ql-align-justify"><br></p>
  </div>
</section>
<app-additional-services></app-additional-services>
<app-our-advantages></app-our-advantages>
<app-our-clients-small></app-our-clients-small>
<app-feedback></app-feedback>
<app-map></app-map>
