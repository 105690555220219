<section class="bread-block">
  <div class="container">
    <nav aria-label="breadcrumb">
      <ol class="breadcrumb">
        <li class="breadcrumb-item"><a [routerLink]="['']">Головна</a></li>
        <li class="breadcrumb-item"><a [routerLink]="['/portfolio']">Портфоліо</a></li>
        <li class="breadcrumb-item">Торгово-розважальний центр «DEPOT»</li>
      </ol>
    </nav>
  </div>
</section>

<section class="content-page my-2 my-lg-4">
  <div class="container">
    <div class="title title-zag-block">ТОРГОВО-РОЗВАЖАЛЬНИЙ ЦЕНТР «DEPOT»</div>
  </div>
</section>

<section class="slider">
  <div class="container">
    <div class="portfolio-owl owl-carousel owl-theme">
      <div class="slider-item">
        <img src="../../../../assets/images/depot/depot_1.jpg" alt="Торгово-развлекательный центр «DEPOT»"
             class="img-fluid">
      </div>
      <div class="slider-item">
        <img src="../../../../assets/images/depot/depot_2.jpg" alt="Торгово-развлекательный центр «DEPOT»"
             class="img-fluid">
      </div>
    </div>
  </div>
</section>
<section class="content-page my-2 my-lg-4">
  <div class="container">
    <p>Торгово-розважальний центр «DEPOT» 19 тис. м2 (м. Черкаси, бул. Шевченка, 305).</p>
  </div>
</section>
<section class="content-page my-2 my-lg-4">
  <div class="container">
    <div class="title title-zag-block">Проведені роботи</div>
    <ol>
      <li>Монтаж системи кондиціювання повітря на базі обладнання LG (Корея) – 1,17 МВт</li>
      <li>Монтаж вентиляційних систем на базі припливно-витяжних установок SALDA (Литва)</li>
      <li>Монтаж системи опалення та теплопостачання</li>
    </ol>
  </div>
</section>

<app-our-advantages></app-our-advantages>
<app-our-clients-small></app-our-clients-small>
<app-feedback></app-feedback>
<app-map></app-map>
