import { Component, OnInit } from '@angular/core';
import {Meta, Title} from '@angular/platform-browser';

@Component({
  selector: 'app-ventilyacziya',
  templateUrl: './ventilyacziya.component.html',
  styleUrls: ['./ventilyacziya.component.css']
})
export class VentilyacziyaComponent implements OnInit {

  constructor(private title: Title,
              private meta: Meta) {
  }

  ngOnInit(): void {
    this.title.setTitle('Вентиляція для комерційних та промислових приміщень під ключ - Комплекс Інжиніринг Системз | Інжинірингові послуги у Києві');
    this.meta.updateTag({name: 'description', content: 'Вентиляція під ключ від інжинірингової компанії Комплекс Інжиніринг Системз в Києві ⭐ Проектування, монтаж, комплектація та постачання обладнання для систем вентиляції'});
    this.meta.updateTag({meta: "keywords", content:"системы вентиляции,системы вентиляции киев,промышленные системы вентиляции,монтаж системы вентиляции,інжинірингова компанія,інжинірингові послуги,інжинірингова компанія Київ,промисловий інжиніринг"})
    window.scroll(0, 0);
  }

}
