import {RouterModule, Routes} from '@angular/router';
import {NgModule} from '@angular/core';
import {HomeComponent} from './pages/home/home.component';
import {VentilyacziyaComponent} from './pages/ventilyacziya/ventilyacziya.component';
import {ErrorComponent} from './pages/error/error.component';
import {KondiczionirovanieComponent} from './pages/kondiczionirovanie/kondiczionirovanie.component';
import {OtoplenieComponent} from './pages/otoplenie/otoplenie.component';
import {TeplosnabzhenieComponent} from './pages/teplosnabzhenie/teplosnabzhenie.component';
import {XolodosnabzhenieComponent} from './pages/xolodosnabzhenie/xolodosnabzhenie.component';
import {PortfolioComponent} from './pages/portfolio/portfolio.component';
import {KontaktyiComponent} from './pages/kontaktyi/kontaktyi.component';
import {VacanciesComponent} from './pages/vacancies/vacancies.component';
import {FeedbackFullComponent} from './pages/feedback-full/feedback-full.component';
import {NewsComponent} from './pages/news/news.component';
import {AlternativnyyIstochnikiTeplaComponent} from './pages/alternativnyy-istochniki-tepla/alternativnyy-istochniki-tepla.component';
import {OsusheniyeComponent} from './pages/osusheniye/osusheniye.component';
import {RazrabotkaProektovOvikComponent} from './pages/razrabotka-proektov-ovik/razrabotka-proektov-ovik.component';
import {AnalizOptimizacziyaProektovOvikComponent} from './pages/analiz-optimizacziya-proektov-ovik/analiz-optimizacziya-proektov-ovik.component';
import {KomplektacziyaPostavkaOborudovaniyComponent} from './pages/komplektacziya-postavka-oborudovaniy/komplektacziya-postavka-oborudovaniy.component';
import {PoslegarantijnoeObsluzhivanieComponent} from './pages/poslegarantijnoe-obsluzhivanie/poslegarantijnoe-obsluzhivanie.component';
import {RealizacziyaProektovComponent} from './pages/realizacziya-proektov/realizacziya-proektov.component';
import {VseUslugiComponent} from './pages/vse-uslugi/vse-uslugi.component';
import {PortfolioSilpoComponent} from './pages/portfolios/portfolio-silpo/portfolio-silpo.component';
import {KonditsionirovaniyeSkladaComponent} from './pages/konditsionirovaniye-sklada/konditsionirovaniye-sklada.component';
import {PremiumTsentrComponent} from './pages/portfolios/premium-tsentr/premium-tsentr.component';
import {RespublikaComponent} from './pages/portfolios/respublika/respublika.component';
import {KhortytsyaPalasComponent} from './pages/portfolios/khortytsya-palas/khortytsya-palas.component';
import {AtmosferaComponent} from './pages/portfolios/atmosfera/atmosfera.component';
import {StranaEnotyyaComponent} from './pages/portfolios/strana-enotyya/strana-enotyya.component';
import {EdemRezortComponent} from './pages/portfolios/edem-rezort/edem-rezort.component';
import {DepotComponent} from './pages/portfolios/depot/depot.component';
import {KvartyrnyyZhytlovyyBudynokComponent} from './pages/portfolios/kvartyrnyy-zhytlovyy-budynok/kvartyrnyy-zhytlovyy-budynok.component';
import {OfisnePomeshchenyeComponent} from './pages/portfolios/ofisne-pomeshchenye/ofisne-pomeshchenye.component';
import {LyubavaComponent} from './pages/portfolios/lyubava/lyubava.component';
import {FabrykaComponent} from './pages/portfolios/fabryka/fabryka.component';
import {LuxuryComponent} from './pages/portfolios/luxury/luxury.component';
import {OrnamentComponent} from './pages/portfolios/ornament/ornament.component';
import {BirkraftComponent} from './pages/portfolios/birkraft/birkraft.component';
import {HotovoComponent} from './pages/portfolios/hotovo/hotovo.component';
import {AdministratyvnoZhytlovyyComponent} from './pages/portfolios/administratyvno-zhytlovyy/administratyvno-zhytlovyy.component';
import {AcuraComponent} from './pages/portfolios/acura/acura.component';
import {InkrystarComponent} from './pages/portfolios/inkrystar/inkrystar.component';
import {HalaktonComponent} from './pages/portfolios/halakton/halakton.component';
import {GrafitComponent} from './pages/portfolios/grafit/grafit.component';
import {LeksComponent} from './pages/portfolios/leks/leks.component';
import {BetonKompleksComponent} from './pages/portfolios/beton-kompleks/beton-kompleks.component';
import {BimarketComponent} from './pages/portfolios/bimarket/bimarket.component';
import {BleskComponent} from './pages/portfolios/blesk/blesk.component';
import {KonditsionirovaniyeZavodaComponent} from './pages/konditsionirovaniye-zavoda/konditsionirovaniye-zavoda.component';
import {KonditsionirovaniyeFarmComponent} from './pages/konditsionirovaniye-farm/konditsionirovaniye-farm.component';
import {MerksComponent} from './pages/portfolios/merks/merks.component';
import {ModnayaKastaComponent} from './pages/portfolios/modnaya-kasta/modnaya-kasta.component';
import {UaPromComponent} from './pages/portfolios/ua-prom/ua-prom.component';
import {DzhublComponent} from './pages/portfolios/dzhubl/dzhubl.component';
import {OfisnaChastynaLohistychnohoKompleksuComponent} from './pages/portfolios/ofisna-chastyna-lohistychnoho-kompleksu/ofisna-chastyna-lohistychnoho-kompleksu.component';
import {UkrayinskyyKapytalComponent} from './pages/portfolios/ukrayinskyy-kapytal/ukrayinskyy-kapytal.component';
import {UkrPlastAvtomatComponent} from './pages/portfolios/ukr-plast-avtomat/ukr-plast-avtomat.component';
import {StyrolComponent} from './pages/portfolios/styrol/styrol.component';
import {MmtsStbComponent} from './pages/portfolios/mmts-stb/mmts-stb.component';
import {LaboratornyyKorpusComponent} from './pages/portfolios/laboratornyy-korpus/laboratornyy-korpus.component';
import {EkmyComponent} from './pages/portfolios/ekmy/ekmy.component';
import {NewBalanceComponent} from './pages/portfolios/new-balance/new-balance.component';
import {GardeComponent} from './pages/portfolios/garde/garde.component';
import {ShahPlovComponent} from './pages/portfolios/shah-plov/shah-plov.component';
import {AudiComponent} from './pages/portfolios/audi/audi.component';
import {VodosnabzheniyeKanalizatsiyaComponent} from './pages/vodosnabzheniye-kanalizatsiya/vodosnabzheniye-kanalizatsiya.component';
import {RealizatsiyaPodKlyuchComponent} from './pages/realizatsiya-pod-klyuch/realizatsiya-pod-klyuch.component';
import {MontazhNaladServisComponent} from './pages/montazh-nalad-servis/montazh-nalad-servis.component';
import {GarantiynoyeObsluzhivaniyeComponent} from './pages/garantiynoye-obsluzhivaniye/garantiynoye-obsluzhivaniye.component';
import {TrcRetrovilleComponent} from './pages/newsitems/trc-retroville/trc-retroville.component';
import {LindenLuxuryResidencesComponent} from './pages/portfolios/linden-luxury-residences/linden-luxury-residences.component';
import {AtlanticResidenceComponent} from './pages/portfolios/atlantic-residence/atlantic-residence.component';
import {BtsEuropeComponent} from './pages/portfolios/bts-europa/bts-europe.component';
import {JoobleSkyloomComponent} from './pages/portfolios/jooble-skyloom/jooble-skyloom.component';
import {CosmosGolfUkraineComponent} from './pages/portfolios/cosmos-golf-ukraine/cosmos-golf-ukraine.component';
import {KyyankaComponent} from './pages/portfolios/kyyanka/kyyanka.component';
import {RetrovilComponent} from './pages/portfolios/retrovil/retrovil.component';
import {OsvitoriyaKhabComponent} from './pages/portfolios/osvitoriya-khab/osvitoriya-khab.component';
import {UnitCityComponent} from './pages/portfolios/unit-city/unit-city.component';

const routes: Routes = [
  {
    path: '',
    pathMatch: 'full',
    component: HomeComponent
  },
  {
    path: 'razrabotka-proektov-ovik',
    component: RazrabotkaProektovOvikComponent,
  },
  {
    path: 'analiz-i-optimizacziya-proektov-ovik',
    component: AnalizOptimizacziyaProektovOvikComponent,
  },
  {
    path: 'komplektacziya-i-postavka-oborudovaniya',
    component: KomplektacziyaPostavkaOborudovaniyComponent,
  },
  {
    path: 'garantijnoe-obsluzhivanie',
    component: GarantiynoyeObsluzhivaniyeComponent,
  },
  {
    path: 'poslegarantijnoe-obsluzhivanie',
    component: PoslegarantijnoeObsluzhivanieComponent,
  },
  {
    path: 'realizacziya-proektov-po-razlichnyim-urovnem-trebovanij',
    component: RealizacziyaProektovComponent,
  },
  {
    path: 'realizatsiya-pod-klyuch',
    component: RealizatsiyaPodKlyuchComponent,
  },
  {
    path: 'montazhnyye-pusko-naladochnyye-servisnyye-raboty',
    component: MontazhNaladServisComponent,
  },
  {
    path: 'uslugi',
    component: VseUslugiComponent,
  },
  {
    path: 'ventilyacziya',
    component: VentilyacziyaComponent,
  },
  {
    path: 'kondiczionirovanie',
    component: KondiczionirovanieComponent,
  },
  {
    path: 'konditsionirovaniye-sklada',
    component: KonditsionirovaniyeSkladaComponent,
  },
  {
    path: 'konditsionirovaniye-zavoda',
    component: KonditsionirovaniyeZavodaComponent,
  },
  {
    path: 'konditsionirovaniye-pomeshcheniy-farmatsevticheskoy-promyshlennosti',
    component: KonditsionirovaniyeFarmComponent,
  },
  {
    path: 'otoplenie',
    component: OtoplenieComponent,
  },
  {
    path: 'teplosnabzhenie',
    component: TeplosnabzhenieComponent,
  },
  {
    path: 'alternativnyy-istochniki-tepla',
    component: AlternativnyyIstochnikiTeplaComponent,
  },
  {
    path: 'xolodosnabzhenie',
    component: XolodosnabzhenieComponent,
  },
  {
    path: 'osushenie',
    component: OsusheniyeComponent,
  },
  {
    path: 'vodosnabzheniye-kanalizatsiya',
    component: VodosnabzheniyeKanalizatsiyaComponent,
  },
  {
    path: 'produktovyy-supermarket-silpo',
    component: PortfolioSilpoComponent,
  },
  {
    path: 'premium-tsentr',
    component: PremiumTsentrComponent,
  },
  {
    path: 'respublika',
    component: RespublikaComponent,
  },
  {
    path: 'khortytsya-palas',
    component: KhortytsyaPalasComponent,
  },
  {
    path: 'atmosfera',
    component: AtmosferaComponent,
  },
  {
    path: 'strana-enotyya',
    component: StranaEnotyyaComponent,
  },
  {
    path: 'edem-rezort',
    component: EdemRezortComponent,
  },
  {
    path: 'depot',
    component: DepotComponent
  },
  {
    path: 'kvartyrnyy-zhytlovyy-budynok',
    component: KvartyrnyyZhytlovyyBudynokComponent
  },
  {
    path: 'ofisne-pomeshchenye',
    component: OfisnePomeshchenyeComponent
  },
  {
    path: 'lyubava',
    component: LyubavaComponent
  },
  {
    path: 'fabryka',
    component: FabrykaComponent
  },
  {
    path: 'luxury',
    component: LuxuryComponent
  },
  {
    path: 'ornament',
    component: OrnamentComponent
  },
  {
    path: 'birkraft',
    component: BirkraftComponent
  },
  {
    path: 'hotovo',
    component: HotovoComponent
  },
  {
    path: 'administratyvno-zhytlovyy',
    component: AdministratyvnoZhytlovyyComponent
  },
  {
    path: 'acura',
    component: AcuraComponent
  },
  {
    path: 'inkrystar',
    component: InkrystarComponent
  },
  {
    path: 'halakton',
    component: HalaktonComponent
  },
  {
    path: 'grafit',
    component: GrafitComponent
  },
  {
    path: 'leks',
    component: LeksComponent
  },
  {
    path: 'beton-kompleks',
    component: BetonKompleksComponent
  },
  {
    path: 'bimarket',
    component: BimarketComponent
  },
  {
    path: 'blesk',
    component: BleskComponent
  },
  {
    path: 'merks',
    component: MerksComponent
  },
  {
    path: 'modnaya-kasta',
    component: ModnayaKastaComponent
  },
  {
    path: 'ua-prom',
    component: UaPromComponent
  },
  {
    path: 'dzhubl',
    component: DzhublComponent
  },
  {
    path: 'ofisna-chastyna-lohistychnoho-kompleksu',
    component: OfisnaChastynaLohistychnohoKompleksuComponent
  },
  {
    path: 'ukrayinskyy-kapytal',
    component: UkrayinskyyKapytalComponent
  },
  {
    path: 'ukr-plast-avtomat',
    component: UkrPlastAvtomatComponent
  },
  {
    path: 'styrol',
    component: StyrolComponent
  },
  {
    path: 'laboratornyy-korpus',
    component: LaboratornyyKorpusComponent
  },
  {
    path: 'mmts-stb',
    component: MmtsStbComponent
  },
  {
    path: 'ekmy',
    component: EkmyComponent
  },
  {
    path: 'new-balance',
    component: NewBalanceComponent
  },
  {
    path: 'garde',
    component: GardeComponent
  },
  {
    path: 'shah-plov',
    component: ShahPlovComponent
  },
  {
    path: 'audi',
    component: AudiComponent
  },
  {
    path: 'linden-luxury-residences',
    component: LindenLuxuryResidencesComponent
  },
  {
    path: 'atlantic-residence',
    component: AtlanticResidenceComponent
  },
  {
    path: 'bts-europe',
    component: BtsEuropeComponent
  },
  {
    path: 'jooble-skyloom',
    component: JoobleSkyloomComponent
  },
  {
    path: 'cosmos-golf-ukraine',
    component: CosmosGolfUkraineComponent
  },
  {
    path: 'kyyanka',
    component: KyyankaComponent
  },
  {
    path: 'retrovil',
    component: RetrovilComponent
  },
  {
    path: 'osvitoriya-khab',
    component: OsvitoriyaKhabComponent
  },
  {
    path: 'unit-city',
    component: UnitCityComponent
  },
  {
    path: 'portfolio',
    component: PortfolioComponent,
  },
  {
    path: 'feedback',
    component: FeedbackFullComponent,
  },
  {
    path: 'vacancies',
    component: VacanciesComponent,
  },
  {
    path: 'news',
    component: NewsComponent,
  },
  {
    path: 'news-trc-retroville-photo',
    component: TrcRetrovilleComponent,
  },
  {
    path: 'kontaktyi',
    component: KontaktyiComponent,
  },
  {
    path: 'error',
    component: ErrorComponent
  },
  {
    path: '**',
    component: ErrorComponent
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoute {
}
