import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-our-clients-small',
  templateUrl: './our-clients-small.component.html',
  styleUrls: ['./our-clients-small.component.css']
})
export class OurClientsSmallComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
