<section>
  <div class="container">
    <div class="priim-cont-page pt-2 pt-lg-5">
      <div class="title-priim-cont-page">Також наша компанія пропонує своїм клієнтам додаткові послуги:</div>
      <div class="row">
        <div class="col-12 col-lg-3 text-center">
          <div class="img-block-gar">
            <img src="../../../assets/images/ic10.png" alt="Монтажні та пуско-налагоджувальні роботи інженерних мереж" class="img-fluid">
            <span>01</span>
          </div>
          <div class="name-block-gar">Монтажні та пусконалагоджувальні роботи інженерних мереж</div>
        </div>

        <div class="col-12 col-lg-3 text-center">
          <div class="img-block-gar">
            <img src="../../../assets/images/ic10.png" alt="Гарантійне обслуговування та сервісний супровід" class="img-fluid">
            <span>02</span>
          </div>
          <div class="name-block-gar">Гарантійне обслуговування та сервісний супровід</div>
        </div>

        <div class="col-12 col-lg-3 text-center">
          <div class="img-block-gar">
            <img src="../../../assets/images/ic10.png" alt="Післягарантійне обслуговування" class="img-fluid">
            <span>03</span>
          </div>
          <div class="name-block-gar">Післягарантійне обслуговування</div>
        </div>

        <div class="col-12 col-lg-3 text-center">
          <div class="img-block-gar">
            <img src="../../../assets/images/ic10.png" alt="Реалізація проектів з різним рівнем вимог" class="img-fluid">
            <span>04</span>
          </div>
          <div class="name-block-gar">Реалізація проектів з різним рівнем вимог</div>
        </div>
      </div>
    </div>
  </div>
</section>
