import {Component, OnInit} from '@angular/core';
import {Meta, Title} from '@angular/platform-browser';

@Component({
  selector: 'app-vodosnabzheniye-kanalizatsiya',
  templateUrl: './vodosnabzheniye-kanalizatsiya.component.html',
  styleUrls: ['./vodosnabzheniye-kanalizatsiya.component.css']
})
export class VodosnabzheniyeKanalizatsiyaComponent implements OnInit {

  constructor(private title: Title,
              private meta: Meta) {
  }

  ngOnInit(): void {
    this.title.setTitle('Водопостачання та каналізація для комерційних та промислових приміщень під ключ - Комплекс Інжиніринг Системз | Інжинірингові послуги у Києві');
    this.meta.updateTag({
      name: 'description',
      content: 'Водопостачання та каналізація під ключ від інжинірингової компанії Комплекс Інжиніринг Системз в Києві ⭐ Проектування, монтаж, комплектація та постачання обладнання для систем водопостачання та каналізації'
    });
    this.meta.updateTag({meta: 'keywords', content: 'системы водоснабжение киев,водоснабжение киев,канализация,канализация киев,системы водоснабжение и канализации,інжинірингова компанія,інжинірингові послуги,інжинірингова компанія Київ,промисловий інжиніринг'});
    window.scroll(0, 0);
  }
}
