<section class="bread-block">
  <div class="container">
    <nav aria-label="breadcrumb">
      <ol class="breadcrumb">
        <li class="breadcrumb-item"><a [routerLink]="['']">Головна</a></li>
        <li class="breadcrumb-item"><a [routerLink]="['/portfolio']">Портфоліо</a></li>
        <li class="breadcrumb-item">Офісний центр "Меркс"</li>
      </ol>
    </nav>
  </div>
</section>

<section class="content-page my-2 my-lg-4">
  <div class="container">
    <div class="title title-zag-block">ОФІСНИЙ ЦЕНТР «МЕРКС»</div>
  </div>
</section>

<section class="slider">
  <div class="container">
    <div class="portfolio-owl owl-carousel owl-theme">
      <div class="slider-item">
        <img src="../../../../assets/images/merks/merks_1.jpg" alt="Офисный центр «Меркс»"
             class="img-fluid">
      </div>
      <div class="slider-item">
        <img src="../../../../assets/images/merks/merks_2.jpg" alt="Офисный центр «Меркс»"
             class="img-fluid">
      </div>
      <div class="slider-item">
        <img src="../../../../assets/images/merks/merks_3.jpg" alt="Офисный центр «Меркс»"
             class="img-fluid">
      </div>
      <div class="slider-item">
        <img src="../../../../assets/images/merks/merks_4.jpg" alt="Офисный центр «Меркс»"
             class="img-fluid">
      </div>
      <div class="slider-item">
        <img src="../../../../assets/images/merks/merks_5.jpg" alt="Офисный центр «Меркс»"
             class="img-fluid">
      </div>
    </div>
  </div>
</section>
<section class="content-page my-2 my-lg-4">
  <div class="container">
    <p>Офісний центр «Меркс» 18,5 тис. м2 (м. Київ, вул. Лейпцизька, 15).</p>
  </div>
</section>
<section class="content-page my-2 my-lg-4">
  <div class="container">
    <div class="title title-zag-block">Проведені роботи</div>
    <ol>
      <li>Монтаж мультизональної системи кондиціювання з рекуперацією тепла та геотермальним
        джерелом енергії на базі обладнання LG MULTI V (Корея) потужністю 1,6 МВт</li>
    </ol>
  </div>
</section>

<app-our-advantages></app-our-advantages>
<app-our-clients-small></app-our-clients-small>
<app-feedback></app-feedback>
<app-map></app-map>
