import {Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {EmailCallDto} from '../../dto/email.call.dto';
import Constants from '../../utils/constants';
import {NgxSpinnerService} from 'ngx-spinner';
import {ToastrService} from 'ngx-toastr';
import {MessageService} from '../../services/message.service';
import {ErrorService} from '../../services/error.service';

@Component({
  selector: 'app-callback',
  templateUrl: './callback.component.html',
  styleUrls: ['./callback.component.css']
})
export class CallbackComponent implements OnInit {

  callForm: FormGroup;
  @ViewChild('closeModal') private closeModal: ElementRef;

  constructor(private spinner: NgxSpinnerService,
              private toast: ToastrService,
              private messageService: MessageService,
              private errorService: ErrorService) {
  }

  ngOnInit(): void {
    this.callForm = new FormGroup({
      name: new FormControl('', [Validators.required, Validators.minLength(2)]),
      phone: new FormControl('', [Validators.required, Validators.minLength(10)])
    });
  }

  isControlInvalid(controlName: string): boolean {
    const control = this.callForm.controls[controlName];
    return control.invalid && control.touched;
  }

  send() {
    const controls = this.callForm.controls;
    if (this.callForm.invalid) {
      Object.keys(controls)
        .forEach(controlName => controls[controlName].markAsTouched());
      return;
    }

    const name = this.callForm.get('name').value;
    const phone = this.callForm.get('phone').value;
    let dto = new EmailCallDto();
    dto.name = name;
    dto.phone = phone;

    this.spinner.show();
    this.messageService.call(dto).subscribe(s => {
      this.spinner.hide();
      if (s.status.code == Constants.HTTP_CODE_200) {
        this.callForm.reset();
        this.closeModal.nativeElement.click();
        this.toast.success('Ваша заявка прийнята чекайте на дзвінок');
      } else {
        this.toast.error(Constants.DEFAULT_ERROR_MESSAGE);
      }
    }, error1 => {
      this.spinner.hide();
      this.toast.error(this.errorService.getErrorMessage(error1));
    });
  }
}
