<section class="bread-block">
  <div class="container">
    <nav aria-label="breadcrumb">
      <ol class="breadcrumb">
        <li class="breadcrumb-item"><a [routerLink]="['']">Головна</a></li>
        <li class="breadcrumb-item"><a [routerLink]="['/portfolio']">Портфоліо</a></li>
        <li class="breadcrumb-item">Адміністративний готельно - офісний центр БЦ Європа та готель Рамада Енкор</li>
      </ol>
    </nav>
  </div>
</section>

<section class="content-page my-2 my-lg-4">
  <div class="container">
    <div class="title title-zag-block">Адміністративний готельно - офісний центр БЦ Європа та готель Рамада Енкор</div>
  </div>
</section>

<section class="slider">
  <div class="container">
    <div class="portfolio-owl owl-carousel owl-theme">
      <div class="slider-item">
        <img src="../../../assets/images/bc-europe/europe_1.jpg" alt="БЦ Европа"
             class="img-fluid">
      </div>
      <div class="slider-item">
        <img src="../../../assets/images/bc-europe/europe_2.jpg" alt="БЦ Европа"
             class="img-fluid">
      </div>
      <div class="slider-item">
        <img src="../../../assets/images/bc-europe/europe_3.jpg" alt="БЦ Европа"
             class="img-fluid">
      </div>
    </div>
  </div>
</section>
<section class="content-page my-2 my-lg-4">
  <div class="container">
    <p>Адміністративний готельно - офісний центр БЦ Європа та готель Рамада Енкор (м. Київ, Столичне шосе, 101)</p>
  </div>
</section>
<section class="content-page my-2 my-lg-4">
  <div class="container">
    <div class="title title-zag-block">Проведені роботи</div>
    <ol>
      <li>Монтаж мультизональної системи кондиціювання повітря на базі LG Multi V (Корея) з водяним охолодженням на 3,3 МВт – 867 внутрішніх блоків – найбільш масштабний у Європі проект із систем VRF з водяним охолодженням.</li>
    </ol>
  </div>
</section>

<app-our-advantages></app-our-advantages>
<app-our-clients-small></app-our-clients-small>
<app-feedback></app-feedback>
<app-map></app-map>
