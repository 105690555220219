import { Component, OnInit } from '@angular/core';
import {Meta, Title} from '@angular/platform-browser';

@Component({
  selector: 'app-vse-uslugi',
  templateUrl: './vse-uslugi.component.html',
  styleUrls: ['./vse-uslugi.component.css']
})
export class VseUslugiComponent implements OnInit {

  constructor(private title: Title,
              private meta: Meta) {
  }

  ngOnInit(): void {
    this.title.setTitle('Наші послуги - Комплекс Инжиниринг Системз | Инжиниринговые услуги в Киеве');
    this.meta.updateTag({name: 'description', content: 'Послуги інжинірингової компанії Комплекс Інжиніринг Системз в Києві ⭐ Проектування, монтаж, комплектація та постачання обладнання для систем вентиляції'});
    this.meta.updateTag({meta: "keywords", content:"інжинірингова компанія,інжинірингові послуги,інжинірингова компанія Київ,промисловий інжиніринг"})
    window.scroll(0, 0);
  }

}
