import { Component, OnInit } from '@angular/core';
import {Meta, Title} from '@angular/platform-browser';

@Component({
  selector: 'app-feedback-full',
  templateUrl: './feedback-full.component.html',
  styleUrls: ['./feedback-full.component.css']
})
export class FeedbackFullComponent implements OnInit {

  constructor(private title: Title,
              private meta: Meta) {
  }

  ngOnInit(): void {
    this.title.setTitle('Відгуки наших клієнтів - Комплексні інженерні системи | Інжинірингові послуги в Києві');
    this.meta.updateTag({name: 'description', content: 'Відгуки покупців про інжинірингову компанію «Комплексні інженерні системи» в Києві ⭐ Проектування, монтаж, монтаж і поставка обладнання для систем альтернативних джерел тепла'});
    this.meta.updateTag({meta: "keywords", content:"відгуки,відгуки про компанії,огляди складних інженерних систем"})
    window.scroll(0, 0);
  }

}
