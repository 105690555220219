import { Component, OnInit } from '@angular/core';
import {Meta, Title} from '@angular/platform-browser';

@Component({
  selector: 'app-realizatsiya-pod-klyuch',
  templateUrl: './realizatsiya-pod-klyuch.component.html',
  styleUrls: ['./realizatsiya-pod-klyuch.component.css']
})
export class RealizatsiyaPodKlyuchComponent implements OnInit {

  constructor(private title: Title,
              private meta: Meta) { }

  ngOnInit(): void {
    this.title.setTitle('Реалізація об\'єкта «під ключ» - Комплекс Інжиніринг Системз | Інжинірингові послуги у Києві');
    this.meta.updateTag({name: 'description', content: 'Реалізація об\'єкта «під ключ» від інжинірингової компанії Комплекс Інжиніринг Системз в Києві ⭐ Проектування, монтаж, комплектація та постачання обладнання для систем опалення'});
    this.meta.updateTag({meta: "keywords", content:"реализация объекта,реализация объекта киев,реализация объекта под ключ,реализация объекта под ключ киев,інжинірингова компанія,інжинірингові послуги,інжинірингова компанія Київ,промисловий інжиніринг"})
    window.scroll(0, 0);
  }
}
