<div class="d-flex justify-content-center align-items-center" style="min-height: calc(100vh - 300px)">
  <!-- Main content -->
  <section class="content">
    <div class="error-page">
      <h1 class="h-error"><b>404</b></h1>

      <div class="error-content">
        <h2><i class="fas fa-exclamation-triangle" style="color: #dc9835"></i>  Упс! Сторінка не існує.</h2>

        <p>
         Неможливо знайти сторінку, яку ви шукали.<a href="/"> Перейти на головну сторінку</a>
        </p>
      </div>
      <!-- /.error-content -->
    </div>
    <!-- /.error-page -->
  </section>
  <!-- /.content -->
</div>
