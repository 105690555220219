<section class="bread-block">
  <div class="container">
    <nav aria-label="breadcrumb">
      <ol class="breadcrumb">
        <li class="breadcrumb-item"><a [routerLink]="['']">Головна</a></li>
        <li class="breadcrumb-item"><a [routerLink]="['/portfolio']">Портфоліо</a></li>
        <li class="breadcrumb-item">Автосалон «ACURA»</li>
      </ol>
    </nav>
  </div>
</section>

<section class="content-page my-2 my-lg-4">
  <div class="container">
    <div class="title title-zag-block">АВТОСАЛОН «ACURA»</div>
  </div>
</section>

<section class="slider">
  <div class="container">
    <div class="portfolio-owl owl-carousel owl-theme">
      <div class="slider-item">
        <img src="../../../../assets/images/acura/acura_1.jpg" alt="Автосалон «ACURA»"
             class="img-fluid">
      </div>
      <div class="slider-item">
        <img src="../../../../assets/images/acura/acura_2.jpg" alt="Автосалон «ACURA»"
             class="img-fluid">
      </div>
      <div class="slider-item">
        <img src="../../../../assets/images/acura/acura_3.jpg" alt="Автосалон «ACURA»"
             class="img-fluid">
      </div>
    </div>
  </div>
</section>
<section class="content-page my-2 my-lg-4">
  <div class="container">
    <p>Автосалон «ACURA» 2 тыс. М2 (м. Київ, ул. Кільцева дорога, 14).</p>
  </div>
</section>
<section class="content-page my-2 my-lg-4">
  <div class="container">
    <div class="title title-zag-block">Проведені роботи</div>
    <ol>
      <li>Монтаж мультизональної системи кондиціювання
        повітря на базі обладнання LG MULTI V (Корея)
      </li>
      <li>Монтаж вентиляційних систем на базі обладнання VTS Clima (Польща) та
        SALDA (Литва)
      </li>
      <li>Монтаж систем опалення та теплопостачання</li>
    </ol>
  </div>
</section>

<app-our-advantages></app-our-advantages>
<app-our-clients-small></app-our-clients-small>
<app-feedback></app-feedback>
<app-map></app-map>
