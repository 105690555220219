<section class="bread-block">
  <div class="container">
    <nav aria-label="breadcrumb">
      <ol class="breadcrumb">
        <li class="breadcrumb-item"><a [routerLink]="['']">Головна</a></li>
        <li class="breadcrumb-item"><a [routerLink]="['/uslugi']">Наші послуги</a></li>
        <li class="breadcrumb-item">Гарантійне обслуговування</li>
      </ol>
    </nav>
  </div>
</section>
<section class="content-page my-2 my-lg-4">
  <div class="container">
    <div class="title title-zag-block">ГАРАНТІЙНЕ ОБСЛУГОВУВАННЯ</div>
    <p class="ql-align-justify">Навіть при покупці високоякісного та дорогого обладнання ніхто не застрахований від
      наявності заводського шлюбу. Саме з метою виправлення виявлених дефектів та виробничих неполадок існує
      гарантійне обслуговування.</p>
    <p class="ql-align-justify">Гарантійне обслуговування включає всі роботи пов'язані з ремонтом систем, заміною
      вузлів і комплектуючих, налагодження обладнання, що вийшло з ладу, що сталося з вини компанії або фірми –
      виробника змонтованого обладнання.</p>
    <p class="ql-align-justify">Отже, гарантія – це коли в процесі експлуатації протягом гарантійного терміну у вузлах
      системи виявляється заводський шлюб, і виріб підлягає ремонту чи заміні з допомогою виробника. Починається
      гарантійний термін з дня безпосереднього придбання чи встановлення для подальшого використання.</p>
    <p class="ql-align-justify">Гарантійне обслуговування часто плутають із сервісним профілактичним обслуговуванням. на
      насправді це різні речі.</p>
    <p class="ql-align-justify">Протягом гарантійного терміну покупець має право на безкоштовне усунення
      несправностей, спричинених заводським дефектом устаткування, або неякісними монтажними роботами. Гарантія
      діє лише за наявності обслуговування. Вийшов ваш пристрій з ладу без необхідного техобслуговування -
      гарантійного ремонту вже не буде надано.</p>
    <p class="ql-align-justify">Виконавець гарантує справну роботу обладнання протягом гарантійного строку за
      винятком таких випадків:</p>
    <ul>
      <li class="ql-align-justify">якщо в процесі проведення технічного обслуговування було виявлено необхідність
        проведення ремонтних робіт, а замовник відмовився від їх проведення;
      </li>
      <li class="ql-align-justify">якщо протягом гарантійного періоду здійснювався ремонт, обслуговування або
        модернізація обладнання силами самого власника чи інших організацій;
      </li>
      <li class="ql-align-justify">якщо відмова сталася через порушення власником правил експлуатації обладнання
        та деяких інших.
      </li>
    </ul>
    <p class="ql-align-justify">Всі ці нюанси обумовлюються чинним законодавством.</p>
    <p class="ql-align-justify">На все обладнання та виконані роботи <strong>компанія «Комплекс»
      Інжиніринг Системз»</strong> надасть вам гарантію. На всі роботи з проектування та монтажу інженерних систем
      та обладнання компанія має відповідні допуски. Все обладнання, що встановлюється, має сертифікати
      відповідності та інші дозвільні документи. При покупці обладнання, будь ласка, перевірте, чи видано вам
      гарантійний талон, чи є у ньому підпис співробітника компанії та штамп.</p>
    <p class="ql-align-justify">Для уникнення непорозумінь ми просимо вас уважно ознайомитися з Інструкцією з
      експлуатації придбаного обладнання та не допускати його роботи у критичних режимах або не за призначенням.</p>
    <p class="ql-align-justify">При виникненні питань, пов'язаних з експлуатацією придбаного обладнання ви
      можете будь-якої миті звернутися до наших фахівців за технічною консультацією.</p>
    <p class="ql-align-justify">Підхід компанії <strong>компанії «Комплекс Інжиніринг Системз»</strong> гарантує клієнту
      своєчасне вирішення будь-яких проблем протягом усього гарантійного терміну!</p>
    <p class="ql-align-justify">Відмінною рисою сервісної служби є швидке реагування та виїзд
      сервісного інженера на будь-яке звернення клієнта протягом гарантійного терміну.</p>
  </div>
</section>
<app-additional-services></app-additional-services>
<app-our-advantages></app-our-advantages>
<app-our-clients-small></app-our-clients-small>
<app-feedback></app-feedback>
<app-map></app-map>
