<section class="bread-block">
  <div class="container">
    <nav aria-label="breadcrumb">
      <ol class="breadcrumb">
        <li class="breadcrumb-item"><a [routerLink]="['']">Головна</a></li>
        <li class="breadcrumb-item"><a [routerLink]="['/portfolio']">Портфоліо</a></li>
        <li class="breadcrumb-item">Офісне приміщення</li>
      </ol>
    </nav>
  </div>
</section>

<section class="content-page my-2 my-lg-4">
  <div class="container">
    <div class="title title-zag-block">ОФІСНЕ ПРИМІЩЕННЯ</div>
  </div>
</section>

<section class="slider">
  <div class="container">
    <div class="portfolio-owl owl-carousel owl-theme">
      <div class="slider-item">
        <img src="../../../../assets/images/office-1.jpeg" alt="Офисное помещение"
             class="img-fluid">
      </div>
    </div>
  </div>
</section>
<section class="content-page my-2 my-lg-4">
  <div class="container">
    <p>Офісне приміщення (м. Київ, Харківське шосе, 154) – 3 тис. м2.</p>
  </div>
</section>
<section class="content-page my-2 my-lg-4">
  <div class="container">
    <div class="title title-zag-block">Проведені роботи</div>
    <ol>
      <li>Монтаж системи радіаторного опалення та теплопостачання на базі обладнання Romstall</li>
    </ol>
  </div>
</section>

<app-our-advantages></app-our-advantages>
<app-our-clients-small></app-our-clients-small>
<app-feedback></app-feedback>
<app-map></app-map>
