<section class="bread-block">
  <div class="container">
    <nav aria-label="breadcrumb">
      <ol class="breadcrumb">
        <li class="breadcrumb-item"><a [routerLink]="['']">Головна</a></li>
        <li class="breadcrumb-item"><a [routerLink]="['/portfolio']">Портфоліо</a></li>
        <li class="breadcrumb-item">Торгово-розважальний центр "Атмосфера"</li>
      </ol>
    </nav>
  </div>
</section>

<section class="content-page my-2 my-lg-4">
  <div class="container">
    <div class="title title-zag-block">ТОРГОВО-РОЗВАЖАЛЬНИЙ ЦЕНТР «АТМОСФЕРА»</div>
  </div>
</section>

<section class="slider">
  <div class="container">
    <div class="portfolio-owl owl-carousel owl-theme">
      <div class="slider-item">
        <img src="../../../../assets/images/atmosfera/atmosfera_1.jpg" alt="Торгово-розважальний центр «Атмосфера»"
             class="img-fluid">
      </div>
      <div class="slider-item">
        <img src="../../../../assets/images/atmosfera/atmosfera_2.jpg" alt="Торгово-розважальний центр «Атмосфера»"
             class="img-fluid">
      </div>
      <div class="slider-item">
        <img src="../../../../assets/images/atmosfera/atmosfera_3.jpg" alt="Торгово-розважальний центр «Атмосфера»"
             class="img-fluid">
      </div>
      <div class="slider-item">
        <img src="../../../../assets/images/atmosfera/atmosfera_4.jpg" alt="Торгово-розважальний центр «Атмосфера»"
             class="img-fluid">
      </div>
    </div>
  </div>
</section>
<section class="content-page my-2 my-lg-4">
  <div class="container">
    <p>Торгово-розважальний центр "Атмосфера" (м. Київ, Столичне шосе, 101), загальна площа 38 тис. м2</p>
  </div>
</section>
<section class="content-page my-2 my-lg-4">
  <div class="container">
    <div class="title title-zag-block">Проведені роботи</div>
    <ol>
      <li>Монтаж систем холодопостачання на базі обладнання RHOSS (Італія) – 2,7 МВт</li>
      <li>Установка систем кондиціювання на базі обладнання LG (Корея)</li>
      <li>Установка додаткової холодильної машини Climaveneta (Італія) -795 кВт7</li>
    </ol>
  </div>
</section>

<app-our-advantages></app-our-advantages>
<app-our-clients-small></app-our-clients-small>
<app-feedback></app-feedback>
<app-map></app-map>
