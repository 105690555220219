import { Injectable } from '@angular/core';

@Injectable()
export class ErrorService {

  constructor() { }

  public getErrorMessage(error: any): string {
    if (error === null || error === undefined) {
      return 'Щось пішло не так, зв\'яжіться з адміністратором';
    } else {
      return error.error.message !== null && error.error.message !== undefined
      && error.error.message !== '' ? error.error.message : 'Щось пішло не так, зв\'яжіться з адміністратором';
    }
  }
}
