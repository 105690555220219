<section class="bread-block">
  <div class="container">
    <nav aria-label="breadcrumb">
      <ol class="breadcrumb">
        <li class="breadcrumb-item"><a [routerLink]="['']">Головна</a></li>
        <li class="breadcrumb-item"><a [routerLink]="['/portfolio']">Портфоліо</a></li>
        <li class="breadcrumb-item">Пивний клуб - міні - пивоварня «Біркрафт»</li>
      </ol>
    </nav>
  </div>
</section>

<section class="content-page my-2 my-lg-4">
  <div class="container">
    <div class="title title-zag-block">ПИВНИЙ КЛУБ - МІНІ - ПИВОВАРНЯ «БІРКРАФТ»</div>
  </div>
</section>

<section class="slider">
  <div class="container">
    <div class="portfolio-owl owl-carousel owl-theme">
      <div class="slider-item">
        <img src="../../../../assets/images/pivobar.jpg" alt="Пивний клуб - міні - пивоварня «Біркрафт»"
             class="img-fluid">
      </div>
    </div>
  </div>
</section>
<section class="content-page my-2 my-lg-4">
  <div class="container">
    <p>Пивний клуб - міні - пивоварня «Біркрафт» - близько 1 тис. м. (М. Київ, вул. Старосельська 1Е).</p>
  </div>
</section>
<section class="content-page my-2 my-lg-4">
  <div class="container">
    <div class="title title-zag-block">Проведені роботи</div>
    <ol>
      <li>Установка систем кондиціювання на базі обладнання Daikin (Японія)</li>
      <li>Монтаж систем вентиляції на базі обладнання Salda (Литва)</li>
      <li>Монтаж систем опалення</li>
      <li>Монтаж систем каналізації та водопостачання</li>
    </ol>
  </div>
</section>

<app-our-advantages></app-our-advantages>
<app-our-clients-small></app-our-clients-small>
<app-feedback></app-feedback>
<app-map></app-map>
