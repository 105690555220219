import { Component, OnInit } from '@angular/core';
import {Meta, Title} from '@angular/platform-browser';

@Component({
  selector: 'app-analiz-optimizacziya-proektov',
  templateUrl: './analiz-optimizacziya-proektov-ovik.component.html',
  styleUrls: ['./analiz-optimizacziya-proektov-ovik.component.css']
})
export class AnalizOptimizacziyaProektovOvikComponent implements OnInit {

  constructor(private title: Title,
              private meta: Meta) {
  }

  ngOnInit(): void {
    this.title.setTitle('Аналіз та оптимізація проектів по розділах овік - Комплекс Інжиніринг Системз | Інжинірингові послуги у Києві');
    this.meta.updateTag({name: 'description', content: 'Аналіз та оптимізація проектів по розділах овик від інжинірингової компанії Комплекс Інжиніринг Системз в Києві ⭐ Проектування, монтаж, комплектація та постачання обладнання для систем альтернативних джерел тепла'});
    this.meta.updateTag({meta: "keywords", content:"аналіз та оптимізація проектів"})
    window.scroll(0, 0);
  }

}
