import { Component, OnInit } from '@angular/core';
import {Meta, Title} from '@angular/platform-browser';

@Component({
  selector: 'app-komplektacziya-postavka-oborudovaniy',
  templateUrl: './komplektacziya-postavka-oborudovaniy.component.html',
  styleUrls: ['./komplektacziya-postavka-oborudovaniy.component.css']
})
export class KomplektacziyaPostavkaOborudovaniyComponent implements OnInit {

  constructor(private title: Title,
              private meta: Meta) {
  }

  ngOnInit(): void {
    this.title.setTitle('Комплектація та постачання сучасного обладнання - Комплекс Інжиніринг Системз | Інжинірингові послуги у Києві');
    this.meta.updateTag({name: 'description', content: 'Комплектація та постачання сучасного обладнання від інжинірингової компанії Комплекс Інжиніринг Системз в Києві ⭐ Проектування, монтаж, комплектація та постачання обладнання для систем альтернативних джерел тепла'});
    this.meta.updateTag({meta: "keywords", content:"комплектация и поставка современного оборудования,інжинірингова компанія,інжинірингові послуги,інжинірингова компанія Київ,промисловий інжиніринг"})
    window.scroll(0, 0);
  }

}
