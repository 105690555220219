<section class="bread-block">
  <div class="container">
    <nav aria-label="breadcrumb">
      <ol class="breadcrumb">
        <li class="breadcrumb-item"><a [routerLink]="['']">Головна</a></li>
        <li class="breadcrumb-item"><a [routerLink]="['/portfolio']">Портфоліо</a></li>
        <li class="breadcrumb-item">Мережа контактних зоопарків «Країна Єнотія»</li>
      </ol>
    </nav>
  </div>
</section>

<section class="content-page my-2 my-lg-4">
  <div class="container">
    <div class="title title-zag-block">МЕРЕЖА КОНТАКТНИХ ЗООПАРКІВ «КРАЇНА ЄНОТІЯ»</div>
  </div>
</section>

<section class="slider">
  <div class="container">
    <div class="portfolio-owl owl-carousel owl-theme">
      <div class="slider-item">
        <img src="../../../../assets/images/etoniya/etoniya_1.jpg" alt="Сеть контактных зоопарков «Страна Енотия»"
             class="img-fluid">
      </div>
      <div class="slider-item">
        <img src="../../../../assets/images/etoniya/etoniya_2.jpg" alt="Сеть контактных зоопарков «Страна Енотия»"
             class="img-fluid">
      </div>
      <div class="slider-item">
        <img src="../../../../assets/images/etoniya/etoniya_1.jpg" alt="Сеть контактных зоопарков «Страна Енотия»"
             class="img-fluid">
      </div>
    </div>
  </div>
</section>
<section class="content-page my-2 my-lg-4">
  <div class="container">
    <p>Мережа контактних зоопарків "Країна Єнотія" (м. Київ ТЦ "ART Mall", м. Бориспіль ТЦ "Aeromаll" м. Херсон ТЦ
      "Фабрика", м. Чернігів ТРЦ "Голлівуд").</p>
  </div>
</section>
<section class="content-page my-2 my-lg-4">
  <div class="container">
    <div class="title title-zag-block">Проведені роботи</div>
    <ol>
      <li>Монтаж систем вентиляції на базі обладнання GKT, Аеростар, SALDA та LG Multi V</li>
      <li>Монтаж систем кондиціювання на базі обладнання GKT, Аеростар, SALDA та LG Multi V</li>
      <li>Монтаж систем водопостачання та каналізації</li>
    </ol>
  </div>
</section>

<app-our-advantages></app-our-advantages>
<app-our-clients-small></app-our-clients-small>
<app-feedback></app-feedback>
<app-map></app-map>
