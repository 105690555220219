import {Component, OnInit} from '@angular/core';
import {Meta, Title} from '@angular/platform-browser';

@Component({
  selector: 'app-montazh-nalad-servis',
  templateUrl: './montazh-nalad-servis.component.html',
  styleUrls: ['./montazh-nalad-servis.component.css']
})
export class MontazhNaladServisComponent implements OnInit {

  constructor(private title: Title,
              private meta: Meta) {
  }

  ngOnInit(): void {
    this.title.setTitle('Монтажні, пусконалагоджувальні та сервісні роботи- Комплекс Інжиніринг Системз | Інжинірингові послуги у Києві');
    this.meta.updateTag({
      name: 'description',
      content: 'Комплект Інжиніринг Системз в Києві ⭐ Проектування, монтаж, ремонт, діагностика та обслуговування ініжинірингових систем від професіоналів за доступними цінами'
    });
    this.meta.updateTag({
      meta: 'keywords',
      content: 'монтажные работы киев,сервисные работы киев,монтажные, пуско-наладочные и сервисные работы,інжинірингова компанія,інжинірингові послуги,інжинірингова компанія Київ,промисловий інжиніринг'
    });
    window.scroll(0, 0);
  }
}
