<section class="bread-block">
  <div class="container">
    <nav aria-label="breadcrumb">
      <ol class="breadcrumb">
        <li class="breadcrumb-item"><a [routerLink]="['']">Головна</a></li>
        <li class="breadcrumb-item"><a [routerLink]="['/portfolio']">Портфоліо</a></li>
        <li class="breadcrumb-item">Освіторія ХАб</li>
      </ol>
    </nav>
  </div>
</section>

<section class="content-page my-2 my-lg-4">
  <div class="container">
    <div class="title title-zag-block">ОСВІТОРІЯ ХАБ</div>
  </div>
</section>

<section class="slider">
  <div class="container">
    <div class="portfolio-owl owl-carousel owl-theme">
      <div class="slider-item">
        <img src="../../../assets/images/osvitoriya/osvitoriya_1.jpeg" alt="Освитория ХАб"
             class="img-fluid">
      </div>
      <div class="slider-item">
        <img src="../../../assets/images/osvitoriya/osvitoriya_2.jpeg" alt="Освитория ХАб"
             class="img-fluid">
      </div>
      <div class="slider-item">
        <img src="../../../assets/images/osvitoriya/osvitoriya_3.jpeg" alt="Освитория ХАб"
             class="img-fluid">
      </div>
    </div>
  </div>
</section>
<section class="content-page my-2 my-lg-4">
  <div class="container">
    <p>Освіторія Хаб (м. Київ, Московська 8). Загальна площа офісних приміщень: 916,35м²</p>
  </div>
</section>
<section class="content-page my-2 my-lg-4">
  <div class="container">
    <div class="title title-zag-block">Проведені роботи</div>
    <ol>
      <li>Монтаж систем припливно-витяжної вентиляції на базі трьох припливно-витяжних установок (загальна витрата повітря припливного: 10140 м3 год)</li>
      <li>Монтаж мультизональної системи кондиціювання повітря (холодильна потужність 140,4 кВт)</li>
    </ol>
  </div>
</section>

<app-our-advantages></app-our-advantages>
<app-our-clients-small></app-our-clients-small>
<app-feedback></app-feedback>
<app-map></app-map>
