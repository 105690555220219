<section class="bread-block">
  <div class="container">
    <nav aria-label="breadcrumb">
      <ol class="breadcrumb">
        <li class="breadcrumb-item"><a [routerLink]="['']">Головна</a></li>
        <li class="breadcrumb-item"><a [routerLink]="['/portfolio']">Портфоліо</a></li>
        <li class="breadcrumb-item">Бізнес Центр "Український Капітал"</li>
      </ol>
    </nav>
  </div>
</section>

<section class="content-page my-2 my-lg-4">
  <div class="container">
    <div class="title title-zag-block">БІЗНЕС ЦЕНТР «УКРАЇНСЬКИЙ КАПІТАЛ»</div>
  </div>
</section>

<section class="slider">
  <div class="container">
    <div class="portfolio-owl owl-carousel owl-theme">
      <div class="slider-item">
        <img src="../../../../assets/images/ukrkapital/ukrcapital_1.jpg" alt="Бизнес Центр «Украинский Капитал»"
             class="img-fluid">
      </div>
      <div class="slider-item">
        <img src="../../../../assets/images/ukrkapital/ukrcapital_2.jpg" alt="Бизнес Центр «Украинский Капитал»"
             class="img-fluid">
      </div>
      <div class="slider-item">
        <img src="../../../../assets/images/ukrkapital/ukrcapital_2.jpg" alt="Бизнес Центр «Украинский Капитал»"
             class="img-fluid">
      </div>
    </div>
  </div>
</section>
<section class="content-page my-2 my-lg-4">
  <div class="container">
    <p>Бізнес-центр «Український Капітал», офісний 6-поверховий бізнес-центр (м. Львів).</p>
  </div>
</section>
<section class="content-page my-2 my-lg-4">
  <div class="container">
    <div class="title title-zag-block">Проведені роботи</div>
    <ol>
      <li>Монтаж напівпромислових та побутових систем кондиціювання повітря на базі обладнання Fuji Еelectric
        (Японія)
      </li>
    </ol>
  </div>
</section>

<app-our-advantages></app-our-advantages>
<app-our-clients-small></app-our-clients-small>
<app-feedback></app-feedback>
<app-map></app-map>
