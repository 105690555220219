<section class="bread-block">
  <div class="container">
    <nav aria-label="breadcrumb">
      <ol class="breadcrumb">
        <li class="breadcrumb-item"><a [routerLink]="['']">Головна</a></li>
        <li class="breadcrumb-item">Контакти</li>
      </ol>
    </nav>
  </div>
</section>
<section class="content-page my-2 my-lg-4">
  <div class="container">
    <div class="title title-zag-block">Контакти</div>
    <p>Ви завжди можете звернутися до наших співробітників за детальною інформацією, консультацією або замовлення послуг. Ми відкриті до нових проектів та будь-яких видів співпраці! Якщо у вас є нестандартна пропозиція або уточнююче питання – зв'яжіться з нами будь-яким доступним способом:</p>
    <p><strong>Контакти «Комплекс Інжиніринг Системз», ТОВ</strong></p>
    <p><strong>Телефони:</strong> +38(044) 300-02-36;  +38(095) 276-40-08;  +38(093) 177-05-18;  +38(067) 690-89-95</p>
    <p><strong>Електронна пошта:</strong> info@cis.kiev.ua</p>
    <p><strong>Адреса:</strong> м. Київ, вул. Микільсько-Ботанічна 6/8</p>
  </div>
</section>
<app-our-advantages></app-our-advantages>
<app-our-clients-small></app-our-clients-small>
<app-feedback></app-feedback>
<app-map></app-map>
