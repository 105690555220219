<section class="bread-block">
  <div class="container">
    <nav aria-label="breadcrumb">
      <ol class="breadcrumb">
        <li class="breadcrumb-item"><a [routerLink]="['']">Головна</a></li>
        <li class="breadcrumb-item">Осушення</li>
      </ol>
    </nav>
  </div>
</section>
<section class="block-uslugi my-2 my-lg-4">
  <div class="container">
    <div class="title title-zag-block">Осушення</div>
    <p class="ql-align-justify">Організація ефективної вентиляції критих залів плавальних басейнів є однією з
      найскладніших завдань, що стоять перед проектувальниками, підрядниками та користувачами технічними службами цього
      типу об'єктів. Правильна вентиляція залу басейну - це тема, яка також має зацікавити та,
      можливо, насамперед власників басейнових комплексів. Тому що вони, спокушені низькою ціною, часто
      вирішують використовувати надто прості і, отже, неефективні рішення.&nbsp;</p>
    <h4><strong>Для чого нам потрібна рециркуляція повітря?</strong></h4>
    <p class="ql-align-justify">Вентиляція басейну - це, перш за все, світ, здавалося б, незначних деталей, без
      яких належне функціонування об'єкта неможливе. Одним з таких елементів, що істотно впливають на
      ефективність і, перш за все, економічний аспект вентиляції є рециркуляція повітря.</p>
    <p class="ql-align-justify">Сучасна вентиляція критих залів плавальних басейнів здійснюється за допомогою
      таких вентиляційних пристроїв, як:</p>
    <p class="ql-align-justify">- осушувачі повітря (використовуються для приміщень з низькою вологістю, наприклад,
      шкільних басейнах, басейнах лікарень або тільки з джакузі) та</p>
    <p class = "ql-align-justify">-вентиляційні центри (використовуються для всіх типів пулів). </p>
    <p class = "ql-align-estify"> колишня робота в більшості випадків, використовуючи механічне охолодження
      циркулююче повітря, тому проблема рециркуляції в основному стосується систем на основі вентиляції
      Центри басейну. </p>
    <p class = "ql-align-justify"> Рециркуляція вихлопного повітря в основному використовується для регулювання вологості в
      приміщення для басейну взимку. & nbsp; рециркуляційне повітря з високим вмістом вологи змішується із зовнішнім повітрям,
      для якого цей параметр взимку значно нижчий. & nbsp; правильна частка цих двох потоків повітря
      (Наприклад, 20% свіжого повітря) дозволяє отримати такий вміст вологи в повітрі, дякую
      кому ми видалимо збільшення вологи з басейну, не викликаючи "сушіння" повітря по відношенню до конструкції
      параметри. & nbsp; це також дає певну ефективність під час несподіваного збільшення припливу вологи в
      Басейновий зал. </p>
    <h4> <strong> Отже, чому пристрій вентиляції повинен мати можливість рециркуляції? & nbsp; якщо
      Мені потрібно 2000 m & nbsp; 3 & nbsp;/ h & nbsp; для осушення басейну взимку, чому & nbsp; i & nbsp; 10 000 m & nbsp; 3 & nbsp;
      H? </strong> </h4>
    <p class = "ql-align-estify"> Це пов'язано з кількома аспектами, а найважливіший є найважливішим для залів
      кількість повітря, необхідного для усунення зростання вологи влітку. & NBSP; відповідно до рекомендацій
      Стандарт VDI 2089 визначається на основі обчисленого росту вологості в басейновому залі та
      Різниця у вологості в повітрі в басейновому залі та зовнішньому повітрі. & NBSP; тому вентиляція здійснюється
      Використання лише зовнішнього повітря. </p>
    <p class = "ql-align-justify"> Варто вивчити цю проблему на прикладі. & nbsp; дозволи: </p>
    <p class = "ql-align-justify">-збільшення вологи в залі басейну: 32,52 кг / год, </p>
    <p class = "ql-align-justify">-зимові параметри повітряного повітря: -16 ° C / 100% (i зона відповідно до PN-82 /
      B-02403), </p>
    <p class = "QL-align-justify">-Параметри повітря на свіжому повітрі влітку: 30 ° C / 45% (II зона відповідно до PN-82 /
      B-02403), </p>
    <p class = "QL-align-justify">-Параметри повітря в залі басейну взимку та влітку: 30 ° C / 55%, </p>
    <p class = "ql-align-justify">-щільність повітря 1,2 кг / m & nbsp; 3 & nbsp;, </p>
    <p class = "ql-align-justify">-питома теплоємність повітря: 1,005 кДж / (кг · k). </p>
    <p class = "ql-align-justify"> в аналізованому випадку різниця в вмісті вологи між повітрям у залі та зовнішнім
      Повітря становить лише 2,71 г / кг сухого повітря. </p>
    <p class = "ql-align-justify"> Використовуючи стандарт VDI 2089, ми очікуємо, що для того, щоб витікати зал басейну,
      Працюючи в передбачуваних умовах, нам потрібно використовувати 12 000 кг повітря на годину, що означає споживання
      Вентиляційний пристрій становить близько 10 000 m & nbsp; 3 & nbsp;/ h. & Nbsp; </p>
    <p class = "ql-align-justify"> попередження! & nbsp; слід пам’ятати, що критерій кількості повітря на основі на основі на основі на основі на основі на основі на основі на основі на основі на основі
      Видалення росту вологи - не єдиний критерій, який визначає кількість повітря. & nbsp; розрахунки повинні
      перевірити відповідно до інших критеріїв. & nbsp; Для цілей цього прикладу передбачається, що в порівнянні
      Аналогічний потік повітря був отриманий з іншими критеріями. </p>
    <h4> <strong> Як виглядатиме ситуація з аналізованим об'єктом взимку? & nbsp; </strong>
    </h4>
    <p class = "ql-align-justify"> Прийняті припущення показують, що взимку різниця у вмісті вологи між повітрям
      На об'єкті та на відкритому повітрі це вже 13,73 г / кг сухої речовини. & NBSP; в цьому випадку для усунення
      Для збільшення вологості нам потрібно лише близько 2369 кг повітря на годину, тобто приблизно 1974 M & nbsp; 3 & nbsp;/ h, тобто, тобто,
      19,7% від загальної кількості витрат пристрою.
      Змішування потоків свіжого та рециркуляційного повітря, ми отримуємо вміст вологи у подачі повітря 11,95 г /
      кг сухого повітря.
      Мінімальна кількість свіжого повітря. & Nbsp; </p>
    <h4> <strong> Що робити, якщо встановити пристрій без рециркуляції? </strong> </h4>
    <p class = "ql-align-justify"> Варто посилатися на вже проаналізований приклад. & nbsp; найсерйозніший ефект
      Використання пристрою без можливості рециркуляції взимку буде в першу чергу «сушіння басейну», тобто
      Значне зменшення вмісту вологи в повітрі басейну нижче параметрів проектування і, таким чином, завершено
      Налаштування вентиляційної системи відповідно до припущень. & NBSP; ця ситуація пов'язана в першу чергу з
      Збільшення експлуатаційних витрат об'єкта (наприклад, витрати на нагрівання повітря), небезпека
      Пошкодження будівництва будівлі або навіть поганого настрою користувачів басейну. & NBSP; в аналізованому
      Приклад, використовуючи лише свіже повітря, продуктивність водовідведення становитиме близько 164 кг / год, що
      перевищує потребу в дизайні в п’ять разів. & nbsp; таке висушене повітря слід змочити, наприклад, використовуючи
      Зволожуючий крем. </p>
    <h4> <strong> Чи достатньо запустити пристрій взимку лише кілька хвилин на годину або
      Зменшити його споживання? & Nbsp; </strong> </h4>
    <p class = "ql-align-justify">, на жаль, & nbsp; Крім функції сушіння, кондиціонер також має інші
      Завдання, такі як: Опалення басейнового залу, забезпечення необхідної кількості свіжого повітря в гігієнічному
      причини, забезпечення необхідного обміну повітрям на об'єкті або навіть підтримання постійного руху повітря в
      Зал басейну. & NBSP; Запуск пристрою на кілька хвилин, а також зменшення витрат
      Неможливо виконати деякі з цих завдань. & NBSP; Наприклад, вимкнено пристрій не може виконувати функцію
      Опалення басейнового залу. & NBSP; у свою чергу, пристрій, що працює лише на кілька хвилин
      Кількість повітря, необхідного для запобігання конденсації на скляних перегородках, таких як Windows. </p>
    <h4> <strong> Який пристрій я повинен шукати? </strong> </h4>
    <p class = "ql-align-justify"> Немає однозначної відповіді на це питання. & nbsp; Використання конкретного пристрою
      Потрібно, перш за все, бути виправданим з точки зору дизайну, тобто мати такі функції та компоненти
      Можна було використовувати вентиляційну систему на основі такого пристрою одночасно дешево і в той же час дешево.
      Сучасні пристрої оснащені компонентами, такими як високоефективне наглядач тепла (наприклад,
      Теплообмінник з прокладкою або прокладкою), повітряні насоси, додаткові конденсатори для нагріву
      Вода в басейні, дві камери рециркуляції. & NBSP; через такі конфігурації функціональність пристрою
      значно розширюється і завдяки багаторівневому відновленню тепла, експлуатації
      Витрати & nbsp; </p>
    <h4> <strong> Вам потрібна рециркуляція? & nbsp; </strong> </h4>
    <p class = "ql-align-justify"> слід пам’ятати, що неможливо досягти ефективної та дешевшої вентиляції залу
      пул без його використання. & nbsp; </p>
    <p class = "ql-align-justify"> команда експертів & nbsp; <strong> комплексу систем інжініруру </strong>
      виконує проект, постачання та встановлення систем дренажу під ключ, з урахуванням усіх поточних стандартів та
      стандарти якомога швидше. </p>
  </div>
</section>
<app-our-advantages></app-our-advantages>
<app-our-clients-small></app-our-clients-small>
<app-feedback></app-feedback>
<app-map></app-map>
