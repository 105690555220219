<section class="bread-block">
  <div class="container">
    <nav aria-label="breadcrumb">
      <ol class="breadcrumb">
        <li class="breadcrumb-item"><a [routerLink]="['']">Головна</a></li>
        <li class="breadcrumb-item"><a [routerLink]="['/portfolio']">Портфоліо</a></li>
        <li class="breadcrumb-item">Офіси IT-компаній Jooble та Skyloom</li>
      </ol>
    </nav>
  </div>
</section>

<section class="content-page my-2 my-lg-4">
  <div class="container">
    <div class="title title-zag-block">Офіси IT-компаній Jooble та Skyloom</div>
  </div>
</section>

<section class="slider">
  <div class="container">
    <div class="portfolio-owl owl-carousel owl-theme">
      <div class="slider-item">
        <img src="../../../assets/images/jooble-skyloom/jooble_skyloom_1.jpg" alt="Офисы IT-компаний Jooble и Skyloom"
             class="img-fluid">
      </div>
      <div class="slider-item">
        <img src="../../../assets/images/jooble-skyloom/jooble_skyloom_2.jpg" alt="Офисы IT-компаний Jooble и Skyloom"
             class="img-fluid">
      </div>
      <div class="slider-item">
        <img src="../../../assets/images/jooble-skyloom/jooble_skyloom_3.jpg" alt="Офисы IT-компаний Jooble и Skyloom"
             class="img-fluid">
      </div>
      <div class="slider-item">
        <img src="../../../assets/images/jooble-skyloom/jooble_skyloom_4.jpg" alt="Офисы IT-компаний Jooble и Skyloom"
             class="img-fluid">
      </div>
      <div class="slider-item">
        <img src="../../../assets/images/jooble-skyloom/jooble_skyloom_5.jpg" alt="Офисы IT-компаний Jooble и Skyloom"
             class="img-fluid">
      </div>
    </div>
  </div>
</section>
<section class="content-page my-2 my-lg-4">
  <div class="container">
    <p>Офіси IT-компаній Jooble та Skyloom (м. Київ, вул. Костянтинівська, 71). Загальна площа 2,5 тис. м2</p>
  </div>
</section>
<section class="content-page my-2 my-lg-4">
  <div class="container">
    <div class="title title-zag-block">Проведені роботи</div>
    <ol>
      <li>Монтаж систем вентиляції, кондиціювання, опалення.</li>
      <li>Монтаж системи кондиціювання на базі обладнання LG Multi V (Корея)</li>
      <li>Монтаж системи вентиляції на базі обладнання Aerostar (Україна)</li>
      <li>Монтаж системи опалення на теплових насосах</li>
    </ol>
  </div>
</section>

<app-our-advantages></app-our-advantages>
<app-our-clients-small></app-our-clients-small>
<app-feedback></app-feedback>
<app-map></app-map>
