import {Component, OnInit} from '@angular/core';
import {Meta, Title} from '@angular/platform-browser';

@Component({
  selector: 'app-konditsionirovaniye-sklada',
  templateUrl: './konditsionirovaniye-sklada.component.html',
  styleUrls: ['./konditsionirovaniye-sklada.component.css']
})
export class KonditsionirovaniyeSkladaComponent implements OnInit {

  constructor(private title: Title,
              private meta: Meta) { }

  ngOnInit(): void {
    this.title.setTitle('Кондиціювання складу - - Комплекс Інжиніринг Системз | Інжинірингові послуги у Києві');
    this.meta.updateTag({name: 'description', content: 'Кондиціювання складу від інжинірингової компанії Комплекс Інжиніринг Системз в Києві ⭐ Проектування, монтаж, комплектація та постачання обладнання для систем вентиляції'});
    this.meta.updateTag({meta: "keywords", content:"кондиционирование,кондиционирование киев,кондиционирование склада под ключ,інжинірингова компанія,інжинірингові послуги,інжинірингова компанія Київ,промисловий інжиніринг"})
    window.scroll(0, 0);
  }

}
