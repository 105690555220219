<section class="bread-block">
  <div class="container">
    <nav aria-label="breadcrumb">
      <ol class="breadcrumb">
        <li class="breadcrumb-item"><a [routerLink]="['']">Головна</a></li>
        <li class="breadcrumb-item">Відгуки</li>
      </ol>
    </nav>
  </div>
</section>
<section class="block-uslugi my-2 my-lg-4">
  <div class="container">
    <div class="title title-zag-block">Відгуки наших клієнтів</div>
    <div class="row">
      <div class="col-12 col-sm-12 col-md-6 col-lg-4 feedback-item">
        <div class="otzivi-item">
          <div class="name-otziv">ТОВ "ТЕЛЕКАНАЛ СТБ"</div>
          <div class="city-otziv">м. Київ</div>
          <div class="desc-otziv"><img src="../../../assets/images/sert1.jpg" alt="" class="img-sert-otz">ТОВ «Телеканал»
            СТБ" висловлює щиру подяку колективу компанії ТОВ "Комплекс"
            Інженерні системи» за надані послуги з оснащення наших приміщень кліматичними системами.
            Виконуються всі види робіт з монтажу та обслуговування систем кондиціонування, опалення та вентиляції
            професійно, в короткі терміни, відповідно до високих європейських стандартів і вимог до якості.
            Інженери компанії «Комплексні інженерні системи» успішно знаходять оптимальні рішення технічних питань,
            що виникають в процесі роботи.
            Рекомендуємо компанію ТОВ «Комплексні Інженерні Системи» як надійного партнера і компанію з сучасними
            прогресивні підходи до виконання робіт.
            <br>
            <br>
            <br>
          </div>
        </div>
      </div>
      <div class="col-12 col-sm-12 col-md-6 col-lg-4 feedback-item">
        <div class="otzivi-item">
          <div class="name-otziv">ТОВ «ЛЮБАВА СІТІ»</div>
          <div class="city-otziv">м. Черкаси</div>
          <div class="desc-otziv"><img src="../../../assets/images/sert1.jpg" alt="" class="img-sert-otz">ТО.В. «ЛЮБАВА
            CITY» висловлює подяку співробітникам ТОВ «КОМПЛЕКСНІ ІНЖЕНЕРНІ СИСТЕМИ» за ефективну та якісну
            надання послуг у сфері постачання, монтажу та пусконалагоджувальних робіт з вентиляції, кондиціювання та
            Опалення.
            У процесі співпраці ТОВ «КОМПЛЕКСНІ ІНЖЕНЕРНІ СИСТЕМИ» зарекомендувало себе як надійний партнер і
            професійна, надійна команда, націлена на досягнення результату.
            Стиль роботи ТОВ «КОМПЛЕКСНІ ІНЖЕНЕРНІ СИСТЕМИ» відрізняється творчим підходом до вирішення складних завдань і
            ретельне виконання завдань в короткі терміни.
            Враховуючи позитивний досвід співпраці, рекомендуємо ТОВ «КОМПЛЕКСНІ ІНЖЕНЕРНІ СИСТЕМИ» як надійне
            партнер, який завжди ретельно виконує взяті на себе зобов'язання і гарантує високу якість своїх
            працювати.
          </div>
        </div>
      </div>
      <div class="col-12 col-sm-12 col-md-6 col-lg-4 feedback-item">
        <div class="otzivi-item">
          <div class="name-otziv">ТОВ «Будхаус Групп»</div>
          <div class="city-otziv">м. Черкаси</div>
          <div class="desc-otziv"><img src="../../../assets/images/sert1.jpg" alt="" class="img-sert-otz">Організація ТОВ
            «Комплекс Інженерних Систем» виконав роботи з монтажу систем вентиляції та кондиціонування в
            ТРК "Любава".
            Роботи виконувалися відповідно до чинних будівельних норм і правил, згідно з технічними
            завдання та умови договору, при належній якості та в строк. При цьому в процесі виробництва
            максимально враховані побажання Замовника, використані сучасні будівельні матеріали та
            технології у виробництві робіт.
            За час роботи організація ТОВ «Комплексні інженерні системи» зарекомендувала себе як
            високопрофесійна компанія з кваліфікованим персоналом, оперативно і якісно приймає рішення
            поставлені завдання.
            <br>
            <br>
            <br>
            <br>
          </div>
        </div>
      </div>
      <div class="col-12 col-sm-12 col-md-6 col-lg-4 feedback-item">
        <div class="otzivi-item">
          <div class="name-otziv">Компанія "Нітеко"</div>
          <div class="city-otziv">м. Київ</div>
          <div class="desc-otziv"><img src="../../../assets/images/sert1.jpg" alt="" class="img-sert-otz">Від імені
            директори товариства з обмеженою відповідальністю «Нітеко» щиро вдячні за плідні
            робота і допомога в області технічного обслуговування систем вентиляції та кондиціонування в готельному комплексі Ramada
            Encore Kiev.
            Висловлюю впевненість у продовженні нашої подальшої взаємовигідної співпраці.

            Головний інженер підприємства "Нітеко"
            <br>
            <br>
            <br>
            <br>
            <br>
            <br>
            <br>
            <br>
            <br>
            <br>
            <br>
            <br>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>

<app-our-advantages></app-our-advantages>
<app-our-clients-small></app-our-clients-small>
<app-feedback></app-feedback>
<app-map></app-map>
