<section class="block block-gar">
  <div class="container">
    <div class="title title-zag-block">НАШІ ПЕРЕВАГИ</div>
    <div class="row">
      <div class="col-12 col-lg-9">
        <div class="ul-block-gar">
          <div class="row">
            <div class="col-12 col-lg-4 text-center advantages-item">
              <div class="img-block-gar">
                <img src="../../../assets/images/ic4.png" alt="" class="img-fluid">
                <span>01</span>
              </div>
              <div class="name-block-gar">Ми – компанія повного циклу із власною виробничою базою.</div>
            </div>
            <div class="col-12 col-lg-4 text-center advantages-item">
              <div class="img-block-gar">
                <img src="../../../assets/images/ic5.png" alt="" class="img-fluid">
                <span>02</span>
              </div>
              <div class="name-block-gar">Гарантуємо якість на кожному етапі співробітництва – від виробництва
                інженерних систем до післягарантійного обслуговування.
              </div>
            </div>
            <div class="col-12 col-lg-4 text-center advantages-item">
              <div class="img-block-gar">
                <img src="../../../assets/images/ic6.png" alt="" class="img-fluid">
                <span>03</span>
              </div>
              <div class="name-block-gar">Виконуємо всі роботи згідно з діючими державними будівельними нормами.
                Є дозволи та ліцензії на всі види монтажних та пусконалагоджувальних робіт.
              </div>
            </div>
            <div class="col-12 col-lg-4 text-center advantages-item">
              <div class="img-block-gar">
                <img src="../../../assets/images/ic7.png" alt="" class="img-fluid">
                <span>04</span>
              </div>
              <div class="name-block-gar">Пропонуємо комплексні рішення з урахуванням індивідуальних потреб кожного
                замовника.
              </div>
            </div>
            <div class="col-12 col-lg-4 text-center advantages-item">
              <div class="img-block-gar">
                <img src="../../../assets/images/ic8.png" alt="" class="img-fluid">
                <span>05</span>
              </div>
              <div class="name-block-gar">Використовуємо сучасне енергозберігаюче обладнання та якісні
                матеріали.
              </div>
            </div>
            <div class="col-12 col-lg-4 text-center advantages-item">
              <div class="img-block-gar">
                <img src="../../../assets/images/ic9.png" alt="" class="img-fluid">
                <span>06</span>
              </div>
              <div class="name-block-gar">Забезпечуємо оптимальне співвідношення вартості та експлуатаційних витрат
                завдяки використанню сучасних технологій та матеріалів.
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-12 col-lg-3 pl-lg-0">
        <div class="form-block-gar">
          <div class="form-block-gar-name text-center">Зворотній зв'язок</div>
          <form [formGroup]="callForm">
            <div class="row">
              <div class="col">
                <div class="form-group">
                  <input type="text" formControlName="name" id="name" class="form-control" placeholder="Ім'я*"
                         [ngClass]="isControlInvalid('name') ? 'input-error':''">
                </div>
                <div class="form-group">
                  <input type="text" formControlName="phone" id="phone" class="form-control" placeholder="Телефон*"
                         [ngClass]="isControlInvalid('phone') ? 'input-error':''">
                </div>
                <div class="form-group">
                  <input type="text" formControlName="email" id="email" class="form-control" placeholder="E-mail*"
                         [ngClass]="isControlInvalid('email') ? 'input-error':''">
                </div>
                <div class="form-group">
                  <textarea class="form-control" formControlName="message" id="message" placeholder="Повідомлення ..."
                            rows="5"></textarea>
                </div>
                <div class="text-center">
                  <button class="my-btn btn-zd-vop" (click)="send()">Задати питання</button>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</section>
