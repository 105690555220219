import {Component, OnInit} from '@angular/core';
import {Meta, Title} from '@angular/platform-browser';

@Component({
  selector: 'app-teplosnabzhenie',
  templateUrl: './teplosnabzhenie.component.html',
  styleUrls: ['./teplosnabzhenie.component.css']
})
export class TeplosnabzhenieComponent implements OnInit {

  constructor(private title: Title,
              private meta: Meta) {
  }

  ngOnInit(): void {
    this.title.setTitle('Теплопостачання для комерційних та промислових приміщень під ключ - Комплекс Інжиніринг Системз | Інжинірингові послуги у Києві');
    this.meta.updateTag({
      name: 'description',
      content: 'Теплопостачання для комерційних та промислових приміщень під ключ від інжинірингової компанії Комплекс Інжиніринг Системз в Києві ⭐ Проектування, монтаж, комплектація та постачання обладнання для систем теплопостачання'
    });
    this.meta.updateTag({
      meta: 'keywords',
      content: 'системы теплоснабжения,системы теплоснабжения киев,інжинірингова компанія,інжинірингові послуги,інжинірингова компанія Київ,промисловий інжиніринг'
    });
    window.scroll(0, 0);
  }
}
