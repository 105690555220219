<section class="bl-form-gl">
  <div class="container">
    <div class="bl-form-gl-title">Зворотній зв'язок</div>
    <div class="row">
      <div class="col-lg-12">
        <form [formGroup]="callForm" id="callForm">
            <div class="row" style="justify-content: center">
              <div class="col-lg-4">
                <div class="form-group">
                  <input type="text" formControlName="name" id="name" class="form-control" placeholder="Ім'я*"
                         [ngClass]="isControlInvalid('name') ? 'input-error':''">
                </div>
                <div class="form-group">
                  <input type="text" formControlName="phone" id="phone" class="form-control" placeholder="Телефон*"
                         [ngClass]="isControlInvalid('phone') ? 'input-error':''">
                </div>
                <div class="form-group">
                  <input type="text" formControlName="email" id="email" class="form-control" placeholder="E-mail*"
                         [ngClass]="isControlInvalid('email') ? 'input-error':''">
                </div>
              </div>
              <div class="col-lg-4">
                <div class="form-group">
                <textarea class="form-control" formControlName="message" placeholder="Повідомлення ..."
                          style="min-height: 190px"></textarea>
                </div>
              </div>
            </div>
            <div class="row" style="justify-content: center">
              <button class="my-btn btn-zd-vop" (click)="send()">Задати питання</button>
            </div>
        </form>
      </div>
    </div>
  </div>
</section>
