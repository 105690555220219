import {Component, OnInit} from '@angular/core';
import Shuffle from 'shufflejs';
import {Meta, Title} from '@angular/platform-browser';

@Component({
  selector: 'app-portfolio',
  templateUrl: './portfolio.component.html',
  styleUrls: ['./portfolio.component.css']
})
export class PortfolioComponent implements OnInit {

  shuffle: any;

  constructor(private title: Title,
              private meta: Meta) { }

  ngOnInit(): void {
    this.title.setTitle('Наші проекти - Комплекс Інжиніринг Системз | Інжинірингові послуги у Києві');
    this.meta.updateTag({name: 'description', content: 'Виконані проекти компанії Комплекс Інжиніринг Системз в Києві ⭐ Проектування, монтаж, комплектація та постачання обладнання для систем опалення'});
    this.meta.updateTag({meta: "keywords", content:"выполненные проекты,інжинірингова компанія,інжинірингові послуги,інжинірингова компанія Київ,промисловий інжиніринг"})
    this.shuffle = new Shuffle(document.getElementById('gridPortfolio'), {
      itemSelector: '.picture-item',
      sizer: '.my-sizer-element'
    });
    window.scroll(0, 0);
  }

  filter(v: string) {
    this.shuffle.filter(v);
  }
}
