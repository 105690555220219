<section class="bread-block">
  <div class="container">
    <nav aria-label="breadcrumb">
      <ol class="breadcrumb">
        <li class="breadcrumb-item"><a [routerLink]="['']">Головна</a></li>
        <li class="breadcrumb-item">Вакансії</li>
      </ol>
    </nav>
  </div>
</section>
<section class="block-uslugi my-2 my-lg-4">
  <div class="container">
    <div class="title title-zag-block">Вакансії</div>
    <div class="title-vacansies">Інженер ПТО</div>
    <p>У дружню команду професіоналів Компанії "Комплекс Інжиніринг Системс" потрібен Інженер ПТО</p>
    <p><b>Вимоги:</b></p>
    <ul>
      <li>напрямок вентиляція, кондиціювання, опалення, водопровід, каналізація;</li>
      <li>освіта вища (теплогазопостачання та вентиляція (ТГВ) або водопостачання та каналізація (ВК));</li>
      <li>стаж роботи інженером ПТО від 2-х років;</li>
      <li>знання повного комплексу будівельно-монтажних робіт, технологій будівельного виробництва,
        послідовності операцій, документообігу у будівництві;
      </li>
      <li>знання законодавства України (будівельних норм ДБН (ДСТУ);</li>
      <li>впевнене володіння ПК, Автокад</li>
    </ul>
    <p><b>Обов'язки:</b></p>
    <ul>
      <li>контроль за ходом виконання робіт, за забезпеченням виконання технічної документації;</li>
      <li>контроль проектної документації;</li>
      <li>контроль та перевірка відсотків;</li>
      <li>контроль списання матеріалів;</li>
      <li>прийом виконавчої документації та її облік;</li>
      <li>розробка технологічних карт</li>
    </ul>
    <p><b>Умови роботи:</b></p>
    <ul>
      <li>графік роботи з 9.00 до 18.00, сб, нд - вихідний;</li>
      <li>офіційне працевлаштування;</li>
      <li>своєчасна виплата зп;</li>
      <li>комфортні умови роботи у дружньому колективі.</li>
    </ul>
    <p>Офіс розташований у мальовничому центрі міста, в пішій доступності від м. Льва Толстого або м. Університет</p>
    <p><b>Якщо Ви готові стати частиною нашої Команди, чекаємо на Ваші резюме з фото та побажаннями щодо заробітної
      плати!</b></p>

    <div class="vacansies-separator"></div>

    <div class="title-vacansies">Інженер-проектувальник систем вентиляції, опалення та кондиціювання</div>
    <p>У дружню команду професіоналів Компанії «Комплекс Інжиніринг Системс» потрібен Інженер-проектувальник систем
      вентиляції, опалення та кондиціювання</p>
    <p><b>Вимоги:</b></p>
    <ul>
      <li>профільна освіта;</li>
      <li>досвід роботи - від 5-ти років;</li>
      <li>уміння читати креслення;</li>
      <li>навички роботи в програмі autocad, magicad, revit;</li>
      <li>навички технічного нагляду за проектуванням, монтажем, реконструкцією та модернізацією обладнання та
        систем;
      </li>
      <li>знання нормативних документів з експлуатації обладнання та систем;</li>
      <li>знання обладнання та систем холодопостачання, вентиляції, кондиціювання, тепломеханіки (припливно-витяжні)
        установки, системи підпору повітря та димовидалення, спліт-системи, холодильні машини, насосні групи,
        тепломеханічне обв'язування і тп);
      </li>
      <li>знання ринку та виробників сучасного обладнання</li>
    </ul>
    <p><b>Обов'язки:</b></p>
    <ul>
      <li>проектування та розробка креслень в «автокад»;</li>
      <li>підбір обладнання та матеріалів у постачальників, комплектація;</li>
      <li>написання тз, картки технічних рішень, заповнення опитувальних листів;</li>
      <li>розробка проектної документації систем опалення, холодопостачання, вентиляції, кондиціювання стадії п,
        рп,
        р.;
      </li>
      <li>узгодження документації;</li>
      <li>видача завдань суміжникам;</li>
      <li>проходження експертизи;</li>
      <li>розробка та узгодження концепцій проекту, проектування, участь у переговорах, підбір
        енерго-економічного
        рішення систем вік;
      </li>
      <li>узгодження всіх технічних питань з клієнтом;</li>
      <li>авторський нагляд за виконанням проекту.</li>
    </ul>
    <p><b>Умови роботи:</b></p>
    <ul>
      <li>графік роботи з 9.00 до 18.00, сб, нд - вихідний;</li>
      <li>офіційне працевлаштування;</li>
      <li>своєчасна виплата зп;</li>
      <li>комфортні умови роботи у дружньому колективі.</li>
    </ul>
    <p>Офіс розташований у мальовничому центрі міста, в пішій доступності від м. Льва Толстого або м. Університет</p>
    <p><b>Якщо Ви готові стати частиною нашої Команди, чекаємо на Ваші резюме з фото та побажаннями щодо заробітної
      плати!</b></p>

    <div class="vacansies-separator"></div>

    <div class="title-vacansies">Менеджер проектів систем ОВіК</div>
    <p>У дружню команду професіоналів Компанії "Комплекс Інжиніринг Системc" потрібен Менеджер проектів систем
      опалення, вентиляції та кондиціювання</p>
    <p><b>Вимоги:</b></p>
    <ul>
      <li>досвід роботи від 2-х років;</li>
      <li>знання систем вік;</li>
      <li>здатність до швидкого навчання;</li>
      <li>знання ринку комерційної нерухомості буде перевагою;</li>
      <li>інженерна освіта буде перевагою;</li>
      <li>уміння вести переговори;</li>
      <li>комунікабельність, наполегливість, активність.</li>
    </ul>
    <p><b>Обов'язки:</b></p>
    <ul>
      <li>ведення існуючих замовників;</li>
      <li>складання комерційних пропозицій;</li>
      <li>взаємодія із замовником та субпідрядними організаціями на всіх етапах ведення проекту;</li>
      <li>постійна взаємодія та співпраця з постачальниками кліматичного обладнання матеріалів;</li>
      <li>складання специфікацій обладнання та матеріалів;</li>
      <li>узгодження всіх технічних питань з клієнтом;</li>
      <li>повний супровід проекту;</li>
      <li>повне документальне закриття об'єкта.</li>
    </ul>
    <p><b>Умови роботи:</b></p>
    <ul>
      <li>графік роботи з 9.00 до 18.00, сб, нд - вихідний;</li>
      <li>офіційне працевлаштування;</li>
      <li>своєчасна виплата зп;</li>
      <li>комфортні умови роботи у дружньому колективі.</li>
    </ul>
    <p>Офіс розташований у мальовничому центрі міста, в пішій доступності від м. Льва Толстого або м. Університет</p>
    <p><b>Якщо Ви готові стати частиною нашої Команди, чекаємо на Ваші резюме з фото та побажаннями щодо заробітної
      плати!</b></p>

    <div class="vacansies-separator"></div>

    <div class="title-vacansies">Головний інженер проектів напряму ОВіК</div>
    <p>У дружню команду професіоналів Компанії «Комплекс Інжиніринг Системс» потрібен Головний інженер проектів
      напрямки ОВіК</p>
    <p><b>Вимоги:</b></p>
    <ul>
      <li>профільна освіта;</li>
      <li>досвід роботи на аналогічній посаді - від 5-ти років;</li>
      <li>успішний досвід інсталяції інженерних систем:вентиляції, кондиціювання, холодопостачання, водопроводу та
        водовідведення, каналізації, опалення;
      </li>
      <li>відповідальність та системність у роботі;</li>
      <li>наявність власного автомобіля;</li>
      <li>власного автомобіля;
        високі комунікативні навички, старанність, відповідальність, уважність.
      </li>
    </ul>
    <p><b>Обов'язки:</b></p>
    <ul>
    <li>оцінка вартості проектування, формування договірної вартості;</li>
      <li>складання технічного завдання на проектування;</li>
      <li>формування бюджетів, графіків випуску проектної та робочої документації;</li>
      <li>узгодження із замовником основних технічних рішень;</li>
      <li>захист проектних рішень в експертизі;</li>
      <li>здійснення контролю за прийнятими технічними рішеннями;</li>
      <li>контроль за дотриманням вимог чинної нормативної документації;</li>
      <li>здійснення авторського нагляду (можливі відрядження).</li>
    </ul>
    <p><b>Умови роботи:</b></p>
    <ul>
    <li>графік роботи з 9.00 до 18.00, сб, нд - вихідний;</li>
      <li>офіційне працевлаштування;</li>
      <li>своєчасна виплата зп;</li>
      <li>гідну оплату праці (розмір заробітної плати обговорюється на співбесіді та залежить від профільної
        досвіду).
      </li>
    </ul>
    <p>Офіс розташований у мальовничому центрі міста, в пішій доступності від м. Льва Толстого або м. Університет</p>
    <p><b>Якщо Ви готові стати частиною нашої Команди, чекаємо на Ваші резюме з фото та побажаннями щодо заробітної плати!</b></p>
  </div>
</section>
<app-feedback></app-feedback>
<app-map></app-map>


