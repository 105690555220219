<section class="bread-block">
  <div class="container">
    <nav aria-label="breadcrumb">
      <ol class="breadcrumb">
        <li class="breadcrumb-item"><a [routerLink]="['']">Головна</a></li>
        <li class="breadcrumb-item"><a [routerLink]="['/kondiczionirovanie']">Кондиціювання</a></li>
        <li class="breadcrumb-item">Кондиціювання заводу</li>
      </ol>
    </nav>
  </div>
</section>
<section class="block-uslugi my-2 my-lg-4">
  <div class="container">
    <div class="title title-zag-block">Кондиціювання заводу</div>
    <p class="ql-align-justify">Ефективні пристрої промислового охолодження та кондиціювання призначені для
      приміщень із великою кубатурою, де неможливо встановити будь-який інший тип кондиціювання. Професійні
      кондиціонери характеризуються високою охолоджувальною здатністю та міцним корпусом.</p>
    <p class="ql-align-justify">Під час роботи заводу або великого підприємства необхідно підтримувати температуру,
      якою буде нормально функціонувати обладнання. Крім того, потрібно забезпечити комфорт та безпеку
      співробітників.</p>
    <p class="ql-align-justify">Промислові кондиціонери мають підбиратися з урахуванням низки факторів:</p>
    <ul>
      <li class="ql-align-justify">Характер діяльності підприємства.</li>
      <li class="ql-align-justify">Вимоги до умов нормальної роботи промислової техніки.</li>
      <li class="ql-align-justify">Норми дотримуються для забезпечення комфортних умов для співробітників.</li>
      <li class="ql-align-justify">Кількість тепла, що виділяється машинами під час виробничого процесу.</li>
      <li class="ql-align-justify">Рівень вологості.</li>
      <li class="ql-align-justify">Вимоги до кількості домішок повітря.</li>
      <li class="ql-align-justify">Температура навколишнього повітря у цеху.</li>
    </ul>
    <h3 class="ql-align-justify">Великі та ефективні кондиціонери – застосування</h3>
    <p class="ql-align-justify">Через високу продуктивність цих пристроїв, високу охолоджувальну здатність і
      потоку повітря це обладнання відмінно підходить для холодильних складів, виробничих цехів, заводів та інших
      подібних приміщень. Вони дозволяють ефективно охолоджувати не лише повітря, а й обладнання, яке використовується
      для роботи у заданому місці. Промислові кондиціонери також використовуються для охолодження технологічних процесів
      та електроустаткування, кондиціювання повітря для залів.</p>
    <h3 class="ql-align-justify">Особливості проектування кліматичної системи для підприємства</h3>
    <p class="ql-align-justify">Розробка проекту системи кондиціювання промислових підприємств ведеться на стадії
      будівництва чи капітального ремонту будівлі. Як правило, підбирається потужне обладнання, яке об'єднується.
      із системою вентиляції. Цей підхід обумовлений тим, що кондиціонер не здатний впливати на склад повітря. Він
      забирає повітряні маси із приміщення, обробляє їх до потрібної температури та повертає назад. Приплив чистий,
      збагаченого повітря з вулиці забезпечується завдяки роботі у парі з вентиляцією.</p>
    <p class="ql-align-justify">Сьогодні існує величезний вибір промислових кліматичних установок, які мають
      найрізноманітніший набір функцій і відрізняються економічністю. Виробничий кондиціонер не відрізняється по
      будову від такого обладнання, що застосовується в інших сферах.</p>
    <p class="ql-align-justify">Система складається з двох блоків – зовнішнього (компресорно-конденсаторний) та внутрішнього
      (Випарник). Випарник забирає тепле повітря із приміщення, а компресорно-конденсаторний модуль здійснює
      подачу охолоджених повітряних мас. На виробництві внутрішні блоки монтуються безпосередньо над промисловими
      машинами, щоб забезпечити їхнє повноцінне охолодження.</p>
    <h3 class="ql-align-justify">Широкий асортимент промислових кондиціонерів</h3>
    <p class="ql-align-justify">В даний час промислові кондиціонери включають широкий спектр
      різних пристроїв, які можуть бути індивідуально адаптовані до конкретних вимог промислових процесів
      та установки, на якій вони будуть використовуватись.</p>
    <h3 class="ql-align-justify">Типи промислових кондиціонерів</h3>
    <p class="ql-align-justify"><strong>VRF-системы</strong></p>
    <p class="ql-align-justify">Системи зі змінною витратою холодоагенту (VRF, VRV і так далі) складаються з одного
      зовнішнього блоку (при необхідності збільшення загальної потужності можуть використовуватися комбінації зовнішніх блоків) та
      деякої кількості внутрішніх блоків. Особливість систем полягає в тому, що зовнішній блок змінює свою
      холодопродуктивність (потужність) залежно від потреб внутрішніх блоків даної потужності. Однак
      гранична продуктивність VRF-систем обмежена (обсяги приміщень, що охолоджуються, до декількох тисяч
      кубометр.</p>
    <p class="ql-align-justify"><strong>Система чилер-фанкойл</strong></p>
    <p class="ql-align-justify">Централізована, багатозональна система кондиціювання повітря, в якій
      теплоносієм між центральною охолоджувальною машиною (чилером) та локальними теплообмінниками (вузлами охолодження)
      повітря, фанкойлами) служить охолоджена рідина, що циркулює під відносно низьким тиском - звичайна
      вода (в тропічному кліматі) або водний розчин етиленгліколю (в помірному та холодному кліматі). Крім чиллера
      (чилерів) та фанкойлів, до складу системи входить трубна розводка між ними, насосна станція (гідромодуль) та
      підсистема автоматичного регулювання</p>
    <p class="ql-align-justify">У порівнянні з VRVVRF системами, в яких між холодильною машиною та локальними
      вузлами циркулює газовий холодоагент, системи чиллер-фанкойл мають відмінності:</p>
    <ul>
      <li class="ql-align-justify">Вдвічі більша максимальна відстань між чиллером та фанкойлами. Довжина трас
        може досягати сотень метрів, тому що при високій теплоємності рідкого теплоносія питомі втрати на погонний
        метр траси нижче, ніж у системах із газовим холодоагентом.
      </li>
      <li class="ql-align-justify">Вартість розведення. Для зв'язку чилерів та фанкойлів використовуються звичайні водяні
        труби, запірна арматура тощо. Балансування водяних труб, тобто вирівнювання тиску та швидкості потоку води
        між окремими фанкойлами, значно простіше і дешевше, ніж у газонаповнених системах.
      </li>
      <li class="ql-align-justify">Безпека. Потенційно леткі гази (газовий холодоагент) зосереджені в чилері,
        що встановлюється, як правило, на відкритому повітрі (на даху або безпосередньо на землі). Аварії трубної
        розведення всередині будівлі обмежені ризиком затоки, яка може бути зменшена автоматичною запірною арматурою.
      </li>
    </ul>
    <h3 class="ql-align-justify">Холодильні компресорні машини</h3>
    <p class="ql-align-justify">В компресорній холодильній машині робоче тіло протікає по контуру потоку,
      поперемінно поглинаючи тепло за низької температури і виділяючи (більше) тепло за вищої температури. Щоб
      підтримувати потік і, отже, процес, необхідне відкачування, тобто введення механічної роботи. Такі
      машини або працюють чергуючи випаровування і конденсацію середовища (холодоагенту), або завжди газоподібним середовищем (в
      здебільшого повітрям).</p>
    <p class="ql-align-justify">Перший тип широко поширений і використовується, наприклад, у побутових холодильниках,
      морозильники та морозильники, системи дозування, холодильні камери, кондиціонери, котки, бійні,
      пивоварних заводах та хімічної промисловості.</p>
  </div>
</section>
