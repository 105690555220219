import { Component, OnInit } from '@angular/core';
import {Meta, Title} from '@angular/platform-browser';

@Component({
  selector: 'app-otoplenie',
  templateUrl: './otoplenie.component.html',
  styleUrls: ['./otoplenie.component.css']
})
export class OtoplenieComponent implements OnInit {

  constructor(private title: Title,
              private meta: Meta) { }

  ngOnInit(): void {
    this.title.setTitle('Опалення для комерційних та промислових приміщень під ключ - Комплекс Інжиніринг Системз | Інжинірингові послуги у Києві');
    this.meta.updateTag({name: 'description', content: 'Опалення під ключ від інжинірингової компанії Комплекс Інжиніринг Системз в Києві ⭐ Проектування, монтаж, комплектація та постачання обладнання для систем опалення'});
    this.meta.updateTag({meta: "keywords", content:"системы отопления,інжинірингова компанія,інжинірингові послуги,інжинірингова компанія Київ,промисловий інжиніринг"})
    window.scroll(0, 0);
  }

}
