<ngx-spinner
  bdColor="rgba(51, 51, 51, 0.8)"
  size="medium"
  color="#fff"
  type="ball-scale-multiple"
></ngx-spinner>

<header>
  <div class="header-top">
    <div class="container">
      <div class="row">
        <div class="col-12 col-lg-4 d-flex justify-content-center">
          <div class="logo-img" style="padding: 0; margin: 0">
            <a [routerLink]="['']">
              <img src="../../../assets/images/logo.jpg" alt="" class="image-logo">
            </a>
          </div>
        </div>
        <div class="col-12 col-lg-6  d-flex justify-content-center">
          <div class="wr-contact d-flex justify-content-center">
            <div class="contact d-flex align-items-center">
              <div class="img-contact">
                <img src="../../../assets/images/loc.png" alt="">
              </div>
              <div class="text-contact">вул. Микільсько-Ботанічна 6/8<br/>Київ, Україна</div>
            </div>
            <div class="contact d-flex align-items-center">
              <div class="img-contact">
                <img src="../../../assets/images/phone.png" alt="">
              </div>
              <div class="contact-a">
                <a href="tel:+38 (044) 300-02-36">+38 (044) 300-02-36</a>
                <a href="tel:+38 (044) 300-02-36">+38 (093) 177-05-18</a>
              </div>
            </div>
          </div>
        </div>
        <div class="col-12 col-lg-2 p-lg-0 d-flex align-items-center justify-content-center">
          <app-callback></app-callback>
        </div>
      </div>
    </div>
  </div>
  <div class="gl-menu">
    <div class="container">
      <nav class="navbar navbar-expand-lg navbar-dark">
        <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent"
                aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
          <span class="navbar-toggler-icon"></span>
        </button>
        <!-- Логотип для свернутой версии меню справа  -->
        <div class="justify-content-end d-lg-none">
          <ul class="navbar-nav collapse-right">
            <li class="nav-item" style="margin-left: 0">
              <a href="#" target="_blank">
                <i class="fab fa-viber"></i>
              </a>
            </li>
            <li class="nav-item">
              <a href="#" target="_blank">
                <i class="fab fa-telegram"></i>
              </a>
            </li>
            <li class="nav-item">
              <a href="#" target="_blank">
                <i class="fab fa-instagram"></i>
              </a>
            </li>
            <li class="nav-item" style="margin-right: 0">
              <a href="#" target="_blank">
                <i class="fab fa-whatsapp"></i>
              </a>
            </li>
          </ul>
        </div>

        <div class="collapse navbar-collapse justify-content-between" id="navbarSupportedContent">
          <!-- левая секция меню   -->
          <ul class="navbar-nav collapse-left">
            <li class="nav-item" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">
              <a class="nav-link" [routerLink]="['']" data-toggle="collapse"
                 data-target=".navbar-collapse.show">ГОЛОВНА</a>
            </li>
            <li class="nav-item" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">
              <a class="nav-link" id="navbarDropdownPortfolio1" role="button" data-toggle="dropdown"
                 aria-haspopup="true" aria-expanded="false">НАШІ ПОСЛУГИ<i
                class="fas fa-caret-down custom-dropdown-icon"></i></a>
              <div class="dropdown-menu" aria-labelledby="navbarDropdownPortfolio">
                <a class="dropdown-item" [routerLink]="['/razrabotka-proektov-ovik']" data-toggle="collapse"
                   data-target=".navbar-collapse.show"><i
                  class="icon-razrabotka menu-icon"></i>РОЗРОБКА ПРОЕКТІВ ЗА РОЗДІЛАМИ ОВІК</a>
                <a class="dropdown-item" [routerLink]="['/analiz-i-optimizacziya-proektov-ovik']" data-toggle="collapse"
                   data-target=".navbar-collapse.show"><i
                  class="icon-analysis menu-icon"></i>АНАЛІЗ І ОПТИМІЗАЦІЯ ПРОЕКТІВ ЗА РОЗДІЛАМИ ОВІК</a>
                <a class="dropdown-item" [routerLink]="['/realizatsiya-pod-klyuch']"
                   data-toggle="collapse" data-target=".navbar-collapse.show"><i
                  class="icon-guarantee menu-icon"></i>РЕАЛІЗАЦІЯ ОБ'ЄКТУ «ПІД КЛЮЧ»</a>
                <a class="dropdown-item" [routerLink]="['/montazhnyye-pusko-naladochnyye-servisnyye-raboty']"
                   data-toggle="collapse" data-target=".navbar-collapse.show"><i
                  class="icon-configuration menu-icon"></i>МОНТАЖНІ, ПУСКО-НАЛАГОДЖУВАЛЬНІ ТА СЕРВІСНІ РОБОТИ
                </a>
                <a class="dropdown-item"
                   [routerLink]="['/komplektacziya-i-postavka-oborudovaniya']"
                   data-toggle="collapse" data-target=".navbar-collapse.show"><i
                  class="icon-postavka menu-icon"></i>КОМПЛЕКТАЦІЯ І ПОСТАВКА СУЧАСНОГО УСТАТКУВАННЯ</a>
                <a class="dropdown-item" [routerLink]="['/garantijnoe-obsluzhivanie']" data-toggle="collapse"
                   data-target=".navbar-collapse.show"><i
                  class="icon-mechanism menu-icon"></i>ГАРАНТІЙНЕ
                  ОБСЛУГОВУВАННЯ</a>
                <a class="dropdown-item" [routerLink]="['/poslegarantijnoe-obsluzhivanie']" data-toggle="collapse"
                   data-target=".navbar-collapse.show"><i
                  class="icon-warranty menu-icon"></i>ПІСЛЯГАРАНТІЙНЕ
                  ОБСЛУГОВУВАННЯ</a>
                <a class="dropdown-item"
                   [routerLink]="['/realizacziya-proektov-po-razlichnyim-urovnem-trebovanij']" data-toggle="collapse"
                   data-target=".navbar-collapse.show"><i
                  class="icon-building menu-icon"></i>РЕАЛІЗАЦІЯ
                  ПРОЕКТІВ ЗА РІЗНИМИ РІВНЯМИ ВИМОГ</a>
                <a class="dropdown-item" [routerLink]="['/uslugi']"><i class="icon-clipboard menu-icon"
                                                                       data-toggle="collapse"
                                                                       data-target=".navbar-collapse.show"></i>ВСІ
                  ПОСЛУГИ</a>
              </div>
            </li>
            <li class="nav-item" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">
              <a class="nav-link" id="navbarDropdownServices1" role="button" data-toggle="dropdown"
                 aria-haspopup="true" aria-expanded="false">СИСТЕМИ<i
                class="fas fa-caret-down custom-dropdown-icon"></i></a>
              <div class="dropdown-menu" aria-labelledby="navbarDropdownPortfolio">
                <a class="dropdown-item" [routerLink]="['/ventilyacziya']" data-toggle="collapse"
                   data-target=".navbar-collapse.show"><i class="icon-fan menu-icon"></i>ВЕНТИЛЯЦІЯ</a>
                <a class="dropdown-item" [routerLink]="['/kondiczionirovanie']" data-toggle="collapse"
                   data-target=".navbar-collapse.show"><i
                  class="icon-air-conditioner menu-icon"></i>КОНДИЦІЮВАННЯ</a>
                <a class="dropdown-item" [routerLink]="['/otoplenie']" data-toggle="collapse"
                   data-target=".navbar-collapse.show"><i
                  class="icon-heating menu-icon"></i>ОПАЛЕННЯ</a>
                <a class="dropdown-item" [routerLink]="['/teplosnabzhenie']" data-toggle="collapse"
                   data-target=".navbar-collapse.show"><i class="icon-pipes menu-icon"></i>ТЕПЛОПОСТАЧАННЯ</a>
                <a class="dropdown-item" [routerLink]="['/alternativnyy-istochniki-tepla']" data-toggle="collapse"
                   data-target=".navbar-collapse.show"><i
                  class="icon-cremation menu-icon"></i>АЛЬТЕРНАТИВНІ ДЖЕРЕЛА
                  ТЕПЛА</a>
                <a class="dropdown-item" [routerLink]="['/xolodosnabzhenie']" data-toggle="collapse"
                   data-target=".navbar-collapse.show"><i
                  class="icon-air-conditioner1 menu-icon"></i>ХОЛОДОПОСТАЧАННЯ</a>
                <a class="dropdown-item" [routerLink]="['/osushenie']" data-toggle="collapse"
                   data-target=".navbar-collapse.show"><i
                  class="icon-drain menu-icon"></i>ОСУШЕННЯ</a>
                <a class="dropdown-item" [routerLink]="['/vodosnabzheniye-kanalizatsiya']" data-toggle="collapse"
                   data-target=".navbar-collapse.show"><i
                  class="icon-tap menu-icon"></i>ВОДОПОСТАЧАННЯ І КАНАЛІЗАЦІЯ</a>
              </div>
            </li>
            <li class="nav-item" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">
              <a class="nav-link" [routerLink]="['/portfolio']" data-toggle="collapse"
                 data-target=".navbar-collapse.show">ПОРТФОЛІО</a>
            </li>
            <li class="nav-item" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">
              <a class="nav-link" [routerLink]="['/feedback']" data-toggle="collapse"
                 data-target=".navbar-collapse.show">ВІДГУКИ</a>
            </li>
            <li class="nav-item" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">
              <a class="nav-link" [routerLink]="['/vacancies']" data-toggle="collapse"
                 data-target=".navbar-collapse.show">ВАКАНСІЇ</a>
            </li>
            <li class="nav-item" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">
              <a class="nav-link" [routerLink]="['/news']" data-toggle="collapse" data-target=".navbar-collapse.show">НОВИНИ</a>
            </li>
            <li class="nav-item" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">
              <a class="nav-link" [routerLink]="['/kontaktyi']" data-toggle="collapse"
                 data-target=".navbar-collapse.show">КОНТАКТИ</a>
            </li>
          </ul>

          <!-- правая секция меню   -->
          <ul class="navbar-nav justify-content-end collapse-right nav-social">
            <li class="nav-item" style="margin-left: 0">
              <a href="#" target="_blank">
                <i class="fab fa-viber"></i>
              </a>
            </li>
            <li class="nav-item">
              <a href="#" target="_blank">
                <i class="fab fa-telegram"></i>
              </a>
            </li>
            <li class="nav-item">
              <a href="#" target="_blank">
                <i class="fab fa-instagram"></i>
              </a>
            </li>
            <li class="nav-item" style="margin-right: 0">
              <a href="#" target="_blank">
                <i class="fab fa-whatsapp"></i>
              </a>
            </li>
          </ul>
        </div>
      </nav>
    </div>
  </div>
</header>



