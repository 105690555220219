import {Component, OnInit} from '@angular/core';
import {Meta, Title} from '@angular/platform-browser';

@Component({
  selector: 'app-konditsionirovaniye-farm',
  templateUrl: './konditsionirovaniye-farm.component.html',
  styleUrls: ['./konditsionirovaniye-farm.component.css']
})
export class KonditsionirovaniyeFarmComponent implements OnInit {

  constructor(private title: Title,
              private meta: Meta) {
  }

  ngOnInit(): void {
    this.title.setTitle('Кондиціювання приміщень фармацевтичної промисловості під ключ - Комплекс Інжиніринг Системз');
    this.meta.updateTag({
      name: 'description',
      content: 'Кондиціювання приміщень фармацевтичної промисловості під ключ від інжинірингової компанії Комплекс Інжиніринг Системз в Києві ⭐ Проектування, монтаж, комплектація та постачання обладнання для систем вентиляції'
    });
    this.meta.updateTag({
      meta: 'keywords',
      content: 'кондиционирование,кондиционирование киев,кондиционирование под ключ,інжинірингова компанія,інжинірингові послуги,інжинірингова компанія Київ,промисловий інжиніринг'
    });
    window.scroll(0, 0);
  }
}
