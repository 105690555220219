<section class="slider">
  <div class="slider-owl owl-carousel owl-theme">
    <div class="slider-item">
      <img src="../../../assets/images/slider.jpg" alt="" class="img-fluid">
      <div class="slider-desc">
        <div class="container">
          <div class="slider-z">Комплекс інжиніринг систем</div>
          <div class="slider-p">Інжинірингова компанія повного "комплексу"</div>
        </div>
      </div>
    </div>
    <div class="slider-item">
      <img src="../../../assets/images/slider.jpg" alt="" class="img-fluid">
      <div class="slider-desc">
        <div class="container">
          <div class="slider-z">Комплекс інжиніринг систем</div>
          <div class="slider-p">Інжинірингова компанія повного "комплексу"</div>
        </div>
      </div>
    </div>
  </div>
</section>

<section class="block block-priim">
  <div class="container">
    <div class="title title-zag-block">КОМПЛЕКС ІНЖИНІРИНГ СИСТЕМЗ</div>
    <div class="row">
      <div class="col-12 col-sm-12 col-md-6 col-lg-4 card-info">
        <div class="col card-info-container">
          <div class="card-info-title">
            17 років
          </div>
          <div class="card-info-content">
           Ми встановлюємо системи вентиляції та кондиціювання
          </div>
        </div>
      </div>
      <div class="col-12 col-sm-12 col-md-6 col-lg-4 card-info">
        <div class="card-info-container col">
          <div class="card-info-title">
            45 мВт холоду<br>1.000.000 м3ч повітря
          </div>
          <div class="card-info-content">
            Ми змонтували обладнання із сумарною продуктивністю
          </div>
        </div>
      </div>
      <div class="col-12 col-sm-12 col-md-6 col-lg-4 card-info">
        <div class="card-info-container col">
          <div class="card-info-title">
            3.500.000 м2
          </div>
          <div class="card-info-content">
           Ми виконали інжинірингові роботи у приміщеннях загальною площею
          </div>
        </div>
      </div>
    </div>
  </div>
</section>

<section class="block block-priim">
  <div class="container">
    <div class="title title-zag-block">ПЕРЕВАГИ РОБОТИ З НАМИ</div>
    <div class="row">
      <div class="col-12 col-sm-6 col-md-4 col-lg-4">
        <div class="li-block-priim">
          <div class="icon-block-priim">
            <img src="../../../assets/images/presentation.png" alt="">
            <div class="romb-2">
              <span></span>
            </div>
          </div>
          <div class="name-block-priim"><b>Проект от А до Я</b><br>
           Контроль та ведення робіт із стадії проекту до повноцінного запуску об'єкта
          </div>
        </div>
      </div>
      <div class="col-12 col-sm-6 col-md-4 col-lg-4">
        <div class="li-block-priim">
          <div class="icon-block-priim">
            <img src="../../../assets/images/coin.png" alt="">
            <div class="romb-2">
              <span></span>
            </div>
          </div>
          <div class="name-block-priim"><b>Прозорі ціни</b><br>
           Працюємо в рамках кошторису на роботи та обладнання.
          </div>
        </div>
      </div>
      <div class="col-12 col-sm-6 col-md-4 col-lg-4">
        <div class="li-block-priim">
          <div class="icon-block-priim">
            <img src="../../../assets/images/calendar.png" alt="">
            <div class="romb-2">
              <span></span>
            </div>
          </div>
          <div class="name-block-priim"><b>Чітке дотримання термінів</b><br>
            Строки фіксуються договором та не змінюються.
          </div>
        </div>
      </div>
    </div>
  </div>
</section>

<app-uslugi></app-uslugi>

<section class="block block-about-gl">
  <div class="container text-justify">
    <div class="title title-zag-block">ПРО НАС</div>
    <p><strong>Інжинірингова компанія «Комплекс Інжиніринг Системз» ТОВ</strong> займається монтажем
      вентиляційні системи з 2003 року. За 17 років роботи у сфері кондиціювання та вентиляції ми на власному
      досвіді вивчили всі тонкощі проектування та реалізації об'єктів з нуля та під ключ. Ми надаємо <strong>інжинірингові
        послуги в
        Києві</strong>та найближчих містах з метою дарувати людям чисте повітря та здорову атмосферу для роботи та
      життєдіяльності. </p>
    <p>Наші замовники:</p>
    <ul>
      <li>торгово-розважальні центри;</li>
      <li>житлові багатоповерхові комплекси;</li>
      <li>ресторани, бари, кафе, їдальні;</li>
      <li>лікарні, приватні клініки та медичні центри;</li>
      <li>логістичні компанії;</li>
      <li>навчальні заклади;</li>
      <li>фабрики та заводи;</li>
      <li>звичайні люди, які бажають створити затишок у будинку.</li>
    </ul>
    <p style="border: 1px solid #dc9835; background-color: #fbf7f2; padding: 3px;">Компанія <strong>«Комплекс Інжиніринг
      Системз»</strong> - інжинірингова компанія повного циклу, що має власну виробничу базу.
      Наявність усіх необхідних дозволів та ліцензії на здійснення монтажних та пусконалагоджувальних робіт систем
      вентиляції, кондиціювання повітря, опалення, теплопостачання, каналізації та водопостачання гарантує
      законність та відповідність усім діючим державним будівельним нормам. Робота проектного відділу
      гарантує максимальне наближення у вирішенні технічних завдань із побажаннями та вимогами Замовника. Досвідчений
      і
      постійний штат кваліфікованих фахівців забезпечує високу якість виконання робіт у максимально
      короткий строк.</p>
    <p>Чому вигідніше працювати зі спеціалізованим підрядником, ніж довіряти складні інжинірингові процеси
      багатопрофільним співробітникам? Фахівці зможуть передбачити всі спірні моменти та запобігти проблемам на
      Початковий етап. <strong>Послуги інжинірингу від компанії Комплекс Інжиніринг Системз у Києві</strong> – це
      комплексне вирішення проблем, пов'язаних з розробкою проекту будівлі, плануванням вентиляційних систем
      приміщеннях та встановлення обладнання. </p>
    <p>
    <h4><strong>Як ми працюємо?</strong></h4>
    <p> Керівники, власники великих фірм, менеджери будівельних проектів користуються послугами «Комплекс
      Інжиніринг
      Системз» з метою оцінити масштаб робіт, спроектувати та встановити системи:</p>
    <ul>
      <li> вентиляції;</li>
      <li> кондиціювання;</li>
      <li> холодопостачання;</li>
      <li> теплопостачання.</li>
    </ul>
    <p> На початковому етапі співробітництва ми проводимо експертизу, вивчаємо умови будівництва та стан об'єкта.
      Після оцінки та аналізу ринку, готуємо найбільш оптимальну пропозицію з урахуванням можливостей клієнта та
      переваг
      проекту. </p>
    <p>Ми купуємо обладнання та витратні матеріали лише у сертифікованих постачальників зі світовим ім'ям. Наш
      підхід до реалізації інжинірингових систем відрізняється застосуванням інноваційних технологій та перевірених
      інструменти на кожному етапі. В результаті вентиляція та кондиціювання працюють як годинник і створюють комфортні
      умови у промислових будівлях, рестораціях та житлових будинках.</p>
    <p><strong>Проектування, монтаж, ремонт, діагностика та обслуговування ініжинірингових систем від професіоналів по
      доступним цінам</strong> позбавить вас багатьох потенційних витрат. Витрати на продуманий до кожної деталі
      проект допоможуть заощадити на численних доопрацюваннях та переробках. А якісна вентиляція збереже здоров'я
      співробітників, цілісність будівель, ремонту та обладнання. </p>
    <p><strong>Сервісна служба компанії</strong> пропонує послуги з технічного обслуговування та ремонту систем вентиляції,
      кондиціювання,
      опалення, водопостачання, яке включає повний комплект профілактичних, діагностичних і ремонтних
      робіт з усунення можливих неполадок та серйозних поломок.</p>
    <p>Завжди готові відповісти на усі Ваші запитання.</p>
    <p>Раді бачити Вас серед наших постійних клієнтів та друзів!</p>
  </div>
</section>
<section class="block block-btn-form">
  <div class="contaiber-fluid">
    <div class="text-center">
      <app-callback></app-callback>
    </div>
  </div>
</section>
<section class="block block-otzivi-gl">
  <div class="container">
    <div class="title title-zag-block">ВІДГУКИ ТА РЕКОМЕНДАЦІЇ</div>
    <div class="otzivi-owl owl-carousel owl-theme">
      <div class="otzivi-item">
        <div class="name-otziv">ТОВ "ТЕЛЕКАНАЛ СТБ"</div>
        <div class="city-otziv">м. Київ</div>
        <div class="desc-otziv"><img src="../../../assets/images/sert1.jpg" alt="" class="img-sert-otz">ТОВ «Телеканал
          СТБ» висловлює щиру подяку колективу компанії ТОВ «Комплекс
          Інжиніринг Системз» за надані послуги з обладнання наших приміщень кліматичними системами.
          Усі види робіт з монтажу та обслуговування систем кондиціювання повітря, опалення та вентиляції виконуються
          професійно, у стислий термін, відповідно до високих європейських стандартів та вимог до якості.
          Інженери компанії «Комплекс Інжиніринг Системз» успішно знаходять оптимальні рішення технічних питань,
          що виникають у процесі роботи.
          Рекомендуємо компанію ТОВ «Комплекс Інжиніринг Системз», як надійного партнера та компанію з сучасними
          прогресивними підходами до виконання робіт.
          <br>
          <br>
          <br>
        </div>
      </div>
      <div class="otzivi-item">
        <div class="name-otziv">ТОВ «ЛЮБАВА СІТІ»</div>
        <div class="city-otziv">м. Черкаси</div>
        <div class="desc-otziv"><img src="../../../assets/images/sert1.jpg" alt="" class="img-sert-otz">ТОВ «ЛЮБАВА
          СІТІ» висловлює подяку співробітникам ТОВ «КОМПЛЕКС ІНЖИНІРИНГ СИСТЕМЗ» за ефективне та якісне
          надання послуг у сфері поставки, монтажу та пусконалагоджувальних робіт систем вентиляції, кондиціювання та
          опалення.
          У процесі співробітництва ТОВ «КОМПЛЕКС ІНЖИНІРИНГ СИСТЕМЗ» виявила себе як надійний партнер та
          професійна, надійна команда, орієнтована на досягнення результату.
          Стиль роботи ТОВ «КОМПЛЕКС ІНЖИНІРИНГ СИСТЕМЗ» відрізняється креативним підходом до вирішення складних завдань та
          ретельним виконанням завдань у стислий термін.
          Враховуючи позитивний досвід співпраці, ми рекомендуємо ТОВ «КОМПЛЕКС ІНЖИНІРИНГ СИСТЕМЗ» як надійного
          партнера, який завжди ретельно виконує взяті на себе зобов'язання та гарантує високу якість своєї
          роботи.
        </div>
      </div>
      <div class="otzivi-item">
        <div class="name-otziv">ТОВ «Будхаус Групп»</div>
        <div class="city-otziv">м. Черкаси</div>
        <div class="desc-otziv"><img src="../../../assets/images/sert1.jpg" alt="" class="img-sert-otz">Організацією ТОВ
          «Комплекс Інжиніринг Системз» виконувались роботи з монтажу систем вентиляції та кондиціювання повітря в
          ТРК "Любава".
          Роботи були виконані відповідно до чинних будівельних норм та правил, згідно технічного
          завдання та умови договору, з належною якістю та у встановлений термін. При цьому під час виробництва
          робіт максимально враховувалися побажання Замовника, використовувалися сучасні будівельні матеріали та
          технології у виконанні робіт.
          За час проведення робіт організація ТОВ «Комплекс Інжиніринг Системз» виявила себе як
          високопрофесійна компанія з наявністю кваліфікованих кадрів, оперативно та якісно вирішальна
          поставлені завдання.
          <br>
          <br>
          <br>
          <br>
        </div>
      </div>
      <div class="otzivi-item">
        <div class="name-otziv">Компанія "Нітеко"</div>
        <div class="city-otziv">м. Київ</div>
        <div class="desc-otziv"><img src="../../../assets/images/sert1.jpg" alt="" class="img-sert-otz">Від імені
          директори товариства з обмеженою відповідальністю компанії «Нітеко» щиро вдячні за плідну
          роботу та допомогу у сфері обслуговування систем вентиляції та кондиціювання у готельному комплексі Ramada
          Encore Київ.
          Висловлюю впевненість у продовженні нашої подальшої взаємовигідної співпраці.

          Головний інженер компанії «Нітеко»
          <br>
          <br>
          <br>
          <br>
          <br>
          <br>
          <br>
          <br>
          <br>
          <br>
          <br>
          <br>
        </div>
      </div>
    </div>
    <div class="text-center" style="margin-top: 24px">
      <a [routerLink]="'/feedback'" class="my-btn">дивитись все</a>
    </div>
  </div>
</section>

<app-our-advantages></app-our-advantages>

<section class="block portfolio-block">
  <div class="container">
    <div class="title title-zag-block">Портфоліо</div>
    <div class="filters-group">
      <div class="filter-options btn-group mb-5 d-flex flex-wrap" role="group" id="filters-portfolio">
        <button type="button" class="btn-portfolio" (click)="filter('')">Все</button>
        <button type="button" class="btn-portfolio" (click)="filter('ventilation')">Вентиляція</button>
        <button type="button" class="btn-portfolio" (click)="filter('conditioning')">Кондиціювання</button>
        <button type="button" class="btn-portfolio" (click)="filter('heating')">Опалення</button>
        <button type="button" class="btn-portfolio" (click)="filter('alternativewarm')">Альтернативні джерела тепла
        </button>
        <button type="button" class="btn-portfolio" (click)="filter('heatsupply')">Теплопостачання</button>
        <button type="button" class="btn-portfolio" (click)="filter('coldsupply')">Холодопостачання</button>
        <button type="button" class="btn-portfolio" (click)="filter('watersupply')">Водопостачання і каналізація
        </button>
        <button type="button" class="btn-portfolio" (click)="filter('drainage')">Осушення</button>
      </div>
    </div>
    <div class="container">
      <div id="grid" class="row my-shuffle-container">
        <figure class="col-12 col-sm-6 col-md-4 col-lg-4 element-item mb-4 picture-item"
                data-groups='["conditioning","ventilation]'>
          <div class="element-item-rel">
            <img src="../../../assets/images/cosmos-golf-ukraine/cosmos_golf_ukraine_21.jpg" class="w-100 h-100"
                 alt="Cosmos Golf Ukraine">
            <div class="hover-element-item">
              <div class="block-element-item">
                <a [routerLink]="'/cosmos-golf-ukraine'">
                  <div class="name-element-item">Cosmos Golf Ukraine</div>
                  <div class="icon-element-item">
                    <i class="fas fa-angle-right"></i>
                  </div>
                </a>
              </div>
            </div>
          </div>
        </figure>
        <figure class="col-12 col-sm-6 col-md-4 col-lg-4 element-item mb-4 picture-item"
                data-groups='["ventilation","heatsupply","coldsupply","conditioning"]'>
          <div class="element-item-rel">
            <img src="../../../assets/images/retrovil/retrovil_1.jpg" class="w-100 h-100" alt="ТРЦ Ретровиль">
            <div class="hover-element-item">
              <div class="block-element-item">
                <a [routerLink]="'/retrovil'">
                  <div class="name-element-item">ТРЦ Ретровиль</div>
                  <div class="icon-element-item">
                    <i class="fas fa-angle-right"></i>
                  </div>
                </a>
              </div>
            </div>
          </div>
        </figure>
        <figure class="col-12 col-sm-6 col-md-4 col-lg-4 element-item mb-4 picture-item"
                data-groups='["ventilation","conditioning"]'>
          <div class="element-item-rel">
            <img src="../../../assets/images/fabrica/fabrica_1.jpg" class="w-100 h-100"
                 alt="Торгово-развлекательный центр «Фабрика»">
            <div class="hover-element-item">
              <div class="block-element-item">
                <a [routerLink]="'/fabryka'">
                  <div class="name-element-item">Торгово-розважальний центр "Фабрика"</div>
                  <div class="icon-element-item">
                    <i class="fas fa-angle-right"></i>
                  </div>
                </a>
              </div>
            </div>
          </div>
        </figure>
        <figure class="col-12 col-sm-6 col-md-4 col-lg-4 element-item mb-4 picture-item"
                data-groups='["conditioning","ventilation","heating"]'>
          <div class="element-item-rel">
            <img src="../../../assets/images/lyubava/lyubava_3.jpg" class="w-100 h-100"
                 alt="Торгово-развлекательный центр «Любава»">
            <div class="hover-element-item">
              <div class="block-element-item">
                <a [routerLink]="'/lyubava'">
                  <div class="name-element-item">Торгово-розважальний центр «Любава»</div>
                  <div class="icon-element-item">
                    <i class="fas fa-angle-right"></i>
                  </div>
                </a>
              </div>
            </div>
          </div>
        </figure>
        <figure class="col-12 col-sm-6 col-md-4 col-lg-4 element-item mb-4 picture-item"
                data-groups='["conditioning","ventilation","heating","heatsupply"]'>
          <div class="element-item-rel">
            <img src="../../../assets/images/depot/depot_1.jpg" class="w-100 h-100"
                 alt="Торгово-развлекательный центр «DEPOT»">
            <div class="hover-element-item">
              <div class="block-element-item">
                <a [routerLink]="'/depot'">
                  <div class="name-element-item">Торгово-розважальний центр «DEPOT»</div>
                  <div class="icon-element-item">
                    <i class="fas fa-angle-right"></i>
                  </div>
                </a>
              </div>
            </div>
          </div>
        </figure>
        <figure class="col-12 col-sm-6 col-md-4 col-lg-4 element-item mb-4 picture-item"
                data-groups='["ventilation","conditioning"]'>
          <div class="element-item-rel">
            <img src="../../../assets/images/luxary-residenses/luxary_1.jpg" class="w-100 h-100"
                 alt="ЖК LINDEN LUXURY RESIDENCES">
            <div class="hover-element-item">
              <div class="block-element-item">
                <a [routerLink]="'/linden-luxury-residences'">
                  <div class="name-element-item">ЖК LINDEN LUXURY RESIDENCES</div>
                  <div class="icon-element-item">
                    <i class="fas fa-angle-right"></i>
                  </div>
                </a>
              </div>
            </div>
          </div>
        </figure>
        <figure class="col-12 col-sm-6 col-md-4 col-lg-4 element-item mb-4 picture-item"
                data-groups='["coldsupply","conditioning","coldsupply"]'>
          <div class="element-item-rel">
            <img src="../../../assets/images/atmosfera/atmosfera_1.jpg" class="w-100 h-100"
                 alt="Торгово-развлекательный центр «Атмосфера»">
            <div class="hover-element-item">
              <div class="block-element-item">
                <a [routerLink]="'/atmosfera'">
                  <div class="name-element-item">Торгово-розважальний центр "Атмосфера"</div>
                  <div class="icon-element-item">
                    <i class="fas fa-angle-right"></i>
                  </div>
                </a>
              </div>
            </div>
          </div>
        </figure>
        <figure class="col-12 col-sm-6 col-md-4 col-lg-4 element-item mb-4 picture-item"
                data-groups='["ventilation","conditioning"]'>
          <div class="element-item-rel">
            <img src="../../../assets/images/osvitoriya/osvitoriya_1.jpeg" alt="Освитория ХАБ"
                 class="w-100 h-100">
            <div class="hover-element-item">
              <div class="block-element-item">
                <a [routerLink]="'/osvitoriya-khab'">
                  <div class="name-element-item">Освіторія ХАБ</div>
                  <div class="icon-element-item">
                    <i class="fas fa-angle-right"></i>
                  </div>
                </a>
              </div>
            </div>
          </div>
        </figure>
        <figure class="col-12 col-sm-6 col-md-4 col-lg-4 element-item mb-4 picture-item"
                data-groups='["ventilation","heating","coldsupply","heatsupply"]'>
          <div class="element-item-rel">
            <img src="../../../assets/images/unit/unit_1.jpg" class="w-100 h-100"
                 alt="Юнит сити">
            <div class="hover-element-item">
              <div class="block-element-item">
                <a [routerLink]="'/unit-city'">
                  <div class="name-element-item">Юніт сіті</div>
                  <div class="icon-element-item">
                    <i class="fas fa-angle-right"></i>
                  </div>
                </a>
              </div>
            </div>
          </div>
        </figure>
      </div>
    </div>
    <div class="text-center">
      <a [routerLink]="'/portfolio'" class="my-btn">дивитись все</a>
    </div>
  </div>
</section>
<app-our-clients-small></app-our-clients-small>
<app-feedback></app-feedback>
<app-map></app-map>
