<section class="bread-block">
  <div class="container">
    <nav aria-label="breadcrumb">
      <ol class="breadcrumb">
        <li class="breadcrumb-item"><a [routerLink]="['']">Головна</a></li>
        <li class="breadcrumb-item"><a [routerLink]="['/portfolio']">Портфоліо</a></li>
        <li class="breadcrumb-item">Продуктовий супермаркет «Сільпо»</li>
      </ol>
    </nav>
  </div>
</section>

<section class="content-page my-2 my-lg-4">
  <div class="container">
    <div class="title title-zag-block">ПРОДУКТОВИЙ СУПЕРМАРКЕТ «СІЛЬПО»</div>
  </div>
</section>

<section class="slider">
  <div class="container">
    <div class="portfolio-owl owl-carousel owl-theme">
      <div class="slider-item">
        <img src="../../../../assets/images/pp10.jpg" alt="Продуктовый супермаркет «Сильпо»" class="img-fluid">
      </div>
    </div>
  </div>
</section>
<section class="content-page my-2 my-lg-4">
  <div class="container">
    <p>Продуктовий супермаркет «Сільпо» 2 тис м2 (м. Київ, вул. Єленівська, 3).</p>
  </div>
</section>
<section class="content-page my-2 my-lg-4">
  <div class="container">
    <div class="title title-zag-block">Проведені роботи</div>
    <ol>
      <li>Монтаж системи кондиціювання на базі обладнання Midea (Китай) та LG (Корея) 175 кВт</li>
      <li>Монтаж системы вентиляции на базе оборудования Aerostar</li>
      <li>Монтаж комбінованої системи опалення</li>
    </ol>
  </div>
</section>

<app-our-advantages></app-our-advantages>
<app-our-clients-small></app-our-clients-small>
<app-feedback></app-feedback>
<app-map></app-map>
