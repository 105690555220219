import { Component, OnInit } from '@angular/core';
import {Meta, Title} from '@angular/platform-browser';

@Component({
  selector: 'app-alternativnyy-istochniki-tepla',
  templateUrl: './alternativnyy-istochniki-tepla.component.html',
  styleUrls: ['./alternativnyy-istochniki-tepla.component.css']
})
export class AlternativnyyIstochnikiTeplaComponent implements OnInit {

  constructor(private title: Title,
              private meta: Meta) {
  }

  ngOnInit(): void {
    this.title.setTitle('Альтернативні джерела тепла для комерційних та промислових приміщень під ключ - Комплекс Інжиніринг Системз | Інжинірингові послуги у Києві');
    this.meta.updateTag({name: 'description', content: 'Альтернативні джерела тепла під ключ від інжинірингової компанії Комплекс Інжиніринг Системз в Києві ⭐ Проектування, монтаж, комплектація та постачання обладнання для систем альтернативних джерел тепла'});
    this.meta.updateTag({meta: "keywords", content:"альтернативні джерела тепла, альтернативні джерела тепла київ, інжинірингова компанія, інжинірингові послуги, інжинірингова компанія київ, промисловий інжиніринг"})
    window.scroll(0, 0);
  }
}
