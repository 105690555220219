import {Component, OnInit} from '@angular/core';
import {Meta, Title} from '@angular/platform-browser';

declare let $: any;
import Shuffle from 'shufflejs';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {

  shuffle: any;

  constructor(private title: Title,
              private meta: Meta) {
  }

  ngOnInit(): void {
    this.title.setTitle('Інжинірингова компанія - Комплекс Інжиніринг Системз | Інжинірингові послуги у Києві');
    this.meta.updateTag({name: 'description', content: 'Послуги інжинірингу від компанії Комплекс Інжиніринг Системз ⭐ Проектування, монтаж, ремонт, діагностика та обслуговування ініжинірингових систем від професіоналів за доступними цінами'});
    this.meta.updateTag({meta: "keywords", content:"інжинірингова компанія,інжинірингові послуги,інжинірингова компанія Київ,промисловий інжиніринг"})
    $(document).ready(function() {
      $('.slider-owl').owlCarousel({
        items: 1,
        loop: true,
        margin: 10,
        nav: true,
        dots: false
      });
      $('.otzivi-owl').owlCarousel({
        loop: false,
        margin: 30,
        nav: true,
        dots: false,
        responsive: {
          0: {
            items: 1
          },
          600: {
            items: 2
          },
          1000: {
            items: 3
          }
        }
      });
    });

    this.shuffle = new Shuffle(document.getElementById('grid'), {
      itemSelector: '.picture-item',
      sizer: '.my-sizer-element'
    });

    window.scroll(0, 0);
  }

  filter(v: string) {
    this.shuffle.filter(v);
  }
}
