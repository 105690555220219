import {Component, OnInit} from '@angular/core';
import {Meta, Title} from '@angular/platform-browser';

@Component({
  selector: 'app-razrabotka-texnicheskix-proektov',
  templateUrl: './razrabotka-proektov-ovik.component.html',
  styleUrls: ['./razrabotka-proektov-ovik.component.css']
})
export class RazrabotkaProektovOvikComponent implements OnInit {

  constructor(private title: Title,
              private meta: Meta) {
  }

  ngOnInit(): void {
    this.title.setTitle('Розробка проектів по розділам ОВіК - Комплекс Інжиніринг Системз | Інжинірингові послуги у Києві');
    this.meta.updateTag({
      name: 'description',
      content: 'Розробка проектів по розділам ОВіК від інжинірингової компанії Комплекс Інжиніринг Системз в Києві ⭐ Проектування, монтаж, комплектація та постачання обладнання для систем опалення'
    });
    this.meta.updateTag({
      meta: 'keywords',
      content: 'разработка проектов по разделам овик,разработка проектов по разделам овик киев,інжинірингова компанія,інжинірингові послуги,інжинірингова компанія Київ,промисловий інжиніринг'
    });
    window.scroll(0, 0);
  }
}
