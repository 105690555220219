<section class="bread-block">
  <div class="container">
    <nav aria-label="breadcrumb">
      <ol class="breadcrumb">
        <li class="breadcrumb-item"><a [routerLink]="['']">Головна</a></li>
        <li class="breadcrumb-item">Новини</li>
      </ol>
    </nav>
  </div>
</section>
<section class="content-page my-2 my-lg-4">
  <div class="container">
    <div class="title title-zag-block">Новини</div>
    <div class="row">
      <div class="col-12 col-sm-12 col-md-6 col-lg-4 d-flex align-items-stretch news-item"
           [routerLink]="['/news-trc-retroville-photo']">
        <div class="card bg-light">
          <div class="card-image-container">
            <img class="card-image" src="../../../assets/images/news/retroville/retroville-1.jpg"
                 alt="Адміністративний готельно - офісний центр Домосфера">
          </div>
          <div class="card-header border-bottom-0">
            <h5 class="active-title">БЦ та ТРЦ Retroville відкрився</h5>
          </div>
          <div class="card-body border-bottom-0">
            <div class="row">
              <div class="text-justify">
                <p>До Дня Києва, незважаючи на обмеження, пов'язані з пандемією коронавірусу та погану погоду, відкрився
                  найбільший торгово-розважальний центр столиці "Retroville"...</p>
              </div>
            </div>
            <div class="row d-flex justify-content-between">
              <p><i class="fas fa-user"></i> Автор: Админ</p>
              <p><i class="fas fa-calendar"></i> Дата: 30.05.2020</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
<app-our-advantages></app-our-advantages>
<app-our-clients-small></app-our-clients-small>
<app-feedback></app-feedback>
<app-map></app-map>
