import { Component, OnInit } from '@angular/core';
import {Meta, Title} from '@angular/platform-browser';

@Component({
  selector: 'app-realizacziya-proektov',
  templateUrl: './realizacziya-proektov.component.html',
  styleUrls: ['./realizacziya-proektov.component.css']
})
export class RealizacziyaProektovComponent implements OnInit {

  constructor(private title: Title,
              private meta: Meta) { }

  ngOnInit(): void {
    this.title.setTitle('Реалізація проектів за різними рівнями вимог - Комплекс Інжиніринг Системз | Інжинірингові послуги у Києві');
    this.meta.updateTag({name: 'description', content: 'Реалізація проектів за різними рівнями вимог від інжинірингової компанії Комплекс Інжиніринг Системз в Києві ⭐ Проектування, монтаж, комплектація та постачання обладнання для систем опалення'});
    this.meta.updateTag({meta: "keywords", content:"реализация проектов киев,інжинірингова компанія,інжинірингові послуги,інжинірингова компанія Київ,промисловий інжиніринг"})
    window.scroll(0, 0);
  }
}
