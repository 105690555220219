<section class="bread-block">
  <div class="container">
    <nav aria-label="breadcrumb">
      <ol class="breadcrumb">
        <li class="breadcrumb-item"><a [routerLink]="['']">Головна</a></li>
        <li class="breadcrumb-item">Наші проекти</li>
      </ol>
    </nav>
  </div>
</section>
<section class="block portfolio-block">
  <div class="container">
    <div class="title title-zag-block">Наші проекти</div>
    <div class="filters-group">
      <div id="filters-portfolio" role="group" class="filter-options btn-group mb-5 d-flex flex-wrap">
        <button type="button" class="btn-portfolio" (click)="filter('')">Все</button>
        <button type="button" class="btn-portfolio" (click)="filter('ventilation')">Вентиляція</button>
        <button type="button" class="btn-portfolio" (click)="filter('conditioning')">Кондиціювання</button>
        <button type="button" class="btn-portfolio" (click)="filter('heating')">Опалення</button>
        <button type="button" class="btn-portfolio" (click)="filter('alternativewarm')">Альтернативні джерела тепла
        </button>
        <button type="button" class="btn-portfolio" (click)="filter('heatsupply')">Теплопостачання</button>
        <button type="button" class="btn-portfolio" (click)="filter('coldsupply')">Холодопостачання</button>
        <button type="button" class="btn-portfolio" (click)="filter('watersupply')">Водопостачання і каналізація</button>
        <button type="button" class="btn-portfolio" (click)="filter('drainage')">Осушення</button>
      </div>
    </div>
    <div class="container">
      <div id="gridPortfolio" class="row my-shuffle-container">
        <figure class="col-12 col-sm-12 col-md-6 col-lg-4 picture-item" data-groups='[""]'>
          <div class="li-filter-select-content">
            <img src="../../../assets/images/cosmos-golf-ukraine/cosmos_golf_ukraine_21.jpg" alt="Cosmos Golf Ukraine" class="img-fluid">
            <div class="name-portfolio">Cosmos Golf Ukraine</div>
            <div class="mt-auto text-center">
              <a [routerLink]="['/cosmos-golf-ukraine']" class="my-btn">ДЕТАЛЬНІШЕ</a>
            </div>
          </div>
        </figure>
        <figure class="col-12 col-sm-12 col-md-6 col-lg-4 picture-item" data-groups='[""]'>
          <div class="li-filter-select-content">
            <img src="../../../assets/images/retrovil/retrovil_1.jpg" alt="ТРЦ Ретровіль" class="img-fluid">
            <div class="name-portfolio">ТРЦ Ретровіль</div>
            <div class="mt-auto text-center">
              <a [routerLink]="['/retrovil']" class="my-btn">ДЕТАЛЬНІШЕ</a>
            </div>
          </div>
        </figure>
        <figure class="col-12 col-sm-12 col-md-6 col-lg-4 picture-item" data-groups='["conditioning"]'>
          <div class="li-filter-select-content">
            <img src="../../../assets/images/fabrica/fabrica_1.jpg" alt="Торгівельно-розважальний центр «Фабрика»"
                 class="img-fluid">
            <div class="name-portfolio">Торгівельно-розважальний центр "Фабрика"</div>
            <div class="mt-auto text-center">
              <a [routerLink]="['/fabryka']" class="my-btn">ДЕТАЛЬНІШЕ</a>
            </div>
          </div>
        </figure>
        <figure class="col-12 col-sm-12 col-md-6 col-lg-4 picture-item"
                data-groups='["ventilation","conditioning","heating","coldsupply","heatsupply"]'>
          <div class="li-filter-select-content">
            <img src="../../../assets/images/lyubava/lyubava_3.jpg" alt="Торгівельно-розважальний центр«Любава»"
                 class="img-fluid">
            <div class="name-portfolio">Торгівельно-розважальний центр «Любава»</div>
            <div class="mt-auto text-center">
              <a [routerLink]="['/lyubava']" class="my-btn">ДЕТАЛЬНІШЕ</a>
            </div>
          </div>
        </figure>
        <figure class="col-12 col-sm-12 col-md-6 col-lg-4 picture-item"
                data-groups='["ventilation","conditioning","heating","coldsupply","heatsupply"]'>
          <div class="li-filter-select-content">
            <img src="../../../assets/images/depot/depot_1.jpg" alt="Торгівельно-розважальний центр «DEPOT»"
                 class="img-fluid">
            <div class="name-portfolio">Торгівельно-розважальний центр «DEPOT»</div>
            <div class="mt-auto text-center">
              <a [routerLink]="['/depot']" class="my-btn">ДЕТАЛЬНІШЕ</a>
            </div>
          </div>
        </figure>
        <figure class="col-12 col-sm-12 col-md-6 col-lg-4 picture-item" data-groups='[""]'>
          <div class="li-filter-select-content">
            <img src="../../../assets/images/luxary-residenses/luxary_1.jpg" alt="ЖК LINDEN LUXURY RESIDENCES" class="img-fluid">
            <div class="name-portfolio">ЖК LINDEN LUXURY RESIDENCES</div>
            <div class="mt-auto text-center">
              <a [routerLink]="['/linden-luxury-residences']" class="my-btn">ДЕТАЛЬНІШЕ</a>
            </div>
          </div>
        </figure>
        <figure class="col-12 col-sm-12 col-md-6 col-lg-4 picture-item"
                data-groups='["conditioning","heatsupply","coldsupply"]'>
          <div class="li-filter-select-content">
            <img src="../../../assets/images/atmosfera/atmosfera_1.jpg"
                 alt="Торгівельно-розважальний центр «Атмосфера»" class="img-fluid">
            <div class="name-portfolio">Торгівельно-розважальний центр «Атмосфера»</div>
            <div class="mt-auto text-center">
              <a [routerLink]="['/atmosfera']" class="my-btn">ДЕТАЛЬНІШЕ</a>
            </div>
          </div>
        </figure>
<!--        todo 8-->
        <figure class="col-12 col-sm-12 col-md-6 col-lg-4 picture-item" data-groups='[""]'>
          <div class="li-filter-select-content">
            <img src="../../../assets/images/osvitoriya/osvitoriya_1.jpeg" alt="Освіторія ХАБ" class="img-fluid">
            <div class="name-portfolio">Освіторія ХАБ</div>
            <div class="mt-auto text-center">
              <a [routerLink]="['/osvitoriya-khab']" class="my-btn">ДЕТАЛЬНІШЕ</a>
            </div>
          </div>
        </figure>
        <figure class="col-12 col-sm-12 col-md-6 col-lg-4 picture-item" data-groups='[""]'>
          <div class="li-filter-select-content">
            <img src="../../../assets/images/unit/unit_1.jpg" alt="Юніт сіті" class="img-fluid">
            <div class="name-portfolio">Юніт сіті</div>
            <div class="mt-auto text-center">
              <a [routerLink]="['/unit-city']" class="my-btn">ДЕТАЛЬНІШЕ</a>
            </div>
          </div>
        </figure>
        <figure class="col-12 col-sm-12 col-md-6 col-lg-4 picture-item" data-groups='[""]'>
          <div class="li-filter-select-content">
            <img src="../../../assets/images/kyyanka/kyyanka_1.jpg" alt='Салон меблів "Киянка"' class="img-fluid">
            <div class="name-portfolio">Салон меблів "Киянка"</div>
            <div class="mt-auto text-center">
              <a [routerLink]="['/kyyanka']" class="my-btn">ДЕТАЛЬНІШЕ</a>
            </div>
          </div>
        </figure>
        <figure class="col-12 col-sm-12 col-md-6 col-lg-4 picture-item" data-groups='["conditioning"]'>
          <div class="li-filter-select-content">
            <img src="../../../assets/images/merks/merks_1.jpg" alt="Офісний центр «Меркс»" class="img-fluid">
            <div class="name-portfolio">Офісний центр "Меркс"</div>
            <div class="mt-auto text-center">
              <a [routerLink]="['/merks']" class="my-btn">ДЕТАЛЬНІШЕ</a>
            </div>
          </div>
        </figure>
        <figure class="col-12 col-sm-12 col-md-6 col-lg-4 picture-item" data-groups='["conditioning"]'>
          <div class="li-filter-select-content">
            <img src="../../../assets/images/inkristal.jpeg"
                 alt="Адміністративно-офісний бізнес-центр «Інкрістар»" class="img-fluid">
            <div class="name-portfolio">Адміністративно-офісний бізнес-центр «Інкрістар»</div>
            <div class="mt-auto text-center">
              <a [routerLink]="['/inkrystar']" class="my-btn">ДЕТАЛЬНІШЕ</a>
            </div>
          </div>
        </figure>
        <figure class="col-12 col-sm-12 col-md-6 col-lg-4 picture-item" data-groups='["conditioning"]'>
          <div class="li-filter-select-content">
            <img src="../../../assets/images/ukrkapital/ukrcapital_2.jpg" alt="Бізнес Центр «Український Капітал»"
                 class="img-fluid">
            <div class="name-portfolio">Бізнес Центр "Український Капітал"</div>
            <div class="mt-auto text-center">
              <a [routerLink]="['/ukrayinskyy-kapytal']" class="my-btn">ДЕТАЛЬНІШЕ</a>
            </div>
          </div>
        </figure>
        <figure class="col-12 col-sm-12 col-md-6 col-lg-4 picture-item"
                data-groups='["ventilation","conditioning","heating","coldsupply","heatsupply"]'>
          <div class="li-filter-select-content">
            <img src="../../../assets/images/ornament/ornament_1.jpg" alt="ТЦ Орнамент"
                 class="img-fluid">
            <div class="name-portfolio">ТЦ Орнамент</div>
            <div class="mt-auto text-center">
              <a [routerLink]="['/ornament']" class="my-btn">ДЕТАЛЬНІШЕ</a>
            </div>
          </div>
        </figure>
        <figure class="col-12 col-sm-12 col-md-6 col-lg-4 picture-item"
                data-groups='["ventilation","conditioning","heating","coldsupply","heatsupply"]'>
          <div class="li-filter-select-content">
            <img src="../../../assets/images/hotovo/hotovo_2.jpg" alt="ЦНАП «Готово»" class="img-fluid">
            <div class="name-portfolio">ЦНАП «Готово»</div>
            <div class="mt-auto text-center">
              <a [routerLink]="['/hotovo']" class="my-btn">ДЕТАЛЬНІШЕ</a>
            </div>
          </div>
        </figure>
        <figure class="col-12 col-sm-12 col-md-6 col-lg-4 picture-item" data-groups='[""]'>
          <div class="li-filter-select-content">
            <img src="../../../assets/images/jooble-skyloom/jooble_skyloom_1.jpg" alt="Офіси IT-компаній Jooble и Skyloom" class="img-fluid">
            <div class="name-portfolio">Офіси IT-компаній Jooble и Skyloom</div>
            <div class="mt-auto text-center">
              <a [routerLink]="['/jooble-skyloom']" class="my-btn">ДЕТАЛЬНІШЕ</a>
            </div>
          </div>
        </figure>
<!--        todo 18?-->
        <figure class="col-12 col-sm-12 col-md-6 col-lg-4 picture-item" data-groups='[""]'>
          <div class="li-filter-select-content">
            <img src="../../../assets/images/atlantic-residences/atlantic_1.jpg" alt="ЖК ATLANTIC RESIDENCE" class="img-fluid">
            <div class="name-portfolio">ЖК ATLANTIC RESIDENCE</div>
            <div class="mt-auto text-center">
              <a [routerLink]="['/atlantic-residence']" class="my-btn">ДЕТАЛЬНІШЕ</a>
            </div>
          </div>
        </figure>
        <figure class="col-12 col-sm-12 col-md-6 col-lg-4 picture-item" data-groups='["coldsupply","heatsupply"]'>
          <div class="li-filter-select-content">
            <img src="../../../assets/images/premium-centr.jpg"
                 alt="Торгівельно-розважальний комплекс «Преміум центр»" class="img-fluid">
            <div class="name-portfolio">Торгівельно-розважальний комплекс «Преміум центр»</div>
            <div class="mt-auto text-center">
              <a [routerLink]="['/premium-tsentr']" class="my-btn">ДЕТАЛЬНІШЕ</a>
            </div>
          </div>
        </figure>
        <figure class="col-12 col-sm-12 col-md-6 col-lg-4 picture-item" data-groups='["ventilation","conditioning"]'>
          <div class="li-filter-select-content">
            <img src="../../../assets/images/pp4.jpg"
                 alt="Офісно-готельний комплекс «Хортиця Палас»" class="img-fluid">
            <div class="name-portfolio">Офісно-готельний комплекс «Хортиця Палас»</div>
            <div class="mt-auto text-center">
              <a [routerLink]="['/khortytsya-palas']" class="my-btn">ДЕТАЛЬНІШЕ</a>
            </div>
          </div>
        </figure>
        <figure class="col-12 col-sm-12 col-md-6 col-lg-4 picture-item" data-groups='["coldsupply","heatsupply"]'>
          <div class="li-filter-select-content">
            <img src="../../../assets/images/respublica.jpg"
                 alt="Торгівельно-розважальний центр «РЕСПУБЛИКА»" class="img-fluid">
            <div class="name-portfolio">Торгівельно-розважальний центр «РЕСПУБЛІКА»</div>
            <div class="mt-auto text-center">
              <a [routerLink]="['/respublika']" class="my-btn">ДЕТАЛЬНІШЕ</a>
            </div>
          </div>
        </figure>
        <figure class="col-12 col-sm-12 col-md-6 col-lg-4 picture-item"
                data-groups='["ventilation","conditioning","heating","coldsupply","heatsupply"]'>
          <div class="li-filter-select-content">
            <img src="../../../assets/images/acura/acura_1.jpg" alt="Автосалон «ACURA»" class="img-fluid">
            <div class="name-portfolio">Автосалон «ACURA»</div>
            <div class="mt-auto text-center">
              <a [routerLink]="['/acura']" class="my-btn">ДЕТАЛЬНІШЕ</a>
            </div>
          </div>
        </figure>
        <figure class="col-12 col-sm-12 col-md-6 col-lg-4 picture-item" data-groups='["ventilation"]'>
          <div class="li-filter-select-content">
            <img src="../../../assets/images/audi.jpg" alt="Автосалон AUDI" class="img-fluid">
            <div class="name-portfolio">Автосалон AUDI</div>
            <div class="mt-auto text-center">
              <a [routerLink]="['/audi']" class="my-btn">ДЕТАЛЬНІШЕ</a>
            </div>
          </div>
        </figure>
        <figure class="col-12 col-sm-12 col-md-6 col-lg-4 picture-item" data-groups='["conditioning"]'>
          <div class="li-filter-select-content">
            <img src="../../../assets/images/new-balance/new_balance_1.jpg" alt="Мережа магазинів «New Вalance»"
                 class="img-fluid">
            <div class="name-portfolio">Мережа магазинів "New Вalance"</div>
            <div class="mt-auto text-center">
              <a [routerLink]="['/new-balance']" class="my-btn">ДЕТАЛЬНІШЕ</a>
            </div>
          </div>
        </figure>

        <figure class="col-12 col-sm-12 col-md-6 col-lg-4 picture-item" data-groups='["conditioning"]'>
          <div class="li-filter-select-content">
            <img src="../../../assets/images/pp10.jpg" alt="Продуктовий супермаркет «Сільпо»"
                 class="img-fluid">
            <div class="name-portfolio">Продуктовий супермаркет «Сільпо»</div>
            <div class="mt-auto text-center">
              <a [routerLink]="['/produktovyy-supermarket-silpo']" class="my-btn">ДЕТАЛЬНІШЕ</a>
            </div>
          </div>
        </figure>
        <figure class="col-12 col-sm-12 col-md-6 col-lg-4 picture-item"
                data-groups='["ventilation","conditioning","heating","coldsupply","heatsupply","watersupply"]'>
          <div class="li-filter-select-content">
            <img src="../../../assets/images/etoniya/etoniya_1.jpg"
                 alt="Мережа контактних зоопарків  «Країна Єнотія»" class="img-fluid">
            <div class="name-portfolio">Мережа контактних зоопарків «Країна Єнотія»</div>
            <div class="mt-auto text-center">
              <a [routerLink]="['/strana-enotyya']" class="my-btn">ДЕТАЛЬНІШЕ</a>
            </div>
          </div>
        </figure>
        <figure class="col-12 col-sm-12 col-md-6 col-lg-4 picture-item"
                data-groups='["conditioning","ventilation","heating","watersupply"]'>
          <div class="li-filter-select-content">
            <img src="../../../assets/images/pp3.jpg" alt="Готельний комплекс «Едем Резорт»"
                 class="img-fluid">
            <div class="name-portfolio">Готельний комплекс «Едем Резорт»</div>
            <div class="mt-auto text-center">
              <a [routerLink]="['/edem-rezort']" class="my-btn">ДЕТАЛЬНІШЕ</a>
            </div>
          </div>
        </figure>
        <figure class="col-12 col-sm-12 col-md-6 col-lg-4 picture-item" data-groups='["heating","watersupply"]'>
          <div class="li-filter-select-content">
            <img src="../../../assets/images/dom19.jpg" alt="19-квартирний житловий будинок на 6 поверхів"
                 class="img-fluid">
            <div class="name-portfolio">19-квартирний житловий будинок на 6 поверхів</div>
            <div class="mt-auto text-center">
              <a [routerLink]="['/kvartyrnyy-zhytlovyy-budynok']" class="my-btn">ДЕТАЛЬНІШЕ</a>
            </div>
          </div>
        </figure>
        <figure class="col-12 col-sm-12 col-md-6 col-lg-4 picture-item" data-groups='["heating"]'>
          <div class="li-filter-select-content">
            <img src="../../../assets/images/office-1.jpeg" alt="Офісне приміщення"
                 class="img-fluid">
            <div class="name-portfolio">Офісне приміщення</div>
            <div class="mt-auto text-center">
              <a [routerLink]="['/ofisne-pomeshchenye']" class="my-btn">ДЕТАЛЬНІШЕ</a>
            </div>
          </div>
        </figure>
        <figure class="col-12 col-sm-12 col-md-6 col-lg-4 picture-item" data-groups='["conditioning"]'>
          <div class="li-filter-select-content">
            <img src="../../../assets/images/pp12.jpg" alt="Магазин одягу «Luxury»"
                 class="img-fluid">
            <div class="name-portfolio">Магазин одягу «Luxury»</div>
            <div class="mt-auto text-center">
              <a [routerLink]="['/luxury']" class="my-btn">ДЕТАЛЬНІШЕ</a>
            </div>
          </div>
        </figure>
        <figure class="col-12 col-sm-12 col-md-6 col-lg-4 picture-item"
                data-groups='["ventilation","conditioning","heating","coldsupply","heatsupply","watersupply"]'>
          <div class="li-filter-select-content">
            <img src="../../../assets/images/pivobar.jpg"
                 alt="Пивний клуб - міні - пивоварня «Біркрафт»" class="img-fluid">
            <div class="name-portfolio">Пивний клуб - міні - пивоварня «Біркрафт»</div>
            <div class="mt-auto text-center">
              <a [routerLink]="['/birkraft']" class="my-btn">ДЕТАЛЬНІШЕ</a>
            </div>
          </div>
        </figure>
        <figure class="col-12 col-sm-12 col-md-6 col-lg-4 picture-item" data-groups='["conditioning"]'>
          <div class="li-filter-select-content">
            <img src="../../../assets/images/domik24.png"
                 alt="Адміністративно-житловий 24-поверховий комплекс" class="img-fluid">
            <div class="name-portfolio">Адміністративно-житловий 24-поверховий комплекс</div>
            <div class="mt-auto text-center">
              <a [routerLink]="['/administratyvno-zhytlovyy']" class="my-btn">ДЕТАЛЬНІШЕ</a>
            </div>
          </div>
        </figure>
        <figure class="col-12 col-sm-12 col-md-6 col-lg-4 picture-item" data-groups='["conditioning"]'>
          <div class="li-filter-select-content">
            <img src="../../../assets/images/galakton.jpg"
                 alt='Офісні приміщення заводу ЗАТ "Галактон"' class="img-fluid">
            <div class="name-portfolio">Офісні приміщення заводу ЗАТ "Галактон"</div>
            <div class="mt-auto text-center">
              <a [routerLink]="['/halakton']" class="my-btn">ДЕТАЛЬНІШЕ</a>
            </div>
          </div>
        </figure>
        <figure class="col-12 col-sm-12 col-md-6 col-lg-4 picture-item" data-groups='["conditioning"]'>
          <div class="li-filter-select-content">
            <img src="../../../assets/images/fitness.jpg" alt="Фітнес центр «GraFit»"
                 class="img-fluid">
            <div class="name-portfolio">Фітнес центр «GraFit»</div>
            <div class="mt-auto text-center">
              <a [routerLink]="['/grafit']" class="my-btn">ДЕТАЛЬНІШЕ</a>
            </div>
          </div>
        </figure>
        <figure class="col-12 col-sm-12 col-md-6 col-lg-4 picture-item" data-groups='["conditioning"]'>
          <div class="li-filter-select-content">
            <img src="../../../assets/images/lex/lex_1.jpg" alt="Бізнес Центр «Лекс»" class="img-fluid">
            <div class="name-portfolio">Бізнес центр «Лекс»</div>
            <div class="mt-auto text-center">
              <a [routerLink]="['/leks']" class="my-btn">ДЕТАЛЬНІШЕ</a>
            </div>
          </div>
        </figure>
        <figure class="col-12 col-sm-12 col-md-6 col-lg-4 picture-item" data-groups='["conditioning"]'>
          <div class="li-filter-select-content">
            <img src="../../../assets/images/beton.png"
                 alt="Завод залізобетонних конструкцій ім. Ковальській «Бетон-комплекс»" class="img-fluid">
            <div class="name-portfolio">Завод залізобетонних конструкцій ім. Ковальській «Бетон-комплекс»</div>
            <div class="mt-auto text-center">
              <a [routerLink]="['/beton-kompleks']" class="my-btn">ДЕТАЛЬНІШЕ</a>
            </div>
          </div>
        </figure>
        <figure class="col-12 col-sm-12 col-md-6 col-lg-4 picture-item" data-groups='["conditioning"]'>
          <div class="li-filter-select-content">
            <img src="../../../assets/images/bimarket.jpeg"
                 alt="Мережа продуктових супермаркетів «Бімаркет»" class="img-fluid">
            <div class="name-portfolio">Мережа продуктових супермаркетів «Бімаркет»</div>
            <div class="mt-auto text-center">
              <a [routerLink]="['/bimarket']" class="my-btn">ДЕТАЛЬНІШЕ</a>
            </div>
          </div>
        </figure>
        <figure class="col-12 col-sm-12 col-md-6 col-lg-4 picture-item" data-groups='["conditioning"]'>
          <div class="li-filter-select-content">
            <img src="../../../assets/images/blesk.jpg" alt="Мережа пралень «БЛИСК»<"
                 class="img-fluid">
            <div class="name-portfolio">Мережа пралень «БЛИСК»</div>
            <div class="mt-auto text-center">
              <a [routerLink]="['/blesk']" class="my-btn">ДЕТАЛЬНІШЕ</a>
            </div>
          </div>
        </figure>
        <figure class="col-12 col-sm-12 col-md-6 col-lg-4 picture-item" data-groups='["heating"]'>
          <div class="li-filter-select-content">
            <img src="../../../assets/images/pp6.jpg" alt="Складські приміщення мережі «Модна Каста»"
                 class="img-fluid">
            <div class="name-portfolio">Складські приміщення мережі «Модна Каста»</div>
            <div class="mt-auto text-center">
              <a [routerLink]="['/modnaya-kasta']" class="my-btn">ДЕТАЛЬНІШЕ</a>
            </div>
          </div>
        </figure>
        <figure class="col-12 col-sm-12 col-md-6 col-lg-4 picture-item"
                data-groups='["ventilation","conditioning","heating","coldsupply","heatsupply"]'>
          <div class="li-filter-select-content">
            <img src="../../../assets/images/pp11.jpg" alt="Офісне приміщення компанії «UA Prom»"
                 class="img-fluid">
            <div class="name-portfolio">Офісне приміщення компанії «UA Prom»</div>
            <div class="mt-auto text-center">
              <a [routerLink]="['/ua-prom']" class="my-btn">ДЕТАЛЬНІШЕ</a>
            </div>
          </div>
        </figure>
        <figure class="col-12 col-sm-12 col-md-6 col-lg-4 picture-item" data-groups='["ventilation"]'>
          <div class="li-filter-select-content">
            <img src="../../../assets/images/djubl.jpg" alt="Офіс Джубл" class="img-fluid">
            <div class="name-portfolio">Офіс Джубл</div>
            <div class="mt-auto text-center">
              <a [routerLink]="['/dzhubl']" class="my-btn">ДЕТАЛЬНІШЕ</a>
            </div>
          </div>
        </figure>
        <figure class="col-12 col-sm-12 col-md-6 col-lg-4 picture-item" data-groups='["ventilation","conditioning"]'>
          <div class="li-filter-select-content">
            <img src="../../../assets/images/logistic.jpg"
                 alt="Офісна частина логістичного комплексу" class="img-fluid">
            <div class="name-portfolio">Офісна частина логістичного комплексу</div>
            <div class="mt-auto text-center">
              <a [routerLink]="['/ofisna-chastyna-lohistychnoho-kompleksu']" class="my-btn">ДЕТАЛЬНІШЕ</a>
            </div>
          </div>
        </figure>

        <figure class="col-12 col-sm-12 col-md-6 col-lg-4 picture-item" data-groups='["conditioning"]'>
          <div class="li-filter-select-content">
            <img src="../../../assets/images/ukrplast.jpg" alt="ТОВ «УкрПластАвтомат»"
                 class="img-fluid">
            <div class="name-portfolio">ТОВ «УкрПластАвтомат»</div>
            <div class="mt-auto text-center">
              <a [routerLink]="['/ukr-plast-avtomat']" class="my-btn">ДЕТАЛЬНІШЕ</a>
            </div>
          </div>
        </figure>
        <figure class="col-12 col-sm-12 col-md-6 col-lg-4 picture-item" data-groups='["conditioning"]'>
          <div class="li-filter-select-content">
            <img src="../../../assets/images/stirol.jpg"
                 alt="Приміщення фармацевтичного концерну «Стирол»" class="img-fluid">
            <div class="name-portfolio">Приміщення фармацевтичного концерну «Стирол»</div>
            <div class="mt-auto text-center">
              <a [routerLink]="['/styrol']" class="my-btn">ДЕТАЛЬНІШЕ</a>
            </div>
          </div>
        </figure>
        <figure class="col-12 col-sm-12 col-md-6 col-lg-4 picture-item" data-groups='["conditioning"]'>
          <div class="li-filter-select-content">
            <img src="../../../assets/images/ctb.jpg"
                 alt="Студійні та робочі приміщення ЗАТ «ММЦ-СТБ»" class="img-fluid">
            <div class="name-portfolio">Студійні та робочі приміщення ЗАТ «ММЦ-СТБ»</div>
            <div class="mt-auto text-center">
              <a [routerLink]="['/mmts-stb']" class="my-btn">ДЕТАЛЬНІШЕ</a>
            </div>
          </div>
        </figure>
        <figure class="col-12 col-sm-12 col-md-6 col-lg-4 picture-item" data-groups='["ventilation"]'>
          <div class="li-filter-select-content">
            <img src="../../../assets/images/sanitar.jpg"
                 alt="Лабораторний корпус Санітарно-епідеміологічної станції" class="img-fluid">
            <div class="name-portfolio">Лабораторний корпус Санітарно-епідеміологічної станції</div>
            <div class="mt-auto text-center">
              <a [routerLink]="['/laboratornyy-korpus']" class="my-btn">ДЕТАЛЬНІШЕ</a>
            </div>
          </div>
        </figure>
        <figure class="col-12 col-sm-12 col-md-6 col-lg-4 picture-item" data-groups='["conditioning"]'>
          <div class="li-filter-select-content">
            <img src="../../../assets/images/acme.jpg" alt="ООО «ПТФ «Экми»" class="img-fluid">
            <div class="name-portfolio">ТОВ «ВТФ «Екмі»</div>
            <div class="mt-auto text-center">
              <a [routerLink]="['/ekmy']" class="my-btn">ДЕТАЛЬНІШЕ</a>
            </div>
          </div>
        </figure>

        <figure class="col-12 col-sm-12 col-md-6 col-lg-4 picture-item" data-groups='["conditioning"]'>
          <div class="li-filter-select-content">
            <img src="../../../assets/images/garde.jpg" alt="Мультибрендовий бутік «GARDE»"
                 class="img-fluid">
            <div class="name-portfolio">Мультибрендовий бутік «GARDE»</div>
            <div class="mt-auto text-center">
              <a [routerLink]="['/garde']" class="my-btn">ДЕТАЛЬНІШЕ</a>
            </div>
          </div>
        </figure>
        <figure class="col-12 col-sm-12 col-md-6 col-lg-4 picture-item"
                data-groups='["ventilation","conditioning","heating","coldsupply","heatsupply","watersupply"]'>
          <div class="li-filter-select-content">
            <img src="../../../assets/images/shahplov/shahplov_2.jpg"
                 alt="Ресторан азербайджанської кухні Shah-Plov" class="img-fluid">
            <div class="name-portfolio">Ресторан азербайджанської кухні Shah-Plov</div>
            <div class="mt-auto text-center">
              <a [routerLink]="['/shah-plov']" class="my-btn">ДЕТАЛЬНІШЕ</a>
            </div>
          </div>
        </figure>
        <figure class="col-12 col-sm-12 col-md-6 col-lg-4 picture-item" data-groups='[""]'>
          <div class="li-filter-select-content">
            <img src="../../../assets/images/bc-europe/europe_1.jpg" alt="Адміністративний готельно - офісний центр БЦ Європа та готель Рамада Енкор" class="img-fluid">
            <div class="name-portfolio">Адміністративний готельно - офісний центр БЦ Європа та готель Рамада Енкор</div>
            <div class="mt-auto text-center">
              <a [routerLink]="['/bts-europe']" class="my-btn">ДЕТАЛЬНІШЕ</a>
            </div>
          </div>
        </figure>
      </div>
    </div>
  </div>
</section>
<app-our-clients-small></app-our-clients-small>
<section class="content-page my-2 my-lg-4">
  <div class="container">
    <div class="title title-zag-block">Наші об'єкти</div>
    <p class="vc_custom_heading">
      <span>Наша компанія в даний час займається реалізацією інженерних рішень</span><span>на наступних об'єктах:</span>
    </p>
    <ol>
      <li class="ql-align-justify"><strong>Готельний комплекс «Едем Резорт»</strong> (Київська область, с. Козин) -
        кондиціювання та холодопостачання на базі ХМ Climaveneta (Італія), кондиціювання на базі LG Multi V
        (Корея), вентиляція AEROSTAR (Україна), опалення, водопостачання та каналізація. 5.7 тис. м2.
      </li>
      <li class="ql-align-justify"><strong>ТРЦ «РЕТРОВІЛЬ»</strong> (м. Київ) - пристрій систем вентиляції FRAPOL
        (Польща), теплопостачання, холодопостачання на базі ХМ TRANE (США), руфтопи YORK (США), кондиціювання на базі
        LG Multi V (Корея). 100 тис. м2.
      </li>
      <li class="ql-align-justify"><strong>Адміністративні приміщення</strong> <strong>IT-парк Unit Cit</strong>y (р..
        Київ) - фанкойли Aermec (Італія), холодопостачання, опалення, вентиляція, кондиціювання на базі LG Multi V
        (Корея). 5 тис. м2.
      </li>
      <li class="ql-align-justify"><strong>ЖК Linden Luxury Residence</strong> (м. Київ) - системи вентиляції LG EcoV
        (Корея) та Salda (Литва), центрального кондиціювання на базі LG Multi V (Корея). 22 тис м2.
      </li>
      <li class="ql-align-justify"><strong>ЖК ATLANTIC</strong> (м. Київ) - системи центрального кондиціювання на
        базі LG Multi V (Корея). 100 тис. м2.
      </li>
      <li class="ql-align-justify"><strong>ТРЦ «Микільський»</strong> (м. Харків) - пристрій систем вентиляції,
        теплопостачання, холодопостачання на базі обладнання LG Multi V (Корея) та VTS Clima (Польща). 105 тис. м2.
      </li>
      <li class="ql-align-justify"><strong>ТРЦ «Планета Молл»</strong> (м. Харків) - реконструкція систем вентиляції,
        теплопостачання, холодопостачання YORK (США). 67 тис м2.
      </li>
    </ol>
    <p><b>ТОВ «Комплекс Інжиніринг Системз»</b> проектує та встановлює системи вентиляції, кондиціювання, опалення,
      тепло- та холодопостачання з 2003 року. За цей час ми нагромадили безцінний досвід роботи з будь-якими типами об'єктів: від
      приватних котеджів до навчальних закладів. <strong>Компанії та промислові об'єкти, яким
        Надавалися інжинірингові послуги від компанії Комплекс Інжиніринг Системз</strong> звертаються до нас на
      регулярній основі, замовляючи діагностику та сервіс обладнання. Кожен клієнт стає нашим другом, адже ми
      поринаємо в його світ потреб та проблем, створюючи сприятливу атмосферу для життя та роботи. Завдяки
      відкритій, чесній та теплій співпраці люди рекомендують нас своїм знайомим, не сумніваючись у якості
      послуг.</p>
    <p>Описуючи <strong>наші об'єкти Комплекс Інжиніринг Системз</strong>акцентує увагу на послідовній
      роботі до проектування та реалізації. Кожен етап роботи прозорий та відкритий для аналізу та контролю. Ви можете
      зрозуміти причини та оцінити якість кожної операції. Запрошуємо вас познайомитись з нами ближче!</p></div>
</section>
<app-feedback></app-feedback>
<app-map></app-map>
