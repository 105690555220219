<section class="bread-block">
  <div class="container">
    <nav aria-label="breadcrumb">
      <ol class="breadcrumb">
        <li class="breadcrumb-item"><a [routerLink]="['']">Головна</a></li>
        <li class="breadcrumb-item"><a [routerLink]="['/uslugi']">Наші послуги</a></li>
        <li class="breadcrumb-item">Розробка проектів з розділів ОВіК</li>
      </ol>
    </nav>
  </div>
</section>
<section class="content-page my-2 my-lg-4">
  <div class="container">
    <div class="title title-zag-block">РОЗРОБКА ПРОЕКТІВ ЗА РОЗДІЛАМИ ОВІК</div>
    <p class="ql-align-justify"><strong>Компанія «Комплекс Інжиніринг Системз»</strong> - це численний колектив
      дипломованих фахівців високої кваліфікації з багаторічним досвідом роботи, здатний задовольнити
      високі вимоги замовника у найкоротші терміни. Робота проектувальників заснована на застосуванні інноваційних
      технологій, передових світових практик та програмних продуктів, а також сучасної техніки. Під час підготовки
      проектних рішень наші фахівці, за необхідності, дають рекомендації щодо вибору торгової марки та типу
      необхідного обладнання, що оптимізує терміни та фінансові витрати замовника на виконання
      проекту.</p><h4><strong>Проект ОВіК</strong></h4>
    <p class="ql-align-justify">Проект ОВіК чи проект ОВ – це інженерний розділ загального комплекту проектної
      документації на будівлю чи споруду, до повного розділу входять підрозділи опалення, вентиляція, теплопостачання
      вентиляції, кондиціювання, холодопостачання (поширена назва розділу проектування ВВ). При
      проведення реконструкції може розроблятися окремо. Інженерні системи, розроблені в ньому, дозволяють
      регулювати температуру в приміщеннях, підтримувати здоровий мікроклімат. Проектування ОВіК повинне
      виконуватись відповідно до нормативних вимог. Також проект має враховувати усі побажання замовника.
      Проект узгоджується з іншими інженерними та архітектурно-будівельними розділами.</p><h4><strong>Проектування опалення, вентиляції та кондиціювання</strong></h4>
    <p class="ql-align-justify">Основним завданням опалювального обладнання є підтримка у приміщеннях комфортною
      температури. Під час розробки проекту обов'язково виконуються теплотехнічні розрахунки, враховуються
      втрати тепла. Якщо проект розробляється для існуючої будівлі, проектувальники виїжджають на об'єкт, виконують
      його обстеження.</p>
    <p class="ql-align-justify">Вентиляція забезпечує підтримку чистоти повітря у приміщеннях. За її ефективної
      роботі повітря у приміщенні буде відповідати санітарним вимогам. Залежно від характеристик будівлі
      використовується природна, припливно-витяжна чи комбінована вентиляційна система. Як правило,
      розробляється проект димовидалення (розділ проекту ДУ), який визначає, як у разі займання буде
      використовувати повітря.</p>
    <p class="ql-align-justify">У процесі проектування кондиціювання підбирається необхідне обладнання,
      виконуються розрахунки. Також у специфікації вказані необхідні для монтажу елементи трубопроводів, арматура.</p>
    <h4><strong>Проект опалення, вентиляції та кондиціювання</strong></h4>
    <p class="ql-align-justify">Склад документації встановлено на законодавчому рівні. До неї входить пояснювальна
      записка, креслення та схеми, специфікація обладнання. Проект оформляється відповідно до чинних нормативних актів.
      вимогами</p>
    <p class="ql-align-justify">Опалення є найбільш витратною інженерною системою. Встановлення системи опалення
      обходиться приблизно 5% від загальної вартості будівництва будинку. Експлуатаційні витрати можуть досягати половини
      всіх витрат на утримання будинку. Грамотний проект системи опалення дає можливість суттєво знизити витрати,
      як на етапі будівництва, так і в процесі експлуатації.</p>
    <p class="ql-align-justify">У приватних будинках та квартирах побутовий кондиціонер, як правило, встановлюється без
      проекту. Підбір обладнання виконується відповідно до рекомендацій фахівців. для адміністративних
      будівель, магазинів, виробничих приміщень, для житлових будинків обов'язково потрібен розділ проекту ОВ,
      систем опалення, вентиляції та кондиціювання.</p>
    <p class="ql-align-justify">За відсутності гарної вентиляції перебувати в будь-якій будівлі небезпечно для здоров'я. У
      у разі виробничих приміщень неефективна робота вентиляції може стати причиною вибуху або масового
      отруєння людей отруйними речовинами. Тому важливо, щоб усі розрахунки систем та проект ОВіК виконувались досвідченими.
      спеціалістами.</p>
    <p class="ql-align-justify">Терміни розробки проекту та його вартість визначаються в індивідуальному порядку,
      залежності від його складності та обсягу необхідних робіт. Фахівці <strong>ккомпанії «Комплекс Інжиніринг
        Системз» </strong>захищають проектні рішення та готову проектну документацію в наглядових органах, і готові
      допомогти вам на всіх етапах проектних робіт.</p>
    <p class="ql-align-justify"><br></p>
  </div>
</section>
<app-additional-services></app-additional-services>
<app-our-advantages></app-our-advantages>
<app-our-clients-small></app-our-clients-small>
<app-feedback></app-feedback>
<app-map></app-map>
