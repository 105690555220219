<button class="my-btn text-uppercase" data-toggle="modal" data-target="#callFormModal">ЗАМОВИТИ ДЗВІНОК</button>

<div class="modal fade" id="callFormModal" #callFormModal tabindex="-1" role="dialog" aria-labelledby="btn-form-modalTitle" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <div class="h5 modal-title text-white" id="btn-form-modalTitle">замовити дзвінок</div>
        <button #closeModal type="button" class="close text-white" style="opacity:1;" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">×</span>
        </button>
      </div>
      <div class="modal-body">
        <form [formGroup]="callForm" id="callForm">
          <div class="row">
            <div class="col">
              <div class="form-group">
                <input type="text" formControlName="name" id="name" class="form-control" placeholder="Ім'я*" [ngClass]="isControlInvalid('name') ? 'input-error':''">
              </div>
              <div class="form-group">
                <input type="text" formControlName="phone" id="phone" class="form-control" placeholder="Телефон*" [ngClass]="isControlInvalid('phone') ? 'input-error':''">
              </div>
              <div class="text-center">
                <button class="my-btn btn-zd-vop p-2 px-3" (click)="send()">Замовити</button>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>
