<section class="bread-block">
  <div class="container">
    <nav aria-label="breadcrumb">
      <ol class="breadcrumb">
        <li class="breadcrumb-item"><a [routerLink]="['']">Головна</a></li>
        <li class="breadcrumb-item"><a [routerLink]="['/portfolio']">Портфоліо</a></li>
        <li class="breadcrumb-item">Cosmos Golf Ukraine</li>
      </ol>
    </nav>
  </div>
</section>

<section class="content-page my-2 my-lg-4">
  <div class="container">
    <div class="title title-zag-block">Cosmos Golf Ukraine</div>
  </div>
</section>

<section class="slider">
  <div class="container">
    <div class="portfolio-owl owl-carousel owl-theme">
      <div class="slider-item">
        <img src="../../../assets/images/cosmos-golf-ukraine/cosmos_golf_ukraine_21.jpg" alt="Cosmos Golf Ukraine"
             class="img-fluid">
      </div>
      <div class="slider-item">
        <img src="../../../assets/images/cosmos-golf-ukraine/cosmos_golf_ukraine_2.jpg" alt="Cosmos Golf Ukraine"
             class="img-fluid">
      </div>
      <div class="slider-item">
        <img src="../../../assets/images/cosmos-golf-ukraine/cosmos_golf_ukraine_3.jpg" alt="Cosmos Golf Ukraine"
             class="img-fluid">
      </div>
      <div class="slider-item">
        <img src="../../../assets/images/cosmos-golf-ukraine/cosmos_golf_ukraine_4.jpg" alt="Cosmos Golf Ukraine"
             class="img-fluid">
      </div>
      <div class="slider-item">
        <img src="../../../assets/images/cosmos-golf-ukraine/cosmos_golf_ukraine_5.jpg" alt="Cosmos Golf Ukraine"
             class="img-fluid">
      </div>
      <div class="slider-item">
        <img src="../../../assets/images/cosmos-golf-ukraine/cosmos_golf_ukraine_6.jpg" alt="Cosmos Golf Ukraine"
             class="img-fluid">
      </div>
      <div class="slider-item">
        <img src="../../../assets/images/cosmos-golf-ukraine/cosmos_golf_ukraine_7.jpg" alt="Cosmos Golf Ukraine"
             class="img-fluid">
      </div>
      <div class="slider-item">
        <img src="../../../assets/images/cosmos-golf-ukraine/cosmos_golf_ukraine_8.jpg" alt="Cosmos Golf Ukraine"
             class="img-fluid">
      </div>
      <div class="slider-item">
        <img src="../../../assets/images/cosmos-golf-ukraine/cosmos_golf_ukraine_9.jpg" alt="Cosmos Golf Ukraine"
             class="img-fluid">
      </div>
      <div class="slider-item">
        <img src="../../../assets/images/cosmos-golf-ukraine/cosmos_golf_ukraine_10.jpg" alt="Cosmos Golf Ukraine"
             class="img-fluid">
      </div>
      <div class="slider-item">
        <img src="../../../assets/images/cosmos-golf-ukraine/cosmos_golf_ukraine_11.jpg" alt="Cosmos Golf Ukraine"
             class="img-fluid">
      </div>
      <div class="slider-item">
        <img src="../../../assets/images/cosmos-golf-ukraine/cosmos_golf_ukraine_12.jpg" alt="Cosmos Golf Ukraine"
             class="img-fluid">
      </div>
      <div class="slider-item">
        <img src="../../../assets/images/cosmos-golf-ukraine/cosmos_golf_ukraine_13.jpg" alt="Cosmos Golf Ukraine"
             class="img-fluid">
      </div>
      <div class="slider-item">
        <img src="../../../assets/images/cosmos-golf-ukraine/cosmos_golf_ukraine_14.jpg" alt="Cosmos Golf Ukraine"
             class="img-fluid">
      </div>
      <div class="slider-item">
        <img src="../../../assets/images/cosmos-golf-ukraine/cosmos_golf_ukraine_15.jpg" alt="Cosmos Golf Ukraine"
             class="img-fluid">
      </div>
      <div class="slider-item">
        <img src="../../../assets/images/cosmos-golf-ukraine/cosmos_golf_ukraine_16.jpg" alt="Cosmos Golf Ukraine"
             class="img-fluid">
      </div>
      <div class="slider-item">
        <img src="../../../assets/images/cosmos-golf-ukraine/cosmos_golf_ukraine_17.jpg" alt="Cosmos Golf Ukraine"
             class="img-fluid">
      </div>
      <div class="slider-item">
        <img src="../../../assets/images/cosmos-golf-ukraine/cosmos_golf_ukraine_18.jpg" alt="Cosmos Golf Ukraine"
             class="img-fluid">
      </div>
      <div class="slider-item">
        <img src="../../../assets/images/cosmos-golf-ukraine/cosmos_golf_ukraine_19.jpg" alt="Cosmos Golf Ukraine"
             class="img-fluid">
      </div>
      <div class="slider-item">
        <img src="../../../assets/images/cosmos-golf-ukraine/cosmos_golf_ukraine_20.jpg" alt="Cosmos Golf Ukraine"
             class="img-fluid">
      </div>
      <div class="slider-item">
        <img src="../../../assets/images/cosmos-golf-ukraine/cosmos_golf_ukraine_1.jpg" alt="Cosmos Golf Ukraine"
             class="img-fluid">
      </div>
    </div>
  </div>
</section>
<section class="content-page my-2 my-lg-4">
  <div class="container">
    <p>Cosmos Golf Ukraine (м. Київ, м.Політех).</p>
  </div>
</section>
<section class="content-page my-2 my-lg-4">
  <div class="container">
    <div class="title title-zag-block">Проведені роботи</div>
    <ol>
      <li>Монтаж системи кондиціювання та вентиляції на базі обладнання LG (Корея)</li>
      <li>Монтаж систем опалення, водопостачання та каналізації під ключ</li>
    </ol>
  </div>
</section>

<app-our-advantages></app-our-advantages>
<app-our-clients-small></app-our-clients-small>
<app-feedback></app-feedback>
<app-map></app-map>
