import {Component, OnInit} from '@angular/core';

@Component({
  selector: 'app-uslugi',
  templateUrl: './uslugi.component.html',
  styleUrls: ['./uslugi.component.css']
})
export class UslugiComponent implements OnInit {

  constructor() {
  }

  ngOnInit(): void {

  }
}
