import {Component, OnInit} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {NgxSpinnerService} from 'ngx-spinner';
import {ToastrService} from 'ngx-toastr';
import {MessageService} from '../../services/message.service';
import {ErrorService} from '../../services/error.service';
import Constants from '../../utils/constants';
import {CustomValidators} from 'ngx-custom-validators';
import {EmailConnectDto} from '../../dto/email.connect.dto';

@Component({
  selector: 'app-feedback',
  templateUrl: './feedback.component.html',
  styleUrls: ['./feedback.component.css']
})
export class FeedbackComponent implements OnInit {

  callForm: FormGroup;

  constructor(private spinner: NgxSpinnerService,
              private toast: ToastrService,
              private messageService: MessageService,
              private errorService: ErrorService) {
  }

  ngOnInit(): void {
    this.callForm = new FormGroup({
      name: new FormControl('', [Validators.required, Validators.minLength(2)]),
      email: new FormControl('', [Validators.required, CustomValidators.email]),
      phone: new FormControl('', [Validators.required, Validators.minLength(10)]),
      message: new FormControl('')
    });
  }

  isControlInvalid(controlName: string): boolean {
    const control = this.callForm.controls[controlName];
    return control.invalid && control.touched;
  }

  send() {
    const controls = this.callForm.controls;
    if (this.callForm.invalid) {
      Object.keys(controls)
        .forEach(controlName => controls[controlName].markAsTouched());
      return;
    }

    const name = this.callForm.get('name').value;
    const phone = this.callForm.get('phone').value;
    const email = this.callForm.get('email').value;
    const message = this.callForm.get('message').value;
    let dto = new EmailConnectDto();
    dto.name = name;
    dto.phone = phone;
    dto.email = email;
    dto.message = message;

    this.spinner.show();
    this.messageService.connect(dto).subscribe(s => {
      this.spinner.hide();
      if (s.status.code == Constants.HTTP_CODE_200) {
        this.callForm.reset();
        this.toast.success("Дякуємо за ваше звернення, ми зв'яжемося з вами");
      } else {
        this.toast.error(Constants.DEFAULT_ERROR_MESSAGE);
      }
    }, error1 => {
      this.spinner.hide();
      this.toast.error(this.errorService.getErrorMessage(error1));
    });
  }
}
