import {Component, OnInit} from '@angular/core';
import {Meta, Title} from '@angular/platform-browser';

@Component({
  selector: 'app-news',
  templateUrl: './news.component.html',
  styleUrls: ['./news.component.css']
})
export class NewsComponent implements OnInit {

  constructor(private title: Title,
              private meta: Meta) {
  }

  ngOnInit(): void {
    this.title.setTitle('Новости - Комплекс Інжиніринг Системз | Інжинірингові послуги у Києві');
    this.meta.updateTag({
      name: 'description',
      content: 'Новини від компанії Комплекс Інжиніринг Системз в Києві ⭐ Проектування, монтаж, ремонт, діагностика та обслуговування ініжинірингових систем від професіоналів за доступними цінами'
    });
    this.meta.updateTag({
      meta: 'keywords',
      content: 'новости комплекс инжиниринг системз,інжинірингова компанія,інжинірингові послуги,інжинірингова компанія Київ,промисловий інжиніринг'
    });
    window.scroll(0, 0);
  }
}
