import { Component, OnInit } from '@angular/core';
import {Meta, Title} from '@angular/platform-browser';

@Component({
  selector: 'app-poslegarantijnoe-obsluzhivanie',
  templateUrl: './poslegarantijnoe-obsluzhivanie.component.html',
  styleUrls: ['./poslegarantijnoe-obsluzhivanie.component.css']
})
export class PoslegarantijnoeObsluzhivanieComponent implements OnInit {

  constructor(private title: Title,
              private meta: Meta) { }

  ngOnInit(): void {
    this.title.setTitle('Післягарантійне обслуговування - Комплекс Інжиніринг Системз | Інжинірингові послуги у Києві');
    this.meta.updateTag({name: 'description', content: 'Післягарантійне обслуговування від інжинірингової компанії Комплекс Інжиніринг Системз в Києві ⭐ Проектування, монтаж, комплектація та постачання обладнання для систем опалення'});
    this.meta.updateTag({meta: "keywords", content:"послегарантийное обслуживание,послегарантийное обслуживание киев,інжинірингова компанія,інжинірингові послуги,інжинірингова компанія Київ,промисловий інжиніринг"})
    window.scroll(0, 0);
  }

}
