<section class="bread-block">
  <div class="container">
    <nav aria-label="breadcrumb">
      <ol class="breadcrumb">
        <li class="breadcrumb-item"><a [routerLink]="['']">Головна</a></li>
        <li class="breadcrumb-item"><a [routerLink]="['/portfolio']">Портфоліо</a></li>
        <li class="breadcrumb-item">ТЦ Орнамент</li>
      </ol>
    </nav>
  </div>
</section>

<section class="content-page my-2 my-lg-4">
  <div class="container">
    <div class="title title-zag-block">ТЦ ОРНАМЕНТ</div>
  </div>
</section>

<section class="slider">
  <div class="container">
    <div class="portfolio-owl owl-carousel owl-theme">
      <div class="slider-item">
        <img src="../../../../assets/images/ornament/ornament_1.jpg" alt="ТЦ Орнамент 5 тыс м2"
             class="img-fluid">
      </div>
      <div class="slider-item">
        <img src="../../../../assets/images/ornament/ornament_2.jpg" alt="ТЦ Орнамент 5 тыс м2"
             class="img-fluid">
      </div>
      <div class="slider-item">
        <img src="../../../../assets/images/ornament/ornament_3.jpg" alt="ТЦ Орнамент 5 тыс м2"
             class="img-fluid">
      </div>
      <div class="slider-item">
        <img src="../../../../assets/images/ornament/ornament_4.jpg" alt="ТЦ Орнамент 5 тыс м2"
             class="img-fluid">
      </div>
      <div class="slider-item">
        <img src="../../../../assets/images/ornament/ornament_5.jpg" alt="ТЦ Орнамент 5 тыс м2"
             class="img-fluid">
      </div>
    </div>
  </div>
</section>
<section class="content-page my-2 my-lg-4">
  <div class="container">
    <p>ТЦ Орнамент 5 тис м2 (м. Київ, пр. Правди).</p>
  </div>
</section>
<section class="content-page my-2 my-lg-4">
  <div class="container">
    <div class="title title-zag-block">Проведені роботи</div>
    <ol>
      <li>Монтаж системи кондиціювання на базі обладнання LG Multi V IV (Корея)</li>
      <li>Монтаж системи вентиляції на базі обладнання Aerostar (Україна)</li>
      <li>Монтаж системи опалення на теплових насосах</li>
    </ol>
  </div>
</section>

<app-our-advantages></app-our-advantages>
<app-our-clients-small></app-our-clients-small>
<app-feedback></app-feedback>
<app-map></app-map>
