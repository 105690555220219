import { Component, OnInit } from '@angular/core';
import {Meta, Title} from '@angular/platform-browser';

@Component({
  selector: 'app-trc-retroville',
  templateUrl: './trc-retroville.component.html',
  styleUrls: ['./trc-retroville.component.css']
})
export class TrcRetrovilleComponent implements OnInit {

  constructor(private title: Title,
              private meta: Meta) {
  }

  ngOnInit(): void {
    this.title.setTitle('БЦ та ТРЦ Retroville відкрився - Комплекс Інжиніринг Системз | Інжинірингові послуги у Києві');
    this.meta.updateTag({
      name: 'description',
      content: 'БЦ та ТРЦ Retroville відкрився. Комплекс Інжиніринг Системз ⭐ Проектування, монтаж, ремонт, діагностика та обслуговування ініжинірингових систем від професіоналів за доступними цінами'
    });
    this.meta.updateTag({
      meta: 'keywords',
      content: 'новости комплекс инжиниринг системз,інжинірингова компанія,інжинірингові послуги,інжинірингова компанія Київ,промисловий інжиніринг'
    });
    window.scroll(0, 0);
  }

}
