<section class="bread-block">
  <div class="container">
    <nav aria-label="breadcrumb">
      <ol class="breadcrumb">
        <li class="breadcrumb-item"><a [routerLink]="['']">Головна</a></li>
        <li class="breadcrumb-item"><a [routerLink]="['/portfolio']">Портфоліо</a></li>
        <li class="breadcrumb-item">ЖК linden luxury residences</li>
      </ol>
    </nav>
  </div>
</section>

<section class="content-page my-2 my-lg-4">
  <div class="container">
    <div class="title title-zag-block">ЖК LINDEN LUXURY RESIDENCES</div>
  </div>
</section>

<section class="slider">
  <div class="container">
    <div class="portfolio-owl owl-carousel owl-theme">
      <div class="slider-item">
        <img src="../../../assets/images/luxary-residenses/luxary_1.jpg" alt="ЖК LINDEN LUXURY RESIDENCES"
             class="img-fluid">
      </div>
      <div class="slider-item">
        <img src="../../../assets/images/luxary-residenses/luxary_2.jpg" alt="ЖК LINDEN LUXURY RESIDENCES"
             class="img-fluid">
      </div>
      <div class="slider-item">
        <img src="../../../assets/images/luxary-residenses/luxary_3.jpg" alt="ЖК LINDEN LUXURY RESIDENCES"
             class="img-fluid">
      </div>
      <div class="slider-item">
        <img src="../../../assets/images/luxary-residenses/luxary_4.jpg" alt="ЖК LINDEN LUXURY RESIDENCES"
             class="img-fluid">
      </div>
    </div>
  </div>
</section>
<section class="content-page my-2 my-lg-4">
  <div class="container">
    <p>ЖК linden luxury residences (м. Київ, вул. Лютеранській, 14-в), загальна площа 22 тис м2</p>
  </div>
</section>
<section class="content-page my-2 my-lg-4">
  <div class="container">
    <div class="title title-zag-block">Проведені роботи</div>
    <ol>
      <li>Монтаж системи вентиляції LG EcoV (Корея) та Salda (Литва)</li>
      <li>Монтаж систем центрального кондиціювання на базі LG Multi V (Корея)</li>
    </ol>
  </div>
</section>

<app-our-advantages></app-our-advantages>
<app-our-clients-small></app-our-clients-small>
<app-feedback></app-feedback>
<app-map></app-map>
