import { Component, OnInit } from '@angular/core';
import {Meta, Title} from '@angular/platform-browser';

@Component({
  selector: 'app-garantiynoye-obsluzhivaniye',
  templateUrl: './garantiynoye-obsluzhivaniye.component.html',
  styleUrls: ['./garantiynoye-obsluzhivaniye.component.css']
})
export class GarantiynoyeObsluzhivaniyeComponent implements OnInit {

  constructor(private title: Title,
              private meta: Meta) {
  }

  ngOnInit(): void {
    this.title.setTitle('Гарантійне обслуговування - Комплекс Інжиніринг Системз | Інжинірингові послуги у Києві');
    this.meta.updateTag({name: 'description', content: 'Гарантійне обслуговування від інжинірингової компанії Комплекс Інжиніринг Системз в Києві ⭐ Проектування, монтаж, комплектація та постачання обладнання для систем альтернативних джерел тепла'});
    this.meta.updateTag({meta: "keywords", content:"гарантійне обслуговування"})
    window.scroll(0, 0);
  }

}
