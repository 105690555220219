<section class="bread-block">
  <div class="container">
    <nav aria-label="breadcrumb">
      <ol class="breadcrumb">
        <li class="breadcrumb-item"><a [routerLink]="['']">Головна</a></li>
        <li class="breadcrumb-item"><a [routerLink]="['/portfolio']">Портфоліо</a></li>
        <li class="breadcrumb-item">Адміністративний готельно - офісний центр Домосфера</li>
      </ol>
    </nav>
  </div>
</section>

<section class="content-page my-2 my-lg-4">
  <div class="container">
    <div class="title title-zag-block">АДМІНІСТРАТИВНИЙ ГОТЕЛЬНО-ОФІСНИЙ ЦЕНТР ДОМОСФЕРУ</div>
  </div>
</section>

<section class="slider">
  <div class="container">
    <div class="portfolio-owl owl-carousel owl-theme">
      <div class="slider-item">
        <img src="../../../assets/images/domosfera/domosfera_1.jpg"
             alt="Административный гостинично - офисный центр Домосфера" class="img-fluid">
      </div>
      <div class="slider-item">
        <img src="../../../assets/images/domosfera/domosfera_2.jpg"
             alt="Административный гостинично - офисный центр Домосфера" class="img-fluid">
      </div>
      <div class="slider-item">
        <img src="../../../assets/images/domosfera/domosfera_3.jpg"
             alt="Административный гостинично - офисный центр Домосфера" class="img-fluid">
      </div>
    </div>
  </div>
</section>
<section class="content-page my-2 my-lg-4">
  <div class="container">
    <p>Адміністративний готельно-офісний центр Домосфера (м. Київ, Столичне шосе 101: БЦ Європа та готель Рамада
      Енкор) - найбільш масштабний у Європі проект із систем VRF з водяним
      охолодженням, 40 тис. м2</p>
  </div>
</section>
<section class="content-page my-2 my-lg-4">
  <div class="container">
    <div class="title title-zag-block">Проведені роботи</div>
    <ol>
      <li>Монтаж мультизональних систем кондиціювання повітря на базі LG Multi V (Корея) з водяним охолодженням
        3,3 МВт, 867 внутрішніх блоків</li>
    </ol>
  </div>
</section>

<app-our-advantages></app-our-advantages>
<app-our-clients-small></app-our-clients-small>
<app-feedback></app-feedback>
<app-map></app-map>
