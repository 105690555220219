<body class="site">
<main class="site-content">
  <app-header></app-header>
  <router-outlet></router-outlet>
</main>
<app-footer></app-footer>
<div class="scroll-to-top" (click)="scrollToTop()" [ngClass]="{'show-scroll': navIsFixed}">
  <label class="btn" style="margin-right: 8px; padding: 0;text-align: left">
    <i class="fas fa-chevron-up chevron-up"></i>
  </label>
</div>
</body>
