// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  // api: 'http://localhost:8080/api'
  api: 'https://api.cis.kiev.ua/api'
};


// ng build --base-href=/ --prod
// ng g c new-components --module app

// ng g c unit-city --module app
