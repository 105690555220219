import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../environments/environment';
import {Observable} from 'rxjs';
import {CisResponse} from '../dto/cis.response';
import {EmailCallDto} from '../dto/email.call.dto';
import {EmailConnectDto} from '../dto/email.connect.dto';

@Injectable()
export class MessageService {

  constructor(private http: HttpClient) {
  }

  public call(dto:EmailCallDto): Observable<CisResponse> {
    return this.http.post<CisResponse>(`${environment.api}/notification/call`, dto);
  }

  public connect(dto:EmailConnectDto): Observable<CisResponse> {
    return this.http.post<CisResponse>(`${environment.api}/notification/connect`, dto);
  }
}
