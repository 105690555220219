<section class="bread-block">
  <div class="container">
    <nav aria-label="breadcrumb">
      <ol class="breadcrumb">
        <li class="breadcrumb-item"><a [routerLink]="['']">Головна</a></li>
        <li class="breadcrumb-item"><a [routerLink]="['/news']">Новини</a></li>
        <li class="breadcrumb-item">ТРЦ Retroville</li>
      </ol>
    </nav>
  </div>
</section>

<section class="content-page my-2 my-lg-4">
  <div class="container">
    <div class="title title-zag-block">БЦ та ТРЦ Retroville відкрився</div>
    <img src="../../../assets/images/news/retroville/retroville-1.jpg" alt="" class="img-article">
    <p class="ql-align-justify">До Дня Києва, незважаючи на обмеження, пов'язані з пандемією коронавірусу та погану
      погоду відкрився найбільший торгово-розважальний центр столиці «Retroville»!</p>
    <p class="ql-align-justify">Урочиста церемонія відкриття відбулася днем раніше 30 травня і її відвідав мер Києва -
      Віталій Кличко, який взяв участь у перерізанні червоної стрічки. Напередодні на базі комплексу розпочав роботу
      будівельний гіпермаркет Leroy Merlin та супермаркет Novus у новому форматі. ТРЦ працює із суворим дотриманням
      всіх санітарно-епідеміологічних норм, тому зона розваг, ресторани та фуд-корти працюють тільки на винос та
      будуть запущені одразу після зняття карантину.</p>
    <img src="../../../assets/images/news/retroville/retroville-2.jpg" alt="" class="img-article">
    <p class="ql-align-justify">Для безпеки персоналу та покупців запроваджено особливий режим роботи: у приміщенні центру
      можна перебувати лише в масці, кількість відвідувачів обмежена, у ключових місцях встановлені санітайзери,
      регулярно проводиться дезінфекція, а також гостей закликають бути обережними чисельні інформаційні плакати
      та оголошення по системі оповіщення. Також, у день відкриття, кожен відвідувач отримав маску з торговим логотипом.
      центру.</p>
    <img src="../../../assets/images/news/retroville/retroville-4.jpg" alt="" class="img-article">
    <p class="ql-align-justify">Окрім міського голови Києва, в офіційній церемонії відкриття взяли участь
      голова Подільської районної державної адміністрації та представники посольства Литовської Республіки
      Україною. Окремо церемонію освячення нового ТРЦ провів владика Агапіт, настоятель Свято-Михайлівського
      монастиря.</p>
    <img src="../../../assets/images/news/retroville/retroville-3.jpg" alt="" class="img-article">
    <p class="ql-align-justify">У перший день роботи торговельно-розважального центру також відкрилися магазини алкогольних.
      напоїв та пива «Litra», продавець техніки та електроніки Eldorado Аптека Доброго Дня, Vodafone, Lifecell та
      Kyivstar, Tabakerka, Funduk Sunduk, Roshen, Tutto Gusto, Novodom, Jysk, Coqui, Spice room та інші.</p>
    <p class="ql-align-justify">«Наша команда втримала наявних орендарів та змогла залучити нових. В результаті
      Retroville заповнений брендами на 86%, і ми пишаємося партнерством з кожним з них. Вже в серпні 2020 року ми готуємо до
      відкриття другої черги ТРЦ та БЦ Retroville», - повідомила СЕО ТРЦ та БЦ Retroville Рената Якубченняне.</p>
    <img src="../../../assets/images/news/retroville/retroville-5.jpg" alt="" class="img-article">
    <p class="ql-align-justify">Загальна площа об'єкта – 118 990 м. кв, площа оренди – понад 91 860 м. кв. Проект
      передбачає наземний паркінг на 3245 місць. Якірні орендарі молла - гіпермаркет Novus (10640 м. кв.),
      Будівельний гіпермаркет Leroy Merlin (4805 м. кв.), перший у Києві кінотеатр Multiplex з новою технологією
      ScreenX, дитячий розважальний центр Fly Kids (4640 м. кв.), Бренди польського гурту LPP - Cropp, House, Sinsay
      (понад 3000 кв.м), фітнес-клуб SportLife (4436 кв. м) з басейнами та джакузі, а також найбільший у місті фуд-корт
      на 725 місць, де буде представлено 8 окремих ресторанів, а також 16 операторів, у тому числі McDonald's, KFC,
      Salateira та інші.</p>
    <p class="ql-align-justify"><br></p>
  </div>
</section>

<app-our-advantages></app-our-advantages>
<app-our-clients-small></app-our-clients-small>
<app-feedback></app-feedback>
<app-map></app-map>
