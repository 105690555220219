import {Component, OnInit} from '@angular/core';
import {Meta, Title} from '@angular/platform-browser';

@Component({
  selector: 'app-kontaktyi',
  templateUrl: './kontaktyi.component.html',
  styleUrls: ['./kontaktyi.component.css']
})
export class KontaktyiComponent implements OnInit {

  constructor(private title: Title,
              private meta: Meta) {
  }

  ngOnInit(): void {
    this.title.setTitle('Контакты - Комплекс Инжиниринг Системз | Инжиниринговые услуги в Киеве');
    this.meta.updateTag({
      name: 'description',
      content: 'Телефон - +38(044) 300-02-36 +38(095) 276-40-08 +38(093) 177-05-18 +38(067) 690-89-95 Электронная почта -\n' +
        'info@cis.kiev.ua Адрес - м. Київ, Никольско-Ботаническая 6/8'
    });
    this.meta.updateTag({
      meta: 'keywords',
      content: 'інжинірингова компанія,інжинірингові послуги,інжинірингова компанія Київ,промисловий інжиніринг'
    });
    window.scroll(0, 0);
  }
}
