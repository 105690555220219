import { Component, OnInit } from '@angular/core';
import {Meta, Title} from '@angular/platform-browser';

declare function initSlider(): any;

@Component({
  selector: 'app-shah-plov',
  templateUrl: './shah-plov.component.html',
  styleUrls: ['./shah-plov.component.css']
})
export class ShahPlovComponent implements OnInit {

  constructor(private title: Title,
              private meta: Meta) { }

  ngOnInit(): void {
    initSlider();
    window.scroll(0, 0);
    this.title.setTitle('Інжинірингова компанія - Комплекс Інжиніринг Системз | Ресторан азербайджанської кухні Shah-Plov');
    this.meta.updateTag({name: 'description', content: 'Послуги інжинірингу від компанії Комплекс Інжиніринг Системз ⭐ Проектування, монтаж, ремонт, діагностика та обслуговування ініжинірингових систем від професіоналів за доступними цінами'});
    this.meta.updateTag({meta: "keywords", content:"інжинірингова компанія,інжинірингові послуги,інжинірингова компанія Київ,промисловий інжиніринг,монтаж вентиляции,монтаж систем кондиционирования,монтаж отопления,монтаж вентиляции,монтаж канализации и водоснабжения"})
  }
}
