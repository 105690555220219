<section class="bread-block">
  <div class="container">
    <nav aria-label="breadcrumb">
      <ol class="breadcrumb">
        <li class="breadcrumb-item"><a [routerLink]="['']">Головна</a></li>
        <li class="breadcrumb-item"><a [routerLink]="['/portfolio']">Портфоліо</a></li>
        <li class="breadcrumb-item"><a [routerLink]="['/portfolio']">Портфоліо</a></li>
        <li class="breadcrumb-item">ЖК atlantic residence</li>
      </ol>
    </nav>
  </div>
</section>

<section class="content-page my-2 my-lg-4">
  <div class="container">
    <div class="title title-zag-block">ЖК ATLANTIC RESIDENCE</div>
  </div>
</section>

<section class="slider">
  <div class="container">
    <div class="portfolio-owl owl-carousel owl-theme">
      <div class="slider-item">
        <img src="../../../assets/images/atlantic-residences/atlantic_1.jpg" alt="ЖК ATLANTIC RESIDENCE"
             class="img-fluid">
      </div>
      <div class="slider-item">
        <img src="../../../assets/images/atlantic-residences/atlantic_2.jpg" alt="ЖК ATLANTIC RESIDENCE"
             class="img-fluid">
      </div>
      <div class="slider-item">
        <img src="../../../assets/images/atlantic-residences/atlantic_3.jpg" alt="ЖК ATLANTIC RESIDENCE"
             class="img-fluid">
      </div>
      <div class="slider-item">
        <img src="../../../assets/images/atlantic-residences/atlantic_4.jpg" alt="ЖК ATLANTIC RESIDENCE"
             class="img-fluid">
      </div>
      <div class="slider-item">
        <img src="../../../assets/images/atlantic-residences/atlantic_5.jpg" alt="ЖК ATLANTIC RESIDENCE"
             class="img-fluid">
      </div>
    </div>
  </div>
</section>
<section class="content-page my-2 my-lg-4">
  <div class="container">
    <p>ЖК atlantic residence (м. Київ, вул. Антоновича, 118). Загальна площа - 100 тис м2</p>
  </div>
</section>
<section class="content-page my-2 my-lg-4">
  <div class="container">
    <div class="title title-zag-block">Проведені роботи</div>
    <ol>
      <li>Монтаж системи центрального кондиціювання на базі LG Multi V (Корея)</li>
    </ol>
  </div>
</section>

<app-our-advantages></app-our-advantages>
<app-our-clients-small></app-our-clients-small>
<app-feedback></app-feedback>
<app-map></app-map>
