<section class="block block-uslugi">
  <div class="container">
    <div class="title title-zag-block">НАШІ ПОСЛУГИ</div>
    <div class="desc-block-uslugi">Повний спектр інжинірингових послуг, пов'язаних зі створенням комфортного мікроклімату
      приміщеннях різного призначення:
    </div>
    <div class="row">
      <div class="col-12 col-sm-6 col-md-4 col-lg-3">
        <div class="li-block-uslugi">
          <img src="../../../assets/images/img1.jpg" alt="Розробка технічних проектів" class="img-fluid">
          <div class="name-block-uslugi">Розробка проектів з розділів ОВіК</div>
          <div class="mt-auto text-center">
            <a [routerLink]="'/razrabotka-proektov-ovik'" class="my-btn">ДЕТАЛЬНІШЕ</a>
          </div>
        </div>
      </div>
      <div class="col-12 col-sm-6 col-md-4 col-lg-3">
        <div class="li-block-uslugi">
          <img src="../../../assets/images/img2.jpg" alt="Аналіз та оптимізація проектів" class="img-fluid">
          <div class="name-block-uslugi">Аналіз та оптимізація проектів за розділами ОВіК</div>
          <div class="mt-auto text-center">
            <a [routerLink]="'/analiz-i-optimizacziya-proektov-ovik'" class="my-btn">ДЕТАЛЬНІШЕ</a>
          </div>
        </div>
      </div>
      <div class="col-12 col-sm-6 col-md-4 col-lg-3">
        <div class="li-block-uslugi">
          <img src="../../../assets/images/img3.jpg" alt="Реалізація об'єкта «під ключ»" class="img-fluid">
          <div class="name-block-uslugi">Реалізація об'єкта «під ключ»</div>
          <div class="mt-auto text-center">
            <a [routerLink]="'/realizatsiya-pod-klyuch'" class="my-btn">ДЕТАЛЬНІШЕ</a>
          </div>
        </div>
      </div>
      <div class="col-12 col-sm-6 col-md-4 col-lg-3">
        <div class="li-block-uslugi">
          <img src="../../../assets/images/img4.jpg" alt="Монтажні та пусконалагоджувальні роботи" class="img-fluid">
          <div class="name-block-uslugi">Монтажні, пуско-налагоджувальні та сервісні роботи</div>
          <div class="mt-auto text-center">
            <a [routerLink]="'/montazhnyye-pusko-naladochnyye-servisnyye-raboty'" class="my-btn">ДЕТАЛЬНІШЕ</a>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
