import {BrowserModule} from '@angular/platform-browser';
import {NgModule} from '@angular/core';

import {AppComponent} from './app.component';
import {HomeComponent} from './pages/home/home.component';
import {AppRoute} from './app.route';
import {HeaderComponent} from './main/header/header.component';
import {FooterComponent} from './main/footer/footer.component';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {NgxPaginationModule} from 'ngx-pagination';
import {CustomFormsModule} from 'ngx-custom-validators';
import {IConfig, NgxMaskModule} from 'ngx-mask';
import {ToastrModule} from 'ngx-toastr';
import {NgSelectModule} from '@ng-select/ng-select';
import {NgxSpinnerModule} from 'ngx-spinner';
import {HttpClientModule} from '@angular/common/http';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {CommonModule} from '@angular/common';
import {MapComponent} from './components/map/map.component';
import {FeedbackComponent} from './components/feedback/feedback.component';
import {OurClientsSmallComponent} from './components/our-clients-small/our-clients-small.component';
import {OurAdvantagesComponent} from './components/our-advantages/our-advantages.component';
import {ErrorService} from './services/error.service';
import {VentilyacziyaComponent} from './pages/ventilyacziya/ventilyacziya.component';
import {KondiczionirovanieComponent} from './pages/kondiczionirovanie/kondiczionirovanie.component';
import {OtoplenieComponent} from './pages/otoplenie/otoplenie.component';
import {TeplosnabzhenieComponent} from './pages/teplosnabzhenie/teplosnabzhenie.component';
import {XolodosnabzhenieComponent} from './pages/xolodosnabzhenie/xolodosnabzhenie.component';
import {PortfolioComponent} from './pages/portfolio/portfolio.component';
import {KontaktyiComponent} from './pages/kontaktyi/kontaktyi.component';
import {ErrorComponent} from './pages/error/error.component';
import {VacanciesComponent} from './pages/vacancies/vacancies.component';
import {FeedbackFullComponent} from './pages/feedback-full/feedback-full.component';
import {NewsComponent} from './pages/news/news.component';
import {MessageService} from './services/message.service';
import {AlternativnyyIstochnikiTeplaComponent} from './pages/alternativnyy-istochniki-tepla/alternativnyy-istochniki-tepla.component';
import {OsusheniyeComponent} from './pages/osusheniye/osusheniye.component';
import {CallbackComponent} from './components/callback/callback.component';
import {RazrabotkaProektovOvikComponent} from './pages/razrabotka-proektov-ovik/razrabotka-proektov-ovik.component';
import {AdditionalServicesComponent} from './components/additional-services/additional-services.component';
import {AnalizOptimizacziyaProektovOvikComponent} from './pages/analiz-optimizacziya-proektov-ovik/analiz-optimizacziya-proektov-ovik.component';
import {KomplektacziyaPostavkaOborudovaniyComponent} from './pages/komplektacziya-postavka-oborudovaniy/komplektacziya-postavka-oborudovaniy.component';
import {PoslegarantijnoeObsluzhivanieComponent} from './pages/poslegarantijnoe-obsluzhivanie/poslegarantijnoe-obsluzhivanie.component';
import {RealizacziyaProektovComponent} from './pages/realizacziya-proektov/realizacziya-proektov.component';
import {UslugiComponent} from './components/uslugi/uslugi.component';
import {VseUslugiComponent} from './pages/vse-uslugi/vse-uslugi.component';
import {PortfolioDomosferaComponent} from './pages/portfolios/portfolio-domosfera/portfolio-domosfera.component';
import {PortfolioSilpoComponent} from './pages/portfolios/portfolio-silpo/portfolio-silpo.component';
import {KonditsionirovaniyeSkladaComponent} from './pages/konditsionirovaniye-sklada/konditsionirovaniye-sklada.component';
import {PremiumTsentrComponent} from './pages/portfolios/premium-tsentr/premium-tsentr.component';
import {RespublikaComponent} from './pages/portfolios/respublika/respublika.component';
import {KhortytsyaPalasComponent} from './pages/portfolios/khortytsya-palas/khortytsya-palas.component';
import {AtmosferaComponent} from './pages/portfolios/atmosfera/atmosfera.component';
import {StranaEnotyyaComponent} from './pages/portfolios/strana-enotyya/strana-enotyya.component';
import {EdemRezortComponent} from './pages/portfolios/edem-rezort/edem-rezort.component';
import {DepotComponent} from './pages/portfolios/depot/depot.component';
import {KvartyrnyyZhytlovyyBudynokComponent} from './pages/portfolios/kvartyrnyy-zhytlovyy-budynok/kvartyrnyy-zhytlovyy-budynok.component';
import {OfisnePomeshchenyeComponent} from './pages/portfolios/ofisne-pomeshchenye/ofisne-pomeshchenye.component';
import {LyubavaComponent} from './pages/portfolios/lyubava/lyubava.component';
import {FabrykaComponent} from './pages/portfolios/fabryka/fabryka.component';
import {LuxuryComponent} from './pages/portfolios/luxury/luxury.component';
import {OrnamentComponent} from './pages/portfolios/ornament/ornament.component';
import {BirkraftComponent} from './pages/portfolios/birkraft/birkraft.component';
import {HotovoComponent} from './pages/portfolios/hotovo/hotovo.component';
import {AdministratyvnoZhytlovyyComponent} from './pages/portfolios/administratyvno-zhytlovyy/administratyvno-zhytlovyy.component';
import {AcuraComponent} from './pages/portfolios/acura/acura.component';
import {InkrystarComponent} from './pages/portfolios/inkrystar/inkrystar.component';
import {HalaktonComponent} from './pages/portfolios/halakton/halakton.component';
import {GrafitComponent} from './pages/portfolios/grafit/grafit.component';
import {LeksComponent} from './pages/portfolios/leks/leks.component';
import {BetonKompleksComponent} from './pages/portfolios/beton-kompleks/beton-kompleks.component';
import {BimarketComponent} from './pages/portfolios/bimarket/bimarket.component';
import {BleskComponent} from './pages/portfolios/blesk/blesk.component';
import {MerksComponent} from './pages/portfolios/merks/merks.component';
import {ModnayaKastaComponent} from './pages/portfolios/modnaya-kasta/modnaya-kasta.component';
import {UaPromComponent} from './pages/portfolios/ua-prom/ua-prom.component';
import {DzhublComponent} from './pages/portfolios/dzhubl/dzhubl.component';
import {OfisnaChastynaLohistychnohoKompleksuComponent} from './pages/portfolios/ofisna-chastyna-lohistychnoho-kompleksu/ofisna-chastyna-lohistychnoho-kompleksu.component';
import {UkrayinskyyKapytalComponent} from './pages/portfolios/ukrayinskyy-kapytal/ukrayinskyy-kapytal.component';
import {UkrPlastAvtomatComponent} from './pages/portfolios/ukr-plast-avtomat/ukr-plast-avtomat.component';
import {StyrolComponent} from './pages/portfolios/styrol/styrol.component';
import {MmtsStbComponent} from './pages/portfolios/mmts-stb/mmts-stb.component';
import {LaboratornyyKorpusComponent} from './pages/portfolios/laboratornyy-korpus/laboratornyy-korpus.component';
import {EkmyComponent} from './pages/portfolios/ekmy/ekmy.component';
import {NewBalanceComponent} from './pages/portfolios/new-balance/new-balance.component';
import {GardeComponent} from './pages/portfolios/garde/garde.component';
import {ShahPlovComponent} from './pages/portfolios/shah-plov/shah-plov.component';
import {AudiComponent} from './pages/portfolios/audi/audi.component';
import {KonditsionirovaniyeFarmComponent} from './pages/konditsionirovaniye-farm/konditsionirovaniye-farm.component';
import {KonditsionirovaniyeZavodaComponent} from './pages/konditsionirovaniye-zavoda/konditsionirovaniye-zavoda.component';
import {Angulartics2Module} from 'angulartics2';
import { VodosnabzheniyeKanalizatsiyaComponent } from './pages/vodosnabzheniye-kanalizatsiya/vodosnabzheniye-kanalizatsiya.component';
import { MontazhNaladServisComponent } from './pages/montazh-nalad-servis/montazh-nalad-servis.component';
import {RealizatsiyaPodKlyuchComponent} from './pages/realizatsiya-pod-klyuch/realizatsiya-pod-klyuch.component';
import { GarantiynoyeObsluzhivaniyeComponent } from './pages/garantiynoye-obsluzhivaniye/garantiynoye-obsluzhivaniye.component';
import { TrcRetrovilleComponent } from './pages/newsitems/trc-retroville/trc-retroville.component';
import { LindenLuxuryResidencesComponent } from './pages/portfolios/linden-luxury-residences/linden-luxury-residences.component';
import { AtlanticResidenceComponent } from './pages/portfolios/atlantic-residence/atlantic-residence.component';
import { BtsEuropeComponent } from './pages/portfolios/bts-europa/bts-europe.component';
import { JoobleSkyloomComponent } from './pages/portfolios/jooble-skyloom/jooble-skyloom.component';
import { CosmosGolfUkraineComponent } from './pages/portfolios/cosmos-golf-ukraine/cosmos-golf-ukraine.component';
import { KyyankaComponent } from './pages/portfolios/kyyanka/kyyanka.component';
import { RetrovilComponent } from './pages/portfolios/retrovil/retrovil.component';
import { OsvitoriyaKhabComponent } from './pages/portfolios/osvitoriya-khab/osvitoriya-khab.component';
import { UnitCityComponent } from './pages/portfolios/unit-city/unit-city.component';

export function tokenGetter() {
  return localStorage.getItem('access_token');
}

export let options: Partial<IConfig> | (() => Partial<IConfig>);

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    HeaderComponent,
    FooterComponent,
    MapComponent,
    FeedbackComponent,
    OurClientsSmallComponent,
    OurAdvantagesComponent,
    VentilyacziyaComponent,
    KondiczionirovanieComponent,
    OtoplenieComponent,
    TeplosnabzhenieComponent,
    XolodosnabzhenieComponent,
    PortfolioComponent,
    KontaktyiComponent,
    ErrorComponent,
    VacanciesComponent,
    FeedbackFullComponent,
    NewsComponent,
    AlternativnyyIstochnikiTeplaComponent,
    OsusheniyeComponent,
    CallbackComponent,
    RazrabotkaProektovOvikComponent,
    AdditionalServicesComponent,
    AnalizOptimizacziyaProektovOvikComponent,
    KomplektacziyaPostavkaOborudovaniyComponent,
    PoslegarantijnoeObsluzhivanieComponent,
    RealizacziyaProektovComponent,
    UslugiComponent,
    VseUslugiComponent,
    PortfolioDomosferaComponent,
    PortfolioSilpoComponent,
    KonditsionirovaniyeSkladaComponent,
    PremiumTsentrComponent,
    RespublikaComponent,
    KhortytsyaPalasComponent,
    AtmosferaComponent,
    StranaEnotyyaComponent,
    EdemRezortComponent,
    DepotComponent,
    KvartyrnyyZhytlovyyBudynokComponent,
    OfisnePomeshchenyeComponent,
    LyubavaComponent,
    FabrykaComponent,
    LuxuryComponent,
    OrnamentComponent,
    BirkraftComponent,
    HotovoComponent,
    AdministratyvnoZhytlovyyComponent,
    AcuraComponent,
    InkrystarComponent,
    HalaktonComponent,
    GrafitComponent,
    LeksComponent,
    BetonKompleksComponent,
    BimarketComponent,
    BleskComponent,
    MerksComponent,
    ModnayaKastaComponent,
    UaPromComponent,
    DzhublComponent,
    OfisnaChastynaLohistychnohoKompleksuComponent,
    UkrayinskyyKapytalComponent,
    UkrPlastAvtomatComponent,
    StyrolComponent,
    MmtsStbComponent,
    LaboratornyyKorpusComponent,
    EkmyComponent,
    NewBalanceComponent,
    GardeComponent,
    ShahPlovComponent,
    AudiComponent,
    KonditsionirovaniyeFarmComponent,
    KonditsionirovaniyeZavodaComponent,
    VodosnabzheniyeKanalizatsiyaComponent,
    RealizatsiyaPodKlyuchComponent,
    MontazhNaladServisComponent,
    GarantiynoyeObsluzhivaniyeComponent,
    TrcRetrovilleComponent,
    LindenLuxuryResidencesComponent,
    AtlanticResidenceComponent,
    BtsEuropeComponent,
    JoobleSkyloomComponent,
    CosmosGolfUkraineComponent,
    KyyankaComponent,
    RetrovilComponent,
    OsvitoriyaKhabComponent,
    UnitCityComponent
  ],
  imports: [
    BrowserModule,
    AppRoute,
    CommonModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    ToastrModule.forRoot({
      timeOut: 10000,
      positionClass: 'toast-bottom-right',
      preventDuplicates: true
    }),
    HttpClientModule,
    NgSelectModule,
    NgxSpinnerModule,
    ToastrModule.forRoot(),
    NgxMaskModule.forRoot(options),
    FormsModule,
    CustomFormsModule,
    NgxPaginationModule,
    Angulartics2Module.forRoot()
  ],
  providers: [
    ErrorService,
    MessageService
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
}
