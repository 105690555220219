<section class="bread-block">
  <div class="container">
    <nav aria-label="breadcrumb">
      <ol class="breadcrumb">
        <li class="breadcrumb-item"><a [routerLink]="['']">Головна</a></li>
        <li class="breadcrumb-item"><a [routerLink]="['/portfolio']">Портфоліо</a></li>
        <li class="breadcrumb-item">Адміністративно-житловий 24-поверховий комплекс</li>
      </ol>
    </nav>
  </div>
</section>

<section class="content-page my-2 my-lg-4">
  <div class="container">
    <div class="title title-zag-block">Адміністративно-житловий 24-поверховий комплекс</div>
  </div>
</section>

<section class="slider">
  <div class="container">
    <div class="portfolio-owl owl-carousel owl-theme">
      <div class="slider-item">
        <img src="../../../../assets/images/domik24.png" alt="Административно-жилой 24-этажный комплекс"
             class="img-fluid">
      </div>
    </div>
  </div>
</section>
<section class="content-page my-2 my-lg-4">
  <div class="container">
    <p>Адміністративно-житловий 24-поверховий комплекс (м. Київ, бул. Шевченка, 33) понад 345 внутр. блоків.</p>
  </div>
</section>
<section class="content-page my-2 my-lg-4">
  <div class="container">
    <div class="title title-zag-block">Проведені роботи</div>
    <ol>
      <li>Монтаж мультизональної системи кондиціювання на базі обладнання Daikin (Японія) 850 кВт холоду</li>
      <li>Монтаж систем припливно-витяжної вентиляції на базі обладнання Frapol (Австрія-Польща)</li>
    </ol>
  </div>
</section>

<app-our-advantages></app-our-advantages>
<app-our-clients-small></app-our-clients-small>
<app-feedback></app-feedback>
<app-map></app-map>
