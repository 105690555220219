<section class="block parthner-block">
  <div class="container">
    <div class="title title-zag-block">СЕРЕД НАШИХ КЛІЄНТІВ</div>
    <div class="logo-wr">
      <div class="row mx-0">
        <div class="col-12 col-sm-6 col-md-6 col-lg-3 p-0 text-center logo-li-img">
          <img src="../../../assets/images/clients/retroville.png" alt="" class="img-fluid logo-wr-img">
        </div>
        <div class="col-12 col-sm-6 col-md-6 col-lg-3 p-0 text-center logo-li-img">
          <img src="../../../assets/images/clients/fabrika.png" alt="" class="img-fluid logo-wr-img">
        </div>
        <div class="col-12 col-sm-6 col-md-6 col-lg-3 p-0 text-center logo-li-img">
          <img src="../../../assets/images/clients/atmosfera.png" alt="" class="img-fluid logo-wr-img">
        </div>
        <div class="col-12 col-sm-6 col-md-6 col-lg-3 p-0 text-center logo-li-img">
          <img src="../../../assets/images/clients/domosfera.png" alt="" class="img-fluid logo-wr-img">
        </div>
        <div class="col-12 col-sm-6 col-md-6 col-lg-3 p-0 text-center logo-li-img">
          <img src="../../../assets/images/clients/lubava.png" alt="" class="img-fluid logo-wr-img">
        </div>
        <div class="col-12 col-sm-6 col-md-6 col-lg-3 p-0 text-center logo-li-img">
          <img src="../../../assets/images/clients/unit.jpg" alt="" class="img-fluid logo-wr-img">
        </div>
        <div class="col-12 col-sm-6 col-md-6 col-lg-3 p-0 text-center logo-li-img">
          <img src="../../../assets/images/clients/palace.jpg" alt="" class="img-fluid logo-wr-img">
        </div>
        <div class="col-12 col-sm-6 col-md-6 col-lg-3 p-0 text-center logo-li-img">
          <img src="../../../assets/images/clients/gotovo.png" alt="" class="img-fluid logo-wr-img">
        </div>
        <div class="col-12 col-sm-6 col-md-6 col-lg-3 p-0 text-center logo-li-img">
          <img src="../../../assets/images/clients/depot.png" alt="" class="img-fluid logo-wr-img">
        </div>
        <div class="col-12 col-sm-6 col-md-6 col-lg-3 p-0 text-center logo-li-img">
          <img src="../../../assets/images/clients/ornamentmall.png" alt="" class="img-fluid logo-wr-img">
        </div>
        <div class="col-12 col-sm-6 col-md-6 col-lg-3 p-0 text-center logo-li-img">
          <img src="../../../assets/images/clients/stb.jpg" alt="" class="img-fluid logo-wr-img">
        </div>
        <div class="col-12 col-sm-6 col-md-6 col-lg-3 p-0 text-center logo-li-img">
          <img src="../../../assets/images/clients/bhg.png" alt="" class="img-fluid logo-wr-img">
        </div>
      </div>
    </div>
<!--    <div class="text-center">-->
<!--      <a href="" class="my-btn">дивитись все</a>-->
<!--    </div>-->
  </div>
</section>
