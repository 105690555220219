<section class="bread-block">
  <div class="container">
    <nav aria-label="breadcrumb">
      <ol class="breadcrumb">
        <li class="breadcrumb-item"><a [routerLink]="['']">Головна</a></li>
        <li class="breadcrumb-item"><a [routerLink]="['/uslugi']">Наші послуги</a></li>
        <li class="breadcrumb-item">Післягарантійне обслуговування</li>
      </ol>
    </nav>
  </div>
</section>
<section class="content-page my-2 my-lg-4">
  <div class="container">
    <div class="title title-zag-block">ПІСЛЯГАРАНТІЙНЕ ОБСЛУГОВУВАННЯ</div>
    <p class="ql-align-justify">Післягарантійне обслуговування - це особлива форма сервісного обслуговування, раніше
      придбаного у торгівельної організації обладнання, яке далеко не завжди входить до стандартних зобов'язань
      перед покупцями деяких компаній.</p>
    <p class="ql-align-justify">Відомо, що в умовах конкурентного ринку, питання про виконання зобов'язань перед
      Замовником завжди виходить на перше місце. Нескладно уявити наслідки, коли обладнання для
      документообігу, від якого часто залежить термін здачі замовлення, виходить з ладу, а часом ще й у невідповідний
      момент. Особливо це загострюється, якщо гарантія на апарати закінчилася! У компаніях, що зіткнулися з
      такою ситуацією одразу формується низка питань: що робити, куди дзвонити, куди звернутися, і хто визначить причину
      поломки обладнання?</p>
    <p class="ql-align-justify">Внаслідок обставин, що склалася, компанія готова піти на будь-які витрати для налагодження
      обладнання, чим може скористатися поспіхом знайдена обслуговуюча компанія, яка не має відношення до
      дистрибуції тих інших апаратів і, що виконує ремонт тими способами, які їй доступні,
      виставляючи при цьому перед замовником ще й чималі рахунки.</p>
    <p class="ql-align-justify">Системи вентиляції, опалення, кондиціювання, теплопостачання, холодопостачання,
      осушення, водопостачання та каналізації вимагають певного технічного обслуговування, згідно з регламентом. Більше
      того, при неправильній експлуатації або через інші причини бувають виходи з ладу тих чи інших систем, створюються
      ті чи інші позаштатні ситуації, які потребують втручання спеціаліста. Забігаючи наперед скажемо, що компанія
      «Комплекс Інжиніринг Системз» проводить професійне гарантійне, післягарантійне, сервісне обслуговування.
      видів систем:</p>
    <ul>
      <li>вентиляції</li>
      <li>опалення</li>
      <li>кондиціювання</li>
      <li>теплопостачання</li>
      <li>альтернативних джерел тепла</li>
      <li>холодопостачання</li>
      <li>осушення</li>
      <li>водопостачання та каналізації</li>
    </ul>
    <p class="ql-align-justify">Укладаємо договори на обслуговування перерахованих вище систем.</p><h4><strong>Результат нашої роботи</strong></h4>
    <p class="ql-align-justify">Підтримка внутрішніх та внутрішньомайданних інженерних мереж, систем та обладнання
      робочий режим.&nbsp;</p><h4><strong>Яку вигоду отримує Замовник?</strong></h4>
    <p class="ql-align-justify">Впевненість у завтрашньому дні внаслідок технічного обслуговування внутрішніх та
      внутрішньомайданних інженерних мереж, систем та обладнання, готових до подальшої експлуатації.&nbsp;</p>
    <p class="ql-align-justify">Економія коштів рахунок відсутності свого постійного обслуговуючого персоналу.</p>
    <p class="ql-align-justify">Відрегульовані інженерні системи та обладнання, технічні характеристики яких
      відповідають робочим вимогам експлуатації.</p>
    <p class="ql-align-justify">Переваги постгарантійного обслуговування від <strong>компанії «Комплекс Інжиніринг
      Системз»</strong>:</p>
    <ul>
      <li class="ql-align-justify">всі види робіт виконуються досвідченими співробітниками, які досконально знають свою
        роботу;
      </li>
      <li class="ql-align-justify">ми постійно підтримуємо зв'язок із виробниками обладнання та регулярно проходимо
        необхідне навчання;
      </li>
      <li class="ql-align-justify">усунення несправностей виконується у максимально стислий термін;</li>
      <li class="ql-align-justify">вартість обслуговування – відповідає рівню сервісу.</li>
    </ul>
    <p class="ql-align-justify">Щоб дізнатися більше про послугу післягарантійного обслуговування, зв'яжіться з менеджерами
      за будь-яким із телефонів, вказаних на сайті.</p>
    <p class="ql-align-justify">При співпраці з <strong>компанією «Комплекс Інжиніринг Системз»</strong> ваша
      система функціонуватиме з максимальною надійністю!</p>
  </div>
</section>
<app-additional-services></app-additional-services>
<app-our-advantages></app-our-advantages>
<app-our-clients-small></app-our-clients-small>
<app-feedback></app-feedback>
<app-map></app-map>
