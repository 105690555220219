import { Component, OnInit } from '@angular/core';
import {Meta, Title} from '@angular/platform-browser';

@Component({
  selector: 'app-xolodosnabzhenie',
  templateUrl: './xolodosnabzhenie.component.html',
  styleUrls: ['./xolodosnabzhenie.component.css']
})
export class XolodosnabzhenieComponent implements OnInit {

  constructor(private title: Title,
              private meta: Meta) { }

  ngOnInit(): void {
    this.title.setTitle('Холодопостачання для комерційних та промислових приміщень під ключ - Комплекс Інжиніринг Системз | Інжинірингові послуги у Києві');
    this.meta.updateTag({name: 'description', content: 'Холодопостачання під ключ від інжинірингової компанії Комплекс Інжиніринг Системз в Києві ⭐ Проектування, монтаж, комплектація та постачання обладнання для систем холодопостачання'});
    this.meta.updateTag({meta: "keywords", content:"системы холодоснабжения,холодоснабжение киев,монтаж холодоснабжения,інжинірингова компанія,інжинірингові послуги,інжинірингова компанія Київ,промисловий інжиніринг"})
    window.scroll(0, 0);
  }

}
